module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Search Results</h2>\n\n';
 if ('undefined' !== typeof staff && staff.length > 0) { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Name</th>\n            <th>Email</th>\n            <th>Company</th>\n            <th>Division</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 staff.forEach((s) => { ;
__p += '\n        <tr data-id="' +
((__t = ( s.id )) == null ? '' : __t) +
'" class="' +
((__t = ( 1 === s.active ? '' : 'unpublished' )) == null ? '' : __t) +
'">\n            <td><a href="#staff/edit/' +
((__t = ( s.id )) == null ? '' : __t) +
'">' +
((__t = ( s.id )) == null ? '' : __t) +
'</a></td>\n            <td><a href="#staff/edit/' +
((__t = ( s.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(s) )) == null ? '' : __t) +
'</a></td>\n            <td>\n                ';
 if (s.email) { ;
__p += '\n                ' +
((__t = ( s.email )) == null ? '' : __t) +
' <a href="mailto:' +
((__t = ( s.email )) == null ? '' : __t) +
'"><i class="fa fa-envelope fa-lg" aria-hidden="true"></i></a>\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (s.division) { ;
__p += '\n                    ' +
((__t = ( s.division.name )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (s.company) { ;
__p += '\n                    ' +
((__t = ( s.company.name )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n';
 } else { ;
__p += '\n<p>No staff match your search query.</p>\n';
 } ;


}
return __p
};
