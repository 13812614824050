import { Model } from 'backbone';
import config from '@/js/app/config';

const DealModel = Model.extend({

    urlRoot: config.api.url + 'deals',

    initialize: function () {
        // Calculate contract duration
        this.listenTo(this, 'change:賃貸条件_契約期間_start change:賃貸条件_契約期間_end', this.calcContractDuration);

        // Calculate fee totals
        this.listenTo(this, 'change:fee_item_af_amount change:fee_item_ob_amount change:fee_item_1_amount change:fee_item_2_amount change:fee_item_3_amount change:fee_item_4_amount change:fee_item_5_amount change:fee_item_6_amount change:fee_item_7_amount change:fee_item_8_amount change:fee_item_9_amount change:fee_item_10_amount change:fee_item_11_amount change:fee_item_12_amount', this.calcFeeTotal);
        this.listenTo(this,
            'change:fee_item_af_amount change:fee_item_af_tax_rate ' +
            'change:fee_item_ob_amount change:fee_item_ob_tax_rate ' +
            'change:fee_item_1_amount change:fee_item_1_tax_rate ' +
            'change:fee_item_2_amount change:fee_item_2_tax_rate ' +
            'change:fee_item_3_amount change:fee_item_3_tax_rate ' +
            'change:fee_item_4_amount change:fee_item_4_tax_rate ' +
            'change:fee_item_5_amount change:fee_item_5_tax_rate ' +
            'change:fee_item_6_amount change:fee_item_6_tax_rate ' +
            'change:fee_item_7_amount change:fee_item_7_tax_rate ' +
            'change:fee_item_8_amount change:fee_item_8_tax_rate ' +
            'change:fee_item_9_amount change:fee_item_9_tax_rate ' +
            'change:fee_item_10_amount change:fee_item_10_tax_rate ' +
            'change:fee_item_11_amount change:fee_item_11_tax_rate ' +
            'change:fee_item_12_amount change:fee_item_12_tax_rate',
            this.calcFeeNet);
        this.listenTo(this, 'change:fee_total change:fee_net', this.calcFeeTax);

        // Calculate costs
        for (let i = 1; i <= 2; i++) {
            this.listenTo(this, `change:cost_item_${i}_percent change:cost_item_${i}_af change:cost_item_${i}_ob change:fee_item_af_amount change:fee_item_af_tax_rate change:fee_item_ob_amount change:fee_item_ob_tax_rate`, (model) => {
                this.calcCost(model, i);
            });
        }

        // Calculate fee nets
        this.listenTo(this, 'change:fee_item_af_amount change:fee_item_af_tax_rate change:cost_item_1_percent change:cost_item_1_af change:cost_item_2_percent change:cost_item_2_af change:cost_item_3_fee change:cost_item_3_amount change:cost_item_4_fee change:cost_item_4_amount change:cost_item_5_fee change:cost_item_5_amount change:cost_item_6_fee change:cost_item_6_amount change:cost_item_7_fee change:cost_item_7_amount change:cost_item_8_fee change:cost_item_8_amount', this.calcAdNet);
        this.listenTo(this, 'change:fee_item_ob_amount change:fee_item_ob_tax_rate change:cost_item_1_percent change:cost_item_1_ob change:cost_item_2_percent change:cost_item_2_ob change:cost_item_3_fee change:cost_item_3_amount change:cost_item_4_fee change:cost_item_4_amount change:cost_item_5_fee change:cost_item_5_amount change:cost_item_6_fee change:cost_item_6_amount change:cost_item_7_fee change:cost_item_7_amount change:cost_item_8_fee change:cost_item_8_amount', this.calcObNet);
        for (let i = 1; i <= 12; i++) {
            this.listenTo(this, `change:fee_item_${i}_amount change:fee_item_${i}_tax_rate change:cost_item_3_fee change:cost_item_3_amount change:cost_item_4_fee change:cost_item_4_amount change:cost_item_5_fee change:cost_item_5_amount change:cost_item_6_fee change:cost_item_6_amount change:cost_item_7_fee change:cost_item_7_amount change:cost_item_8_fee change:cost_item_8_amount`, (model) => {
                this.calcOfNet(model, i);
            });
        }

        // Calculate net totals
        this.listenTo(this, 'change:fee_item_1_net change:fee_item_2_net change:fee_item_3_net change:fee_item_4_net change:fee_item_5_net change:fee_item_6_net change:fee_item_7_net change:fee_item_8_net change:fee_item_9_net change:fee_item_10_net change:fee_item_11_net change:fee_item_12_net', this.calcTotalOfNet);
        this.listenTo(this, 'change:fee_item_af_net change:fee_item_ob_net change:total_of_net', this.calcTotalNet);

        // Calculate pre deduction AF/OB/OF for Agent 0
        this.listenTo(this, 'change:fee_item_af_net change:split_agent_1_af_pre change:split_agent_2_af_pre change:split_agent_3_af_pre change:split_agent_4_af_pre change:split_agent_5_af_pre change:split_agent_6_af_pre change:split_agent_7_af_pre', this.calcAgent0AdPre);
        this.listenTo(this, 'change:fee_item_ob_net change:split_agent_1_ob_pre change:split_agent_2_ob_pre change:split_agent_3_ob_pre change:split_agent_4_ob_pre change:split_agent_5_ob_pre change:split_agent_6_ob_pre change:split_agent_7_ob_pre', this.calcAgent0ObPre);
        this.listenTo(this, 'change:total_of_net change:split_agent_1_of_pre change:split_agent_2_of_pre change:split_agent_3_of_pre change:split_agent_4_of_pre change:split_agent_5_of_pre change:split_agent_6_of_pre change:split_agent_7_of_pre', this.calcAgent0OfPre);

        // Calculate web deduction AF/OB/OF amount
        this.listenTo(this, 'change:fee_item_af_net change:split_web_percent_af', this.calcSplitWebAd);
        this.listenTo(this, 'change:fee_item_ob_net change:split_web_percent_ob', this.calcSplitWebOb);
        this.listenTo(this, 'change:total_of_net change:split_web_percent_of', this.calcSplitWebOf);

        for (let i = 0; i < 8; i++) {
            // Calculate post deduction AF/OB/OF for agent
            this.listenTo(this, `change:split_agent_${i}_af_pre change:split_agent_${i}_pct_deduction change:split_web_percent_af change:v`, (model) => {
                this.calcAgentAd(model, i);
            });
            this.listenTo(this, `change:split_agent_${i}_ob_pre change:split_agent_${i}_pct_deduction change:split_web_percent_ob change:v`, (model) => {
                this.calcAgentOb(model, i);
            });
            this.listenTo(this, `change:split_agent_${i}_of_pre change:split_agent_${i}_pct_deduction change:split_web_percent_of change:v`, (model) => {
                this.calcAgentOf(model, i);
            });

            // Calculate pre deduction total for agent
            this.listenTo(this, `change:split_agent_${i}_af_pre change:split_agent_${i}_ob_pre change:split_agent_${i}_of_pre`, (model) => {
                this.calcAgentTotalPre(model, i);
            });

            // Calculate post web deduction total for agent
            this.listenTo(this, `change:split_agent_${i}_af change:split_agent_${i}_ob change:split_agent_${i}_of`, (model) => {
                this.calcAgentTotal(model, i);
            });
        }

        // Calculate web deduction total
        this.listenTo(this, 'change:split_web_af change:split_web_ob change:split_web_of', this.calcSplitWebTotal);

    },

    /* Contract duration calculation */
    calcContractDuration: function (model) {
        console.debug('DealModel#calcContractDuration');
        let start = model.get('賃貸条件_契約期間_start');
        let end = model.get('賃貸条件_契約期間_end');

        if (start) {
            start = moment(start);
        }
        if (end) {
            // Add one day to end date for inclusive calculation
            end = moment(end).add('days', 1);
        }

        // If start and end dates valid
        if (start && end) {
            const years = end.diff(start, 'years');
            const months = years > 0
                ? end.subtract('years', years).diff(start, 'months')
                : end.diff(start, 'months');
            model.set('賃貸条件_契約期間_length', years + '年' + months + 'ヶ月');
        }
    },

    /* Fee calculations */
    calcFeeTotal: function (model) {
        console.debug('DealModel#calcFeeTotal');
        model.set('fee_total', (model.get('fee_item_af_amount') || 0) + (model.get('fee_item_ob_amount') || 0) + (model.get('fee_item_1_amount') || 0) + (model.get('fee_item_2_amount') || 0) + (model.get('fee_item_3_amount') || 0) + (model.get('fee_item_4_amount') || 0) + (model.get('fee_item_5_amount') || 0) + (model.get('fee_item_6_amount') || 0) + (model.get('fee_item_7_amount') || 0) + (model.get('fee_item_8_amount') || 0) + (model.get('fee_item_9_amount') || 0) + (model.get('fee_item_10_amount') || 0) + (model.get('fee_item_11_amount') || 0) + (model.get('fee_item_12_amount') || 0));
    },

    calcFeeNet: function (model) {
        console.debug('DealModel#calcFeeNet');
        model.set('fee_net',
            Math.round(
                ((model.get('fee_item_af_amount') || 0) / (1 + parseFloat(model.get('fee_item_af_tax_rate') || 0))) +
                ((model.get('fee_item_ob_amount') || 0) / (1 + parseFloat(model.get('fee_item_ob_tax_rate') || 0))) +
                ((model.get('fee_item_1_amount') || 0) / (1 + parseFloat(model.get('fee_item_1_tax_rate') || 0))) +
                ((model.get('fee_item_2_amount') || 0) / (1 + parseFloat(model.get('fee_item_2_tax_rate') || 0))) +
                ((model.get('fee_item_3_amount') || 0) / (1 + parseFloat(model.get('fee_item_3_tax_rate') || 0))) +
                ((model.get('fee_item_4_amount') || 0) / (1 + parseFloat(model.get('fee_item_4_tax_rate') || 0))) +
                ((model.get('fee_item_5_amount') || 0) / (1 + parseFloat(model.get('fee_item_5_tax_rate') || 0))) +
                ((model.get('fee_item_6_amount') || 0) / (1 + parseFloat(model.get('fee_item_6_tax_rate') || 0))) +
                ((model.get('fee_item_7_amount') || 0) / (1 + parseFloat(model.get('fee_item_7_tax_rate') || 0))) +
                ((model.get('fee_item_8_amount') || 0) / (1 + parseFloat(model.get('fee_item_8_tax_rate') || 0))) +
                ((model.get('fee_item_9_amount') || 0) / (1 + parseFloat(model.get('fee_item_9_tax_rate') || 0))) +
                ((model.get('fee_item_10_amount') || 0) / (1 + parseFloat(model.get('fee_item_10_tax_rate') || 0))) +
                ((model.get('fee_item_11_amount') || 0) / (1 + parseFloat(model.get('fee_item_11_tax_rate') || 0))) +
                ((model.get('fee_item_12_amount') || 0) / (1 + parseFloat(model.get('fee_item_12_tax_rate') || 0)))
            ));
    },

    calcFeeTax: function (model) {
        console.debug('DealModel#calcFeeTax');
        model.set('fee_tax', model.get('fee_total') - model.get('fee_net'));
    },

    /* Cost calculations */
    calcCost: function (model, index) {
        console.debug('DealModel#calcCost');
        const percent = model.get(`cost_item_${index}_percent`) / 100;

        let amount = 0, taxRate = 0;
        if (model.get(`cost_item_${index}_af`)) {
            taxRate = 1 + parseFloat(model.get('fee_item_af_tax_rate'));
            amount += Math.round(model.get('fee_item_af_amount') / taxRate * percent);
        }
        if (model.get(`cost_item_${index}_ob`)) {
            taxRate = 1 + parseFloat(model.get('fee_item_ob_tax_rate'));
            amount += Math.round(model.get('fee_item_ob_amount') / taxRate * percent);
        }

        model.set(`cost_item_${index}_amount`, amount);
    },

    /* Net calculations */
    calcAdNet: function (model) {
        console.debug('DealModel#calcAdNet');
        const taxRate = 1 + parseFloat(model.get('fee_item_af_tax_rate'));

        let total = Math.round(model.get('fee_item_af_amount') / taxRate);

        // Subtract percentage based costs
        if (model.get('cost_item_1_af')) {
            const cost1Percent = model.get('cost_item_1_percent') / 100;
            total -= Math.round(model.get('fee_item_af_amount') / taxRate * cost1Percent);
        }
        if (model.get('cost_item_2_af')) {
            const cost2Percent = model.get('cost_item_2_percent') / 100;
            total -= Math.round(model.get('fee_item_af_amount') / taxRate * cost2Percent);
        }

        // Subtract fixed costs
        for (let i = 3; i <= 8; i++) {
            if (Number(model.get('cost_item_' + i + '_fee')) === -2) {
                total -= model.get('cost_item_' + i + '_amount');
            }
        }

        model.set('fee_item_af_net', total);
    },

    calcObNet: function (model) {
        console.debug('DealModel#calcObNet');
        const taxRate = 1 + parseFloat(model.get('fee_item_ob_tax_rate'));

        let total = Math.round(model.get('fee_item_ob_amount') / taxRate);

        // Subtract percentage based costs
        if (model.get('cost_item_1_ob')) {
            const cost1Percent = model.get('cost_item_1_percent') / 100;
            total -= Math.round(model.get('fee_item_ob_amount') / taxRate * cost1Percent);
        }
        if (model.get('cost_item_2_ob')) {
            const cost2Percent = model.get('cost_item_2_percent') / 100;
            total -= Math.round(model.get('fee_item_ob_amount') / taxRate * cost2Percent);
        }

        // Subtract fixed costs
        for (let i = 3; i <= 8; i++) {
            if (Number(model.get('cost_item_' + i + '_fee')) === -1) {
                total -= model.get('cost_item_' + i + '_amount');
            }
        }

        model.set('fee_item_ob_net', total);
    },

    calcOfNet: function (model, index) {
        console.debug('DealModel#calcOfNet');

        const taxRate = 1 + parseFloat(model.get(`fee_item_${index}_tax_rate`));

        let total = Math.round(model.get(`fee_item_${index}_amount`) / taxRate);

        // Subtract fixed costs linked to current fee
        for (let i = 3; i <= 8; i++) {
            if (Number(model.get('cost_item_' + i + '_fee')) === index) {
                total -= model.get('cost_item_' + i + '_amount');
            }
        }

        model.set(`fee_item_${index}_net`, total);
    },

    /* Total net calculations */
    calcTotalOfNet: function (model) {
        console.debug('DealModel#calcTotalOfNet');
        model.set('total_of_net', (model.get('fee_item_1_net') || 0) + (model.get('fee_item_2_net') || 0) + (model.get('fee_item_3_net') || 0) + (model.get('fee_item_4_net') || 0) + (model.get('fee_item_5_net') || 0) + (model.get('fee_item_6_net') || 0) + (model.get('fee_item_7_net') || 0) + (model.get('fee_item_8_net') || 0) + (model.get('fee_item_9_net') || 0) + (model.get('fee_item_10_net') || 0) + (model.get('fee_item_11_net') || 0) + (model.get('fee_item_12_net') || 0));
    },

    calcTotalNet: function (model) {
        console.debug('DealModel#calcTotalNet');
        model.set('total_net', (model.get('fee_item_af_net') || 0) + (model.get('fee_item_ob_net') || 0) + (model.get('total_of_net') || 0));
    },

    /* Agent 0 split calculations */
    calcAgent0AdPre: function (model) {
        console.debug('DealModel#calcAgent0AdPre');
        model.set('split_agent_0_af_pre', model.get('fee_item_af_net') - model.get('split_agent_1_af_pre') - model.get('split_agent_2_af_pre') - model.get('split_agent_3_af_pre') - model.get('split_agent_4_af_pre') - model.get('split_agent_5_af_pre') - model.get('split_agent_6_af_pre') - model.get('split_agent_7_af_pre'));
    },

    calcAgent0ObPre: function (model) {
        console.debug('DealModel#calcAgent0ObPre');
        model.set('split_agent_0_ob_pre', model.get('fee_item_ob_net') - model.get('split_agent_1_ob_pre') - model.get('split_agent_2_ob_pre') - model.get('split_agent_3_ob_pre') - model.get('split_agent_4_ob_pre') - model.get('split_agent_5_ob_pre') - model.get('split_agent_6_ob_pre') - model.get('split_agent_7_ob_pre'));
    },

    calcAgent0OfPre: function (model) {
        console.debug('DealModel#calcAgent0OfPre');
        model.set('split_agent_0_of_pre', model.get('total_of_net') - model.get('split_agent_1_of_pre') - model.get('split_agent_2_of_pre') - model.get('split_agent_3_of_pre') - model.get('split_agent_4_of_pre') - model.get('split_agent_5_of_pre') - model.get('split_agent_6_of_pre') - model.get('split_agent_7_of_pre'));
    },

    /* Web split calculations */
    calcSplitWebAd: function (model) {
        console.debug('DealModel#calcSplitWebAd');
        model.set('split_web_af', model.get('split_web_percent_af') ? Math.round(model.get('fee_item_af_net') * model.get('split_web_percent_af') / 100) : 0);
    },

    calcSplitWebOb: function (model) {
        console.debug('DealModel#calcSplitWebOb');
        model.set('split_web_ob', model.get('split_web_percent_ob') ? Math.round(model.get('fee_item_ob_net') * model.get('split_web_percent_ob') / 100) : 0);
    },

    calcSplitWebOf: function (model) {
        console.debug('DealModel#calcSplitWebOf');
        model.set('split_web_of', model.get('split_web_percent_of') ? Math.round(model.get('total_of_net') * model.get('split_web_percent_of') / 100) : 0);
    },

    calcAgentAd: function (model, index) {
        console.debug('DealModel#calcAgentAd');

        if (model.get('v') === 1) {
            // Calculate using web deduction
            model.set(`split_agent_${index}_af`, model.get('split_web_percent_af') > 0 ? model.get(`split_agent_${index}_af_pre`) - Math.round(model.get(`split_agent_${index}_af_pre`) * model.get('split_web_percent_af') / 100) : model.get(`split_agent_${index}_af_pre`));
        }
        else if (model.get('v') >= 2) {
            // Calculate using agent deduction
            model.set(`split_agent_${index}_af`, model.get(`split_agent_${index}_pct_deduction`) ? model.get(`split_agent_${index}_af_pre`) - Math.round(model.get(`split_agent_${index}_af_pre`) * model.get(`split_agent_${index}_pct_deduction`) / 100) : model.get(`split_agent_${index}_af_pre`));
        }
    },

    calcAgentOb: function (model, index) {
        console.debug('DealModel#calcAgentOb');

        if (model.get('v') === 1) {
            // Calculate using web deduction
            model.set(`split_agent_${index}_ob`, model.get('split_web_percent_ob') > 0 ? model.get(`split_agent_${index}_ob_pre`) - Math.round(model.get(`split_agent_${index}_ob_pre`) * model.get('split_web_percent_ob') / 100) : model.get(`split_agent_${index}_ob_pre`));
        }
        else if (model.get('v') >= 2) {
            // Calculate using agent deduction
            model.set(`split_agent_${index}_ob`, model.get(`split_agent_${index}_pct_deduction`) ? model.get(`split_agent_${index}_ob_pre`) - Math.round(model.get(`split_agent_${index}_ob_pre`) * model.get(`split_agent_${index}_pct_deduction`) / 100) : model.get(`split_agent_${index}_ob_pre`));
        }
    },

    calcAgentOf: function (model, index) {
        console.debug('DealModel#calcAgentOf');

        if (model.get('v') === 1) {
            // Calculate using web deduction
            model.set(`split_agent_${index}_of`, model.get('split_web_percent_of') > 0 ? model.get(`split_agent_${index}_of_pre`) - Math.round(model.get(`split_agent_${index}_of_pre`) * model.get('split_web_percent_of') / 100) : model.get(`split_agent_${index}_of_pre`));
        }
        else if (model.get('v') >= 2) {
            // Calculate using agent deduction
            model.set(`split_agent_${index}_of`, model.get(`split_agent_${index}_pct_deduction`) ? model.get(`split_agent_${index}_of_pre`) - Math.round(model.get(`split_agent_${index}_of_pre`) * model.get(`split_agent_${index}_pct_deduction`) / 100) : model.get(`split_agent_${index}_of_pre`));
        }
    },

    /* Total calculations */
    calcAgentTotalPre: function (model, index) {
        console.debug('DealModel#calcAgentTotalPre');
        model.set(`split_agent_${index}_total_pre`, (model.get(`split_agent_${index}_af_pre`) || 0) + (model.get(`split_agent_${index}_ob_pre`) || 0) + (model.get(`split_agent_${index}_of_pre`) || 0));
    },

    calcSplitWebTotal: function (model) {
        console.debug('DealModel#calcSplitWebTotal');
        model.set('split_web_total', (model.get('split_web_af') || 0) + (model.get('split_web_ob') || 0) + (model.get('split_web_of') || 0));
    },

    calcAgentTotal: function (model, index) {
        console.debug('DealModel#calcAgentTotal');
        model.set(`split_agent_${index}_total`, (model.get(`split_agent_${index}_af`) || 0) + (model.get(`split_agent_${index}_ob`) || 0) + (model.get(`split_agent_${index}_of`) || 0));
    },

    // Default attributes for the contract item
    defaults: function () {
        return {
            id: null,
        };
    },

});

export default DealModel;
