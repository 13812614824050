import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import template from '../templates/summary-results.html';

export default class DealSummaryResultsView extends View {
    preinitialize(options) {
        this.events = {
            'click ul.pagination a': this.changePage,
        };

        this.criteria = options.criteria || null;
        this.tableHeaders = [];
    }

    initialize() {
        // When criteria changes; search
        this.listenTo(this.criteria, 'change', this.search);

        // When collection updates/resets; render
        this.listenTo(this.collection, 'change reset', this.render);
    }

    search() {
        console.debug('DealSummaryResultsView#search');

        // Fetch collection
        this.collection.fetch({
            data: _.pick(this.criteria.toJSON(), (value) => {
                return value !== '';
            }),
            reset: true,
        });
    }

    changePage($e) {
        console.debug('DealSummaryResultsView#changePage');

        $e.preventDefault();

        // Set criteria offset from clicked UI element
        this.criteria.set('offset', Number($e.target.dataset.offset));
    }

    render() {
        console.debug('DealSummaryResultsView#render');

        if (this.collection.toJSON().length > 0) {
            this.tableHeaders = Object.keys(this.collection.toJSON()[0]);
        }

        this.el.innerHTML = template({
            results: this.collection.toJSON(),
            criteria: this.criteria.toJSON(),
            tableHeaders: this.tableHeaders,
            Formatter,
        });

        // Setup tooltip
        this.$el.find('[rel="tooltip"]').tooltip();

        return this;
    }
}
