import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

// Export the "Staff" Bloodhound suggestion engine
export default new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('first_name', 'last_name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        transport: function (options, onSuccess, onError) {
            OAuth2Client.ajax(options)
                .then(onSuccess)
                .catch(onError);
        },
        url: config.api.url + 'staff',
        prepare: function (query, settings) {
            settings.data = {
                name: query,
            };

            return settings;
        },
        transform: function (response) {
            const results = [];
            // Add "staff" type to each result
            response.results.forEach((result) => {
                result._type = 'staff';
                results.push(result);
            });
            return results;
        },
    },
    identify: function (obj) {
        return obj.id;
    },
});