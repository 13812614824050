module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n    <div class="row align-items-center">\n        <div class="col">\n            <h1 class="h2 my-0">Tenants Report</h1>\n        </div>\n\n        ';
 if (isExportAllowed) { ;
__p += '\n        <div class="col-auto">\n            <button class="btn btn-success" data-action="export">\n                <i class="fa fa-download" aria-hidden="true"></i> Export\n            </button>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n\n<hr>\n\n<div id="results"></div>';

}
return __p
};
