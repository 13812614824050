import { Collection } from 'backbone';
import config from '@/js/app/config';
import ReportAccountingResultModel from '../models/result';

export default Collection.extend({

    url: config.api.url + 'reports/accounting',

    model: ReportAccountingResultModel,

});