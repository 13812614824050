import { history, View } from 'backbone';
import BloodhoundIndividualDataset from '@/js/app/bloodhound/datasets/individual';
import Preferences from '@/js/app/preferences';
import propertyRestoreSelectedService from '@/js/app/property/services/restore-selected';
import PropertySearchResultsView from '@/js/app/property/views/search-results';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import TypeaheadDefault from '@/js/app/typeahead/defaults';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import ViewingCollection from '../collections/viewings';
import ViewingModel from '../models/viewing';
import template from '../templates/viewing_add.html';
import ViewingNewView from './new';

export default class ViewingAddView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'property_viewing';

        this.events = {
            'click [data-action="new"]': this.newViewing,
            'submit form': this.handleSubmit,
        };

        const selected = propertyRestoreSelectedService();

        // Get properties
        const query = {
            sort_key: Preferences.sort_key,
            sort_order: Preferences.sort_order,
            limit: 1000,
            id: 0 == selected.length ? -1 : selected
        };

        // Create subviews
        this.subviews = {
            clientTypeahead: new UiTypeaheadView({
                placeholder: 'Client',
                options: TypeaheadDefault,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundIndividualDataset),
                ],
            }),
            viewings: new UiDynamicSelectView({
                collection: new ViewingCollection(),
                mapper: function (model) {
                    return {
                        label: model.get('viewing_date'),
                        value: model.id,
                    };
                },
                prependOptions: [
                    { label: '', value: '' },
                ],
            }),
            properties: new PropertySearchResultsView({
                query: query,
                format: 'short',
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'render');

        this.render();

        // Setup listeners
        this.listenTo(this.subviews.clientTypeahead, 'select', this.selectClient);
        this.listenTo(this.subviews.clientTypeahead, 'clear', this.clearClient);
        this.listenTo(this.subviews.viewings.collection, 'add', this.selectNewViewing);

        this.subviews.properties.render().fetch();
    }

    render() {
        console.debug('ViewingAddView#render');

        this.el.innerHTML = template();

        // Set element for subviews and render
        this.subviews.clientTypeahead.setElement(this.$el.find('#cntr-client_id')).render();
        this.subviews.viewings.setElement(this.$el.find('#field-viewing')).render();
        this.subviews.properties.setElement(this.el.querySelector('#results')).render();

        // Disable "new" button
        /** @todo Should be able to set desired attributes externally, and changes should trigger view to update DOM */
        this.el.querySelector('[data-action="new"]').disabled = true;

        return this;
    }

    newViewing() {
        console.debug('ViewingAddView#newViewing');

        // Create new view, with new viewing model
        new ViewingNewView({
            model: new ViewingModel({
                addressbook_id: this.subviews.clientTypeahead.datum.id,
            }),
            collection: this.subviews.viewings.collection,
        }).render();
    }

    selectNewViewing(model) {
        console.debug('ViewingAddView#selectNewViewing');

        // Set selected value to newly added list
        this.subviews.viewings.selected = model.id;
    }

    selectClient($e, datum) {
        console.debug('ViewingAddView#selectClient');

        // Fetch
        this.subviews.viewings.collection.fetch({
            data: {
                addressbook_id: datum.id,
            },
            reset: true,
        })
            .then(() => {
                // Enable list selection dropdown and "new viewing" button
                this.el.querySelector('select[name="viewing"]').disabled = false;
                this.el.querySelector('[data-action="new"]').disabled = false;
            });
    }

    clearClient() {
        console.debug('ViewingAddView#clearClient');

        // Empty and disable viewing selection dropdown and button
        /**
         * @todo Ideally we just set a property on the subview, which would react to the change by updating the DOM... use ES6 Proxy?
         */
        this.el.querySelector('select[name="viewing"]').innerHTML = '';
        this.el.querySelector('select[name="viewing"]').disabled = true;
        this.el.querySelector('[data-action="new"]').disabled = true;
        this.subviews.viewings.selected = null;
    }

    handleSubmit($e) {
        console.debug('ViewingAddView#handleSubmit');

        $e.preventDefault();

        // Get selected properties
        const properties = propertyRestoreSelectedService();
        if (_.isEmpty(properties)) {
            return;
        }

        // Get chosen viewing
        const viewing = this.el.querySelector('#field-viewing').value;
        if (_.isEmpty(viewing)) {
            return;
        }

        // Create model with viewing ID
        const model = new ViewingModel({
            id: viewing,
        });

        // Fetch model data
        model.fetch()
            .then((response) => {
                // Merge properties with existing properties and save
                return model.save('properties', _.union(response.properties, properties), { patch: true, wait: true });
            })
            .then(() => {
                // Clear selections
                propertyRestoreSelectedService([]);

                // Redirect to edit viewing
                history.navigate('property/viewing/edit/' + viewing, { trigger: true });
            });
    }
}