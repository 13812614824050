import { getName } from '@/js/app/formatter';
// import templateNotFound from '@/js/app/ui/templates/typeahead_notfound.html';

export default function (dataset) {
    return {
        name: 'individuals',
        displayKey: getName,
        source: dataset,
        limit: 10,
        // templates: {
        //     notFound: templateNotFound({ message: 'Unable to find any individuals that match your search' }),
        // }
    };
}