import config from '@/js/app/config';
import { Model } from 'backbone';

const RevenueModel = Model.extend({

    urlRoot: config.api.url + 'revenue',

});

export default RevenueModel;
