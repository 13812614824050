import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import template from '../templates/file_item.html';

export default class DealFileItemView extends View {
    preinitialize() {
        this.tagName = 'tr';

        this.events = {
            'click [data-action="download"]': this.download,
            'click [data-action="delete"]': this.delete,
        };
    }

    initialize() {
        // When model is destroyed: remove
        this.listenTo(this.model, 'destroy', this.remove);
    }

    render() {
        console.debug('DealFileItemView#render');

        this.el.innerHTML = template({
            file: this.model.toJSON(),
            Formatter: Formatter,
        });

        return this;
    }

    download($e) {
        console.debug('DealFileItemView#download');

        $e.preventDefault();

        this.model.download();
    }

    delete($e) {
        console.debug('DealFileItemView#delete');

        $e.preventDefault();
        $e.stopPropagation();

        if (
            confirm(
                'Are you sure you want to delete this file? This cannot be undone!'
            )
        ) {
            // Destroy model
            this.model.destroy({
                wait: true,
            });
        }
    }
}
