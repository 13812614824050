import OAuth2Client from '@/js/libs/oauth2-client';
import config from './config';
import logout from './logout';

export default new OAuth2Client({
    url: config.auth.url,
    client_id: config.auth.client_id,
    client_secret: config.auth.client_secret,
    token_name: 'hj-access-token',
    onRefreshFail: logout
});
