module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="actionsBar mb-3">\n    <!-- <button type="button" title="New List" class="btn btn-success btn-sm" data-action="new"><i class="fa fa-plus" aria-hidden="true"></i> List</button> -->\n    <!--<button type="button" title="Refresh" class="btn btn-sm pull-right" data-action="refresh"><i class="fa fa-refresh" aria-hidden="true"></i></button>-->\n</div>\n\n<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Name</th>\n            <th>Type</th>\n            <th># Properties</th>\n            <th>Created By</th>\n            <th>Created Date</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n    ';
 property_lists.forEach((l) => { ;
__p += '\n        <tr>\n            <td><a href="#property/lists/edit/' +
((__t = ( l.id )) == null ? '' : __t) +
'">' +
((__t = ( l.id )) == null ? '' : __t) +
'</a></td>\n            <td><a href="#property/lists/edit/' +
((__t = ( l.id )) == null ? '' : __t) +
'">' +
((__t = ( l.name )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( l.type )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( _.size(l.properties) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( _.isObject(l.created_by) ? Formatter.getName(l.created_by) : '' )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( moment.utc(l.created_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'</td>\n            <td>\n            ';
 if (_.size(l.properties) > 0) { ;
__p += '\n                <a href="' +
((__t = ( Formatter.zumen_url(l.type, l.properties, 'en') )) == null ? '' : __t) +
'" target="' +
((__t = ( _.uniqueId('print_zumen') )) == null ? '' : __t) +
'"><i class="fa fa-print"></i></a>\n            ';
 } ;
__p += '\n            </td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
