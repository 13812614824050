module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row align-items-center">\n    <div class="col">\n        <h1 class="h2 my-0">Enquiry: ' +
((__t = ( inquiry.id )) == null ? '' : __t) +
'</h1>\n    </div>\n\n    <div class="col-auto">\n        <div class="d-flex align-items-center">\n            ';
 if (isInquiryUpdateAllowed) { ;
__p += '\n                <button\n                id="spamButton"\n                type="button"\n                class="btn ' +
((__t = ( inquiry.spam_level > 0 ? 'btn-success' : 'btn-warning' )) == null ? '' : __t) +
' ml-1"\n                data-action="' +
((__t = ( inquiry.spam_level > 0 ? 'unmark-spam' : 'mark-spam' )) == null ? '' : __t) +
'"\n                >\n                    <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>\n                    ' +
((__t = ( inquiry.spam_level> 0 ? 'Unmark Spam' : 'Mark as Spam' )) == null ? '' : __t) +
'\n                </button>\n            ';
 } ;
__p += '\n\n            ';
 if (inquiry.addressbook_id) { ;
__p += '\n                <a href="#people/edit/' +
((__t = ( inquiry.addressbook_id )) == null ? '' : __t) +
'" class="btn btn-primary ml-1" data-action>Go to Client</a>\n            ';
 } else { ;
__p += '\n                <button type="button" class="btn btn-success ml-1" data-action="create-client">\n                    <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>\n                    Create Client\n                </button>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n\n<hr>\n\n<div class="row">\n    <p class="col">\n        <strong>Timestamp:</strong> ' +
((__t = ( moment.utc(inquiry.created_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'\n    </p>\n    <p class="col">\n        <strong>Type:</strong> ' +
((__t = ( TextHelper._(inquiry.inquiry_type, 'inquiry_type') )) == null ? '' : __t) +
'\n    </p>\n</div>\n\n<div class="row">\n    <p class="col">\n        <strong>First Name:</strong> ' +
((__t = ( inquiry.first_name )) == null ? '' : __t) +
'\n    </p>\n    <p class="col">\n        <strong>Last Name:</strong> ' +
((__t = ( inquiry.last_name )) == null ? '' : __t) +
'\n    </p>\n</div>\n\n<div class="row">\n    <p class="col">\n        <strong>Email:</strong> ' +
((__t = ( inquiry.email )) == null ? '' : __t) +
'\n    </p>\n\n    <p class="col">\n        <strong>Telephone:</strong> ' +
((__t = ( inquiry.telephone )) == null ? '' : __t) +
'\n    </p>\n</div>\n\n<p>\n    <strong>Message:</strong> ' +
((__t = ( Formatter.nl2br(_.escape(inquiry.message)) )) == null ? '' : __t) +
'\n</p>\n\n';
 if (inquiry.custom && (inquiry.custom.property_name || inquiry.custom.property_location)) { ;
__p += '\n    <div class="row">\n        <p class="col">\n            <strong>Property name:</strong> ' +
((__t = ( inquiry.custom.property_name )) == null ? '' : __t) +
'\n        </p>\n\n        <p class="col">\n            <strong>Property location:</strong> ' +
((__t = ( inquiry.custom.property_location )) == null ? '' : __t) +
'\n        </p>\n    </div>\n';
 } ;
__p += '\n\n';
 if (inquiry.custom && inquiry.inquiry_type === 'short-term') { ;
__p += '\n<div class="row">\n    <p class="col">\n        <strong>Date from:</strong> ' +
((__t = ( inquiry.custom.date_from )) == null ? '' : __t) +
'\n    </p>\n\n    <p class="col">\n        <strong>Date to:</strong> ' +
((__t = ( inquiry.custom.date_to )) == null ? '' : __t) +
'\n    </p>\n</div>\n';
 } ;
__p += '\n\n';
 if (inquiry.building_id) { ;
__p += '\n    <p>\n    ';
 if (inquiry.unit_id) { ;
__p += '\n        <strong>Property:</strong>\n        <a href="' +
((__t = ( Formatter.property_edit_url(inquiry.property) )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.unit_id )) == null ? '' : __t) +
'</a>\n    ';
 } else { ;
__p += '\n        <strong>Building:</strong>\n        <a href="' +
((__t = ( Formatter.building_edit_url(inquiry.property) )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.building_id )) == null ? '' : __t) +
'</a>\n    ';
 } ;
__p += '\n    </p>\n';
 } ;
__p += '\n\n<div class="row">\n    ';
 if (inquiry.meta.page_url && inquiry.meta.page_title) { ;
__p += '\n        <p class="col">\n            <strong>Page:</strong> <a href="' +
((__t = ( inquiry.meta.page_url )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.meta.page_title )) == null ? '' : __t) +
'</a>\n        </p>\n    ';
 } ;
__p += '\n\n    <p class="col">\n        <strong>Site ID:</strong> ' +
((__t = ( inquiry.meta.api_client_id )) == null ? '' : __t) +
'\n    </p>\n</div>\n\n<div class="row">\n    <p class="col">\n        <strong>IP:</strong> ' +
((__t = ( inquiry.ip )) == null ? '' : __t) +
'\n    </p>\n\n    <p class="col">\n        <strong>Country:</strong> ' +
((__t = ( inquiry.country_code || '-' )) == null ? '' : __t) +
' (' +
((__t = ( Formatter.countryName(inquiry.country_code) )) == null ? '' : __t) +
')\n    </p>\n</div>\n\n';
 const remainingCustom = _.omit(inquiry.custom, ['date_from', 'date_to', 'property_name', 'property_location']) ;
__p += '\n\n';
 if (!_.isEmpty(remainingCustom)) { ;
__p += '\n    <p class="font-weight-bolder">Custom Data</p>\n\n    <table class="table table-sm">\n        <thead>\n            <tr>\n                <th>Key</th>\n                <th>Value</th>\n            </tr>\n        </thead>\n        <tbody>\n            ';
 for (const [key, value] of Object.entries(remainingCustom)) { ;
__p += '\n            <tr>\n                <td>' +
((__t = ( key )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( value )) == null ? '' : __t) +
'</td>\n            </tr>\n            ';
 } ;
__p += '\n        </tbody>\n    </table>\n';
 } ;
__p += '\n\n';
 const remainingMeta = _.omit(inquiry.meta, ['page_url', 'page_title', 'api_client_id']) ;
__p += '\n\n<p class="font-weight-bolder">Meta Data <button type="button" class="btn btn-secondary btn-sm ml-2" data-toggle="collapse" data-target="#table-meta">Toggle</button></p>\n\n<div id="table-meta" class="collapse">\n    <table class="table table-sm">\n        <thead>\n            <tr>\n                <th>Key</th>\n                <th>Value</th>\n            </tr>\n        </thead>\n        <tbody>\n            ';
 for (const [key, value] of Object.entries(remainingMeta)) { ;
__p += '\n            <tr>\n                <td>' +
((__t = ( key )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( value )) == null ? '' : __t) +
'</td>\n            </tr>\n            ';
 } ;
__p += '\n        </tbody>\n    </table>\n</div>';

}
return __p
};
