import DivisionsCollection from '@/js/app/division/collections/divisions';
import { getFinancialYear } from '@/js/app/formatter';
import Session from '@/js/app/session';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import ReportYtdResultModel from '../models/result';
import template from '../templates/generate.html';
import ReportYtdResultsView from './results';

export default class ReportYtdGenerateView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'reports';

        this.events = {
            'change #frm-report-criteria': this.generate,
        };

        // Determine current financial year
        this.currentFinancialYear = getFinancialYear();

        // Override default options
        options = _.extend({
            financialYear: this.currentFinancialYear,
            divisionId: null,
        }, options || {});

        // Build field defaults
        this.financialYear = options.financialYear;
        this.divisionId = options.divisionId;

        // If allowed to access revenue (all) or revenue (company)
        if (Session.isAllowed('phnx:revenue/all') || Session.isAllowed('phnx:revenue/company')) {
            // Generate year range from 2017 to next FY (_.range end is exclusive, hence + 2)
            this.years = _.range(2017, this.currentFinancialYear + 2);
        } else {
            // Generate year range from previous FY to next FY (_.range end is exclusive, hence + 2)
            this.years = _.range(this.currentFinancialYear - 1, this.currentFinancialYear + 2);
        }

        // Create subviews
        this.subviews = {
            results: new ReportYtdResultsView({
                model: new ReportYtdResultModel,
            }),
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: new DivisionsCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                prependOptions: [
                    { label: 'All', value: '' }
                ]
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'generate');

        this.listenTo(this.subviews.results.model, 'request', function () {
            this.el.querySelector('#spinner').classList.remove('d-none');
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.subviews.results.model, 'request_done', function () {
            this.el.querySelector('#spinner').classList.add('d-none');
        });

        // Fetch divisions
        this.subviews.divisionSelect.collection.fetch({
            data: {
                company_id: Session.data.company_id,
                active: 1,
                order: 'order',
            },
        });
    }

    render() {
        console.debug('ReportYtdGenerateView#render');

        // Render template
        this.el.innerHTML = template({
            years: this.years,
            Session,
        });

        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        // If not allowed to access revenue (all) and revenue (company)
        if (!Session.isAllowed('phnx:revenue/all') && !Session.isAllowed('phnx:revenue/company')) {
            // Disable division selection
            this.el.querySelector('[name="division_id"]').disabled = true;
        }

        // Populate form
        populateForm(this.el.querySelector('#frm-report-criteria'), {
            fy: this.financialYear
        });

        this.subviews.divisionSelect.setSelected(this.divisionId);

        // Bind results view
        this.subviews.results.setElement(this.el.querySelector('#results'));

        // If no division ID, generate now
        // Note: this is hacky.. looking for a better solution when the division list is loaded dynamically
        if (!this.divisionId) {
            this.generate();
        }

        return this;
    }

    handleFormChange($e) {
        $e.preventDefault();

        this.generate();
    }

    generate() {
        console.debug('ReportYtdGenerateView#generate');

        const formData = new FormData(this.el.querySelector('form'));

        // Convert formdata to object
        const data = {};
        formData.forEach((value, key) => data[key] = value);

        // If not allowed to access revenue (all) and revenue (company)
        if (!Session.isAllowed('phnx:revenue/all') && !Session.isAllowed('phnx:revenue/company')) {
            // Force division ID
            data.division_id = this.divisionId;
        }

        if (data.division_id) {
            // Set criteria
            this.subviews.results.criteria = data;

            // Initiate fetch
            this.subviews.results.model.fetchDivision({
                data,
            });
        } else {
            // Fetch for current company
            data.company_id = Session.data.company_id;

            // Remove division ID
            delete (data.division_id);

            // Set criteria
            this.subviews.results.criteria = data;

            // Initiate fetch
            this.subviews.results.model.fetchCompany({
                data,
            });
        }
    }
}
