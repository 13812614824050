module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ol class="train_lines">\n    ';
 if (stations) { ;
__p += '\n    ';
 _.each(stations, (landmark, i) => { ;
__p += '\n    <li data-station="' +
((__t = ( i )) == null ? '' : __t) +
'">\n        ' +
((__t = ( landmark.station.station_name )) == null ? '' : __t) +
' - ' +
((__t = ( landmark.station.line.line_name )) == null ? '' : __t) +
' <input type="hidden" name="station_cd" value="' +
((__t = ( landmark.station.station_cd )) == null ? '' : __t) +
'" data-partial>\n        (<input type="number" name="min" class="mini" size="5" maxlength="3" min="0" data-group="station" data-partial> min.)\n        <a href="#" data-action="delete-station"><i class="fa fa-times" aria-hidden="true"></i></a>\n    </li>\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n        <div class="alert">' +
((__t = ( Texts.NO_TRAIN_LINES )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n</ol>';

}
return __p
};
