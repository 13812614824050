import { Collection } from 'backbone';
import config from '@/js/app/config';
import ChangelogModel from '../models/changelog';

export default Collection.extend({

    url: config.api.url + 'changelogs',

    model: ChangelogModel,

});