module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <div class="media-item-list row"></div>\n\n    <fieldset>\n        <div class="form-group row">\n            <label class="col-2 col-form-label">Photo Upload</label>\n            <div class="col-auto">\n                <button type="button" class="btn-file-upload btn btn-info">\n                    <i class="fa fa-upload" aria-hidden="true"></i>\n                    Choose...\n                </button>\n                <input class="d-none file-selector" type="file" accept="image/*" multiple>\n            </div>\n        </div>\n    </fieldset>\n\n    <div class="cntr-upload-status"></div>\n\n    <fieldset>\n        <div class="form-group row">\n            <label class="col-2 col-form-label">Video Link</label>\n            <div class="col-8">\n                <input class="form-control" id="field-video_link" name="video_link" type="url">\n            </div>\n        </div>\n\n        ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n        <div class="form-group row">\n            <label class="col-2 col-form-label">VR Tour Link</label>\n            <div class="col-8">\n                <input class="form-control" id="field-vr_tour_link" name="vr_tour_link" type="url">\n            </div>\n        </div>\n        ';
 } ;
__p += '\n    </fieldset>\n</form>';

}
return __p
};
