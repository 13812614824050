import { Collection } from 'backbone';
import config from '@/js/app/config';
import ViewingModel from '../models/viewing';

export default Collection.extend({

    url: config.api.url + 'viewings',

    model: ViewingModel,

    parse: function(response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});