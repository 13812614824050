module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Search Results</h2>\n\n<table class="table table-sm table-striped">\n  <thead>\n    <tr>\n      ';
 tableHeaders.forEach((header) => { ;
__p += '\n      <th>' +
((__t = ( header.charAt(0).toUpperCase() + header.slice(1) )) == null ? '' : __t) +
'</th>\n      ';
 }) ;
__p += '\n    </tr>\n  </thead>\n\n  <tbody>\n    ';
 results.forEach((result) => { ;
__p += '\n    <tr>\n      ';
 for (const [key, value] of Object.entries(result)) { ;
__p += '\n        ';
 if (key === 'value') { ;
__p += '\n        <td>\n          ' +
((__t = ( value ?
          Number(value).toLocaleString(
            "ja-JP", {
              style: "currency",
              currency: "JPY",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) : 'No Data' )) == null ? '' : __t) +
'\n        </td>\n        ';
 } else if (key === 'country') { ;
__p += '\n          ';
 if (value) { ;
__p += '\n            <td><img src="https://flagsapi.com/' +
((__t = ( value === 'UK' ? 'GB' : value )) == null ? '' : __t) +
'/shiny/32.png"> - ' +
((__t = ( value )) == null ? '' : __t) +
'</td>\n          ';
 } else { ;
__p += '\n            <td>No Country Data</td>\n          ';
 } ;
__p += '\n        ';
 } else { ;
__p += '\n        <td>' +
((__t = ( value ? value : 'No Data' )) == null ? '' : __t) +
'</td>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </tr>\n    ';
 }) ;
__p += '\n  </tbody>\n</table>';

}
return __p
};
