import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import propertyRestoreSelectedService from '@/js/app/property/services/restore-selected';
import PropertySearchResultsView from '@/js/app/property/views/search-results';
import ViewingModel from '../models/viewing';
import template from '../templates/edit.html';

export default class ViewingEditView extends View {
    preinitialize(options) {
        this.events = {
            'change form input,form select,form textarea': this.updateModel,
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="remove-property"]': this.removeProperty,
        };

        this.model = new ViewingModel({
            id: options.viewingId,
        });

        // Get properties
        const query = {
            sort_key: 'updated_at',
            sort_order: 'DESC',
            limit: 1000,
            include: ['agent_company'],
        };

        this.subviews = {
            results: new PropertySearchResultsView({
                query,
                format: 'long',
                buttons: {
                    type: 'unit',
                    add_to_list: true,
                    send: true,
                    print: true,
                    add_to_viewing: true,
                    remove_from_viewing: true,
                    viewing_id: options.viewingId,
                },
            })
        };
    }

    initialize() {
        // Functions that will trigger from DOM events need to bind to "this"
        _.bindAll(this, 'render', 'updateModel', 'delete', 'removeProperty');

        this.render();

        this.listenTo(this.model, 'change', this.handleModelChange);

        // Trigger fetch, and call "render" on success
        this.model.fetch({
            data: {
                include: ['created_by'],
            },
            success: this.render,
        });
    }

    render() {
        console.debug('ViewingEditView#render');

        const viewing = this.model.toJSON();

        this.el.innerHTML = template();

        populateForm(this.el, viewing);

        propertyRestoreSelectedService([]);

        this.$el.find('#results').html(this.subviews.results.render().el);

        return this;
    }

    handleModelChange(model) {
        console.debug('ViewingEditView#handleModelChange');

        this.subviews.results.query.id = model.get('properties');
        this.subviews.results.fetch();
    }

    updateModel($e) {
        console.debug('ViewingEditView#updateModel');

        // Determine new value
        const t = $e.currentTarget;
        let value;

        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else; use value or null
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.save(t.name, value, { patch: true, wait: true });
    }

    delete() {
        console.debug('ViewingEditView#delete');

        if (confirm('Are you sure you want to delete this viewing? This cannot be undone!')) {
            this.model.destroy({
                success: function () {
                    window.history.go(-1);
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    removeProperty($e) {
        console.debug('ViewingEditView#removeProperty');

        $e.preventDefault();

        // Get selected properties
        const properties = propertyRestoreSelectedService();
        if (_.isEmpty(properties)) {
            return;
        }

        // Remove properties from viewing and reload
        this.model.save('properties', _.difference(this.model.get('properties'), properties), { patch: true, wait: true })
            .then(() => window.location.reload());
        /** @todo Intelligently remove from view */
    }
}