import { View } from 'backbone';
import config from '@/js/app/config';
import template from '../templates/edit_media_item.html';

export default class PropertyEditMediaItemView extends View {
    #mode = 'read';

    preinitialize(options) {
        this.tagName = 'div';

        this.className = 'col-3 mb-3 media-item';

        this.events = {
            'change [name="type"]': this.changeType,
            'click [data-action="delete"]': this.delete,
            'dragstart': this.handleDragStart,
        };

        this.attributes = {
            draggable: true,
        };

        // Pickup options
        this.structure_type = options.structure_type;
        this.building_id = options.building_id;
        this.unit_id = options.unit_id || undefined;
    }

    initialize() {
        // When model is removed from collection: remove from DOM
        this.listenTo(this.model, 'remove', this.remove);
    }

    render() {
        console.debug('PropertyEditMediaItemView#render');

        /**
         * This primarily works on the presence of an imageUrl, which is
         * built from the model's filename. When rendering, we need to know if
         * this view was created with a file or not. If created with a file, we
         * could avoid building and providing the imageUrl to template(). The image
         * won't show and won't even try to load, but when the FileReader finishes
         * reading the file, the callback will target the <img> tag and will set the
         * src to the read file's data.
         *
         * Need to use object-fit or something to make the preview look close to
         * the real thing.
         *
         * Could also create something to, on save, replace the src attribute with
         * the model's filename, so it will load the real image from the CDN.
         *
         * What would be even cooler is not uploading anything, but instead just
         * previewing. Then the save() function could go and collect each model's
         * file and upload it BEFORE the saving the model.
         */

        let imageUrl = config.image.url;
        if (this.structure_type === 'unit') {
            imageUrl += `u/${this.unit_id}/${this.model.get('filename')}`;
        } else {
            imageUrl += `b/${this.building_id}/${this.model.get('filename')}`;
        }

        this.el.innerHTML = template({
            imageUrl,
            structure_type: this.structure_type,
        });

        if (this.model.imageFile) {
            this.#loadImagePreview(this.model.imageFile);
        }

        // Setup lightbox
        this.$el.find('.lightbox').fancybox({
            margin: 30,
        });

        // Set value of image type
        this.el.querySelector('[name="type"]').value = this.model.get('type');

        this.#initializeReadEditMode();

        return this;
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();
    }

    switchEditMode() {
        this.#mode = 'edit';

        this.#initializeReadEditMode();
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            this.el.querySelectorAll('input,select,textarea').forEach(el => el.disabled = true);

            // Prevent item from being dragged
            this.el.draggable = false;

            // Hide "Delete" link
            this.el.querySelector('[data-action="delete"]').classList.add('d-none');
        } else if (this.#mode === 'edit') {
            this.el.querySelectorAll('input,select,textarea').forEach(el => el.disabled = false);

            // Allow item to be dragged
            this.el.draggable = true;

            // Show "Delete" link
            this.el.querySelector('[data-action="delete"]').classList.remove('d-none');
        }
    }

    #loadImagePreview(file) {
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            () => {
                this.el.querySelector('img').src = reader.result;
            }
        );

        reader.readAsDataURL(file);
    }

    changeType($e) {
        console.debug('PropertyEditMediaItemView#changeType');

        $e.stopPropagation();

        // Set type in model
        this.model.set('type', $e.currentTarget.value);
    }

    delete($e) {
        console.debug('PropertyEditMediaItemView#delete');

        $e.preventDefault();
        $e.stopPropagation();

        if (confirm('Are you sure you want to delete this photo? This cannot be undone!')) {
            // Remove model from it's collection
            this.model.collection.remove(
                this.model,
            );
        }
    }

    handleDragStart($e) {
        console.debug('PropertyEditMediaItemView#handleDragStart');

        // Set drop effect to move to attach element to mouse pointer
        const originalEvent = $e.originalEvent;
        originalEvent.dataTransfer.dropEffect = 'move';
    }
}