module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td class="align-middle text-nowrap">\n    ' +
((__t = ( moment.utc(inquiry.created_at).local().format('YYYY-MM-DD HH:mm') )) == null ? '' : __t) +
'\n</td>\n<td class="align-middle">\n    ' +
((__t = ( inquiry.first_name )) == null ? '' : __t) +
' ' +
((__t = ( inquiry.last_name )) == null ? '' : __t) +
'\n</td>\n<td class="align-middle">\n    ' +
((__t = ( TextHelper._(inquiry.inquiry_type, 'inquiry_type') )) == null ? '' : __t) +
'\n</td>\n<td class="align-middle">\n    ';
 if (inquiry.meta && inquiry.meta.page_title && inquiry.meta.page_url) { ;
__p += '\n        <a href="' +
((__t = ( inquiry.meta.page_url )) == null ? '' : __t) +
'" target="_new">' +
((__t = ( inquiry.meta.page_title )) == null ? '' : __t) +
'</a>\n    ';
 } else { ;
__p += '\n        -\n    ';
 } ;
__p += '\n    </td>\n<td class="align-middle">\n    <abbr title="' +
((__t = ( Formatter.countryName(inquiry.country_code) )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.country_code || '-' )) == null ? '' : __t) +
'</abbr>\n</td>\n<td class="align-middle">\n';
 if (inquiry.addressbook_id) { ;
__p += '\n    <a href="#people/edit/' +
((__t = ( inquiry.addressbook_id )) == null ? '' : __t) +
'">' +
((__t = ( inquiry.addressbook_id )) == null ? '' : __t) +
'</a>\n';
 } else { ;
__p += '\n    <span class="badge badge-danger">No Client</span>\n';
 } ;
__p += '\n</td>\n<td class="align-middle">\n    <a href="#inquiries/' +
((__t = ( inquiry.id )) == null ? '' : __t) +
'" class="btn btn-secondary">View</a>\n</td>';

}
return __p
};
