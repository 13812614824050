module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-header" id="kpi' +
((__t = ( model.year )) == null ? '' : __t) +
'">\n    <h2 class="mb-0">\n        <button class="btn btn-link btn-block text-center" type="button" data-toggle="collapse"\n            data-target="#collapse' +
((__t = ( model.year )) == null ? '' : __t) +
'" aria-expanded="false" aria-controls="collapse' +
((__t = ( model.year )) == null ? '' : __t) +
'">\n            FY ' +
((__t = ( model.year )) == null ? '' : __t) +
'\n        </button>\n    </h2>\n</div>\n\n<div id="collapse' +
((__t = ( model.year )) == null ? '' : __t) +
'" class="collapse" aria-labelledby="kpi' +
((__t = ( model.year )) == null ? '' : __t) +
'">\n    <div class="card-body">\n\n        <div class="row justify-content-center">\n            <div class="col-9 offset-1">\n                <table class="table table-sm mb-0">\n                    <thead>\n                        <tr>\n                            <th scope="col">Period</th>\n                            <th scope="col">Division</th>\n                            <th class="text-right" scope="col">Revenue</th>\n                        </tr>\n                    </thead>\n\n                    <tbody id="kpiTableBody"></tbody>\n                </table>\n            </div>\n\n            ';
 if (model.year >= financialYear - 1) { ;
__p += '\n            <div class="col-auto">\n                <button type="button" class="btn btn-outline-success" data-part="read" data-action="edit"\n                    data-toggle="tooltip" data-placement="top" title="Edit">\n                    <i class="fa fa-pencil" aria-hidden="true"></i>\n                </button>\n\n                <button type="button" class="btn btn-primary d-none" data-part="edit" data-action="save"\n                    data-toggle="tooltip" data-placement="top" title="Save">\n                    <i class="fa fa-check" aria-hidden="true"></i>\n                </button>\n                <button type="button" class="btn btn-secondary d-none" data-part="edit" data-action="cancel"\n                    data-toggle="tooltip" data-placement="top" title="Cancel">\n                    <i class="fa fa-times" aria-hidden="true"></i>\n                </button>\n\n                <div data-part="loading" class="spinner-border text-primary d-none" role="status">\n                    <span class="sr-only">Loading...</span>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>';

}
return __p
};
