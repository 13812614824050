module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n    <h1 class="h2 my-0">Revenue Report</h1>\n</div>\n\n<hr>\n\n<div class="row">\n    <div class="col-6" id="summary"></div>\n    <div class="col-6">\n        <form id="frm-report-criteria" name="report_criteria">\n            <div class="generate_form">\n                <fieldset>\n                    <legend>Report Setup</legend>\n\n                    <div class="form-group row">\n                        <label class="col-3 col-form-label">Preset Range</label>\n                        <div class="col-9">\n                            <div class="input-group">\n                                <select id="time-range" class="form-control" name="time_range">\n                                    <option value="month-current">Current Month</option>\n                                    <option value="month-last">Last Month</option>\n                                    <option value="quarter-current">Current Quarter</option>\n                                    <option value="quarter-last">Last Quarter</option>\n                                    <option value="fy-current">Current Financial Year</option>\n                                    <option value="fy-last">Last Financial Year</option>\n                                    <option value="custom">Custom</option>\n                                </select>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class="form-group row">\n                        <label class="col-3 col-form-label">Date Range</label>\n                        <div class="col-9">\n                            <div id="date-inputs" class="input-group">\n                                <select class="form-control" name="from_year">\n                                    ';
 for (const y of years) { ;
__p += '\n                                    <option value="' +
((__t = ( y )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( y )) == null ? '' : __t) +
'\n                                    </option>\n                                    ';
 } ;
__p += '\n                                </select>\n                                <select class="form-control" name="from_month">\n                                    ';
 months.forEach((month)=> { ;
__p += '\n                                    <option value="' +
((__t = ( month.number )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( month.name )) == null ? '' : __t) +
'\n                                    </option>\n                                    ';
 }); ;
__p += '\n                                </select>\n                                <div class="input-group-append input-group-prepend">\n                                    <span class="input-group-text">~</span>\n                                </div>\n                                <select class="form-control" name="to_year">\n                                    ';
 for (const y of years) { ;
__p += '\n                                    <option value="' +
((__t = ( y )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( y )) == null ? '' : __t) +
'\n                                    </option>\n                                    ';
 } ;
__p += '\n                                </select>\n                                <select class="form-control" name="to_month">\n                                    ';
 months.forEach((month)=> { ;
__p += '\n                                    <option value="' +
((__t = ( month.number )) == null ? '' : __t) +
'">\n                                        ' +
((__t = ( month.name )) == null ? '' : __t) +
'\n                                    </option>\n                                    ';
 }); ;
__p += '\n                                </select>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class="form-group row">\n                        <label class="col-3 col-form-label">Division</label>\n\n                        <div id="cntr-division" class="col-6"></div>\n                    </div>\n                </fieldset>\n            </div>\n\n            <div class="form-group row">\n                <div class="col-3"></div>\n                <div class="col-9">\n                    <button type="submit" class="btn btn-primary px-5">Generate Report</button>\n                    <a href="#" data-action="reset" class="small ml-3">Clear Criteria</a>\n                </div>\n            </div>\n        </form>\n    </div>\n</div>\n\n<div id="results"></div>';

}
return __p
};
