import { Collection } from 'backbone';
import config from '@/js/app/config';
import PropertyListModel from '../models/property-list';

export default Collection.extend({

    url: config.api.url + 'property_lists',

    model: PropertyListModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});