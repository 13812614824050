import Criteria from '@/js/app/criteria-model';

export default Criteria.extend({
    defaults: function() {
        return {
            include: ['assigned_staff'],
            limit: 20,
            offset: 0,
        };
    },
});