module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<canvas class="chart" style="width: 100%; height: 400px"></canvas>\n<table class="table table-sm table-bordered">\n    <caption class="text-right">Revenue in millions of yen</caption>\n    <thead>\n        <tr>\n            <th>&nbsp;</th>\n            <th colspan="3" class="text-center">\n                <a href="#reports/revenue/' +
((__t = ( months[0].format('YYYYMM') )) == null ? '' : __t) +
'/' +
((__t = ( months[2].format('YYYYMM') )) == null ? '' : __t) +
'">Q1</a>\n            </th>\n            <th colspan="3" class="text-center">\n                <a href="#reports/revenue/' +
((__t = ( months[3].format('YYYYMM') )) == null ? '' : __t) +
'/' +
((__t = ( months[5].format('YYYYMM') )) == null ? '' : __t) +
'">Q2</a>\n            </th>\n            <th colspan="3" class="text-center">\n                <a href="#reports/revenue/' +
((__t = ( months[6].format('YYYYMM') )) == null ? '' : __t) +
'/' +
((__t = ( months[8].format('YYYYMM') )) == null ? '' : __t) +
'">Q3</a>\n            </th>\n            <th colspan="3" class="text-center">\n                <a href="#reports/revenue/' +
((__t = ( months[9].format('YYYYMM') )) == null ? '' : __t) +
'/' +
((__t = ( months[11].format('YYYYMM') )) == null ? '' : __t) +
'">Q4</a>\n            </th>\n            <th colspan="4">&nbsp;</th>\n        </tr>\n        <tr>\n            <th>Divisions</th>\n            ';
 months.forEach((month, i) => { ;
__p += '\n            <th class="text-right ' +
((__t = ( currentPeriodIndex === i ? 'highlight' : '' )) == null ? '' : __t) +
'">\n                <a\n                   href="#reports/revenue/' +
((__t = ( month.format('YYYYMM') )) == null ? '' : __t) +
'/' +
((__t = ( month.format('YYYYMM') )) == null ? '' : __t) +
'">' +
((__t = ( month.format('MMM') )) == null ? '' : __t) +
'</a>\n            </th>\n            ';
 }); ;
__p += '\n            <th class="text-right total">Total</th>\n            <th class="text-right">YTD</th>\n            <th colspan="2" class="text-right">Annual Target</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 results.divisions.forEach((d) => { ;
__p += '\n        <tr class="division">\n            <th><a href="#reports/ytd/' +
((__t = ( fy )) == null ? '' : __t) +
'/' +
((__t = ( d.id )) == null ? '' : __t) +
'">' +
((__t = ( d.name )) == null ? '' : __t) +
'</a></th>\n            ';
 d.revenue.forEach((amount, i) => { ;
__p += '\n            <td class="text-right ' +
((__t = ( currentPeriodIndex === i ? 'highlight' : '' )) == null ? '' : __t) +
'">' +
((__t = ( amount )) == null ? '' : __t) +
'</td>\n            ';
 }); ;
__p += '\n            <td class="text-right total">' +
((__t = ( d.revenue_total )) == null ? '' : __t) +
'</td>\n            <td class="text-right color ' +
((__t = ( d.pct_ytd < 100 ? 'bad' : '' )) == null ? '' : __t) +
'">' +
((__t = ( d.pct_ytd )) == null ? '' : __t) +
'%</td>\n            <td class="text-right">' +
((__t = ( d.target_year )) == null ? '' : __t) +
'</td>\n            <td class="text-right color ' +
((__t = ( d.pct_year < 100 ? 'bad' : '' )) == null ? '' : __t) +
'">' +
((__t = ( d.pct_year )) == null ? '' : __t) +
'%</td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n\n    <tfoot>\n        <tr class="total">\n            <th class="total">Total</th>\n            ';
 results.months.revenue.forEach((amount, i) => { ;
__p += '\n            <th class="text-right ' +
((__t = ( currentPeriodIndex === i ? 'highlight' : 'total' )) == null ? '' : __t) +
'">' +
((__t = ( amount )) == null ? '' : __t) +
'</th>\n            ';
 }); ;
__p += '\n            <th class="text-right total">' +
((__t = ( results.months.revenue_total )) == null ? '' : __t) +
'</th>\n            <th class="text-right total color ' +
((__t = ( results.months.pct_ytd < 100 ? 'bad' : '' )) == null ? '' : __t) +
'">\n                ' +
((__t = ( results.months.pct_ytd )) == null ? '' : __t) +
'%</th>\n            <th class="text-right total">' +
((__t = ( results.months.target_total )) == null ? '' : __t) +
'</th>\n            <th class="text-right total color ' +
((__t = ( results.months.pct_year < 100 ? 'bad' : '' )) == null ? '' : __t) +
'">\n                ' +
((__t = ( results.months.pct_year )) == null ? '' : __t) +
'%</th>\n        </tr>\n        <tr>\n            <th>Target</th>\n            ';
 results.months.pct.forEach((pct, i) => { ;
__p += '\n            <th\n                class="text-right ' +
((__t = ( currentPeriodIndex === i ? 'highlight' : '' )) == null ? '' : __t) +
' color ' +
((__t = ( pct < 100 ? 'bad' : '' )) == null ? '' : __t) +
'">\n                ' +
((__t = ( pct )) == null ? '' : __t) +
'%</th>\n            ';
 }); ;
__p += '\n            <th colspan="4">&nbsp;</th>\n        </tr>\n    </tfoot>\n</table>';

}
return __p
};
