import { View } from 'backbone';
import Session from '@/js/app/session';
import template from '../templates/targets.html';

export default class DashboardSelfKpisView extends View {
    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.handleModelChange);
    }

    render(revenue) {
        console.debug('DashboardSelfKpisView#render');

        this.el.innerHTML = template({
            revenue,
            Session,
        });

        return this;
    }

    handleModelChange(model) {
        console.debug('DashboardSelfKpisView#handleModelChange');

        const staffKpi = model.staff.find(
            (kpi) => kpi.id == Session.data.staff_id
        );

        if (staffKpi) {
            // Initialize revenue
            const revenue = {
                thisMonth: {
                    actual: 0,
                    target: 0,
                    percent: 0,
                },
                nextMonth: null,
                thisQtr: {
                    actual: 0,
                    target: 0,
                    percent: 0,
                },
                ytd: {
                    actual: staffKpi.revenue_total,
                    target: staffKpi.target_ytd,
                    percent:
                        (staffKpi.revenue_total / staffKpi.target_ytd) * 100,
                },
                thisYear: {
                    actual: staffKpi.revenue_total,
                    target: staffKpi.target_year,
                    percent:
                        (staffKpi.revenue_total / staffKpi.target_year) * 100,
                },
            };

            const currentMonth = moment().month() + 1;
            const currentMonthIndex =
                currentMonth < 4 ? currentMonth + 8 : currentMonth - 4;

            // Store data for this month
            revenue.thisMonth = {
                actual: staffKpi.revenue[currentMonthIndex],
                target: staffKpi.target[currentMonthIndex],
                percent:
                    (staffKpi.revenue[currentMonthIndex] /
                        staffKpi.target[currentMonthIndex]) *
                    100,
            };

            // If current month index < 11
            if (currentMonthIndex < 11) {
                // Store data for next month
                revenue.nextMonth = {
                    actual: staffKpi.revenue[currentMonthIndex + 1],
                    target: staffKpi.target[currentMonthIndex + 1],
                    percent:
                        (staffKpi.revenue[currentMonthIndex + 1] /
                            staffKpi.target[currentMonthIndex + 1]) *
                        100,
                };
            }

            const qtrStartIndex = Math.floor(currentMonthIndex / 3) * 3;
            const qtrRevenueList = staffKpi.revenue.splice(
                qtrStartIndex,
                qtrStartIndex + 3
            );
            const qtrTargetList = staffKpi.target.splice(
                qtrStartIndex,
                qtrStartIndex + 3
            );

            const reducer = (prevValue, currentValue) =>
                prevValue + currentValue;
            revenue.thisQtr.actual = qtrRevenueList.reduce(reducer);
            revenue.thisQtr.target = qtrTargetList.reduce(reducer);
            revenue.thisQtr.percent =
                (revenue.thisQtr.actual / revenue.thisQtr.target) * 100;

            this.render(revenue);
        }
    }
}
