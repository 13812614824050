module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm table-bordered">\n    <thead>\n        <tr>\n            <th scope="col">ID</th>\n            <th scope="col">Name</th>\n            <th scope="col">Type</th>\n            <th scope="col">Link</th>\n        </tr>\n    </thead>\n\n    ';
 if (properties.length > 0) { ;
__p += '\n    <tbody>\n        ';
 properties.forEach(p => { ;
__p += '\n        <tr>\n            <td>\n                <a href="' +
((__t = ( Formatter.building_edit_url(p) )) == null ? '' : __t) +
'">' +
((__t = ( p.id )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>' +
((__t = ( p.structure_name_en )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( TextHelper._(p.structure_type, 'structure_type') )) == null ? '' : __t) +
'</td>\n            <td>\n                <a href="' +
((__t = ( Formatter.building_edit_url(p) )) == null ? '' : __t) +
'" target="_blank">\n                    <i class="fa fa-lg fa-external-link" aria-hidden="true"></i>\n                </a>\n            </td>\n        </tr>\n        ';
 }) ;
__p += '\n    </tbody>\n    ';
 } else { ;
__p += '\n        <tbody>\n            <tr>\n                <td colspan="4" class="text-center">No Properties Found</td>\n            </tr>\n        </tbody>\n    ';
 } ;
__p += '\n</table>\n\n';
 if (properties.length > 0) { ;
__p += '\n<div class="w-100 d-flex justify-content-center">\n    <button type="button" class="btn btn-light" data-action="forceCreate">\n        新規登録\n    </button>\n</div>\n';
 } else { ;
__p += '\n<div class="w-100 d-flex justify-content-center">\n    <button type="button" class="btn btn-primary" data-action="create">\n        新規登録\n    </button>\n</div>\n';
 } ;


}
return __p
};
