import BloodhoundStaffDataset from '@/js/app/bloodhound/datasets/staff';
import config from '@/js/app/config';
import DivisionsCollection from '@/js/app/division/collections/divisions';
import { getName } from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import { TypeaheadStaffDefaults } from '@/js/app/typeahead/defaults';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import { getSelectInputs, setRangeInputFields } from '../../date-ranges';
import ReportAccountingResultCollection from '../collections/results';
import template from '../templates/generate.html';
import ReportAccountingResultsView from './results';
import ReportAccountingSummaryView from './summary';

export default class ReportAccountingCriteriaView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'reports';

        // Build field defaults
        this.time_range = 'month-current';
        this.date_from = moment().startOf('month').format('YYYY-MM-DD');
        this.date_to = moment().endOf('month').format('YYYY-MM-DD');
        this.division_id = '';
        this.item_paid = '';
        this.staff_id = undefined;
        this.after_deductions = 0;

        this.events = {
            'submit #frm-report-criteria': this.generate,
            'change #time-range': this.handleTimeRangeChange,
            'change #date-inputs>input': this.handleDateChange,
            'click [data-action="reset"]': this.handleClearCriteria
        };

        // Create subviews
        this.subviews = {
            staffTypeahead: new UiTypeaheadView({
                placeholder: 'Agent',
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset),
                ],
            }),
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: new DivisionsCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                prependOptions: [
                    { label: '', value: '' }
                ]
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'generate');

        this.render();

        // Fetch divisions
        this.subviews.divisionSelect.collection.fetch({
            data: {
                company_id: 1,
                active: 1,
                order: 'order',
            },
        });
    }

    render() {
        console.debug('ReportAccountingCriteriaView#render');

        // Render template
        this.el.innerHTML = template();

        this.subviews.staffTypeahead
            .setElement(this.$el.find('#cntr-staff_id'))
            .render();

        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        this.timeRangeSelect = this.el.querySelector('#time-range');
        this.fromSelect = this.el.querySelector('input[name="date_from"]');
        this.toSelect = this.el.querySelector('input[name="date_to"]');

        // Get report criteria from sessionStorage
        this.accountingReportCriteria = JSON.parse(sessionStorage.getItem(
            'accountingReportCriteria'
        ));

        // If search conditions exists
        if (this.accountingReportCriteria) {
            const { time_range, date_from, date_to, division_id, item_paid, staff_id, after_deductions } = this.accountingReportCriteria;

            populateForm(this.el.querySelector('#frm-report-criteria'), {
                time_range: time_range,
                date_from: date_from,
                date_to: date_to,
                item_paid: item_paid || '',
                staff_id: staff_id || undefined,
                after_deductions: after_deductions || 0
            });

            this.subviews.divisionSelect.setSelected(division_id);

            if (staff_id) {
                // Fetch information for given staff ID
                /** @todo Ideally we could set the ID in typeahead, and have it reverse lookup the staff, but Bloodhound isn't built for this */
                OAuth2Client.fetchJSON(config.api.url + 'staff/' + staff_id, {})
                    .then((response) => {
                        // Set datum
                        this.subviews.staffTypeahead.selectDatum(response);

                        /** @todo Ideally we could set datum, and trigger select of that datum programmatically, so UI displays selection as if user typed and clicked */
                        // Set value
                        this.subviews.staffTypeahead.setValue(getName(response));

                        // Trigger click on submit
                        this.el.querySelector('button[type="submit"]').click();
                    });

            }
        } else {
            this.setDefaultFields();
        }

        return this;
    }

    setDefaultFields() {
        populateForm(this.el.querySelector('#frm-report-criteria'), {
            time_range: this.time_range,
            date_from: this.date_from,
            date_to: this.date_to,
            division_id: this.division_id,
            item_paid: this.item_paid,
            staff_id: this.staff_id,
            after_deductions: this.after_deductions
        });
    }

    handleTimeRangeChange($e) {
        const rangeType = $e.target.value;
        const inputs = getSelectInputs(this);

        setRangeInputFields(rangeType, inputs);
    }

    handleDateChange() {
        const dateRangeInput = this.el.querySelector('#time-range');

        dateRangeInput.value = 'custom';
    }

    handleClearCriteria(e) {
        console.debug('ReportAccountingCriteriaView#handleClearCriteria');

        e.preventDefault();

        sessionStorage.removeItem('accountingReportCriteria');

        this.setDefaultFields();
        this.subviews.staffTypeahead.clear();
    }

    generate($e) {
        console.debug('ReportAccountingCriteriaView#generate');

        $e.preventDefault();

        const criteria = {
            staff_id: this.subviews.staffTypeahead.datum
                ? this.subviews.staffTypeahead.datum.id
                : null,
        };

        const form = this.el.querySelector('form');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        // Store search conditions to sessionStorage
        sessionStorage.setItem('accountingReportCriteria', JSON.stringify(criteria));

        // Create new results collection
        const reportResults = new ReportAccountingResultCollection();

        // Create new summary view
        const accountingSummaryView = new ReportAccountingSummaryView({
            collection: reportResults,
        });

        // Attach accounting summary view to DOM
        this.$el.find('#summary').html(accountingSummaryView.el);

        // Create new results view
        const accountingResultsView = new ReportAccountingResultsView({
            collection: reportResults,
        });

        // Attach accounting results view to DOM
        this.$el.find('#results').html(accountingResultsView.el);

        // Fetch results
        reportResults.fetch({
            data: criteria,
            reset: true,
        });
    }
}
