import Logout from '../app/logout';
import Session from '../app/session';

export default class OAuth2Client {
    constructor(options) {
        /** Merge options with defaults */
        this.options = _.extend({
            url: '',
            client_id: null,
            client_secret: null,
            token_name: 'access-token',
            onRefreshFail: null,
        }, options || {});

        // If we have a token on page load, resolve immediately
        const token = JSON.parse(localStorage.getItem(this.options.token_name));
        if (token) {
            this.tokenExpiresAt = token.timestamp + token.expires_in;
            this.token = Promise.resolve(token);
            this.tokenResolved = true;
        }
    }

    /**
     * Authenticate against OAuth2 server
     * @param {string} username - The user's identity
     * @param {string} password - The user's password
     * @returns {object} Promise
     */
    authenticate(username, password) {
        console.debug('OAuth2Client#authenticate');

        // Trigger fetchToken with username and password
        return this.fetchToken({
            grant_type: 'password',
            username: username,
            password: password,
        })
            .then(() => Session.load())
            .then(() => Session.start());
    }

    /**
     * Does the OAuth2 client have a token?
     * @returns {bool}
     */
    hasToken() {
        console.debug('OAuth2Client#hasToken');

        return (typeof this.token === 'object');
    }

    /**
     * Is the OAuth2 client's token expired?
     * @returns {bool}
     */
    isTokenExpired() {
        console.debug('OAuth2Client#isTokenExpired');

        // Return whether token is expired (with 10 second buffer to account for system time drift)
        return (this.tokenExpiresAt - 10 <= Math.floor(Date.now() / 1000));
    }

    /**
     * Is the OAuth2 client's token resolved?
     * @returns {bool}
     */
    isTokenResolved() {
        console.debug('OAuth2Client#isTokenResolved');

        // Return whether token is resolved
        /** @todo Would be great if native Promises let us access it's state... might change to Bluebird? */
        return this.tokenResolved;
    }

    /**
     * Get existing OAuth2 token, or refresh if token expired
     * @returns {object} Promise
     */
    getToken() {
        console.debug('OAuth2Client#getToken');

        // If token exists
        if (this.hasToken()) {
            // If token is expired and resolved; fetch a new token with refresh token
            if (this.isTokenExpired() && this.isTokenResolved()) {
                return this.fetchToken({
                    grant_type: 'refresh_token',
                    refresh_token: JSON.parse(localStorage.getItem(this.options.token_name)).refresh_token,
                })
                    .then((token) => {
                        // Load session info (don't return this promise as nothing else depends on this, so can run in parallel)
                        Session.load();
                        return token;
                    })
                    .catch((err) => {
                        if (this.options.onRefreshFail) {
                            this.options.onRefreshFail();
                        }
                        throw err;
                    });
            }
            // Else; return token
            else {
                return this.token;
            }
        }
        // No token
        else {
            // Logout user
            Logout();

            return Promise.reject(new Error('No token'));
        }
    }

    /**
     * Fetch token from OAuth2 server
     * @param {object} options - Options for request
     * @returns {object} Promise
     */
    fetchToken(options) {
        console.debug('OAuth2Client#fetchToken');

        // Merge options
        options = _.extend({
            client_id: this.options.client_id,
            client_secret: this.options.client_secret,
        }, options || {});

        // Fetch token
        this.tokenResolved = false;
        this.token = fetch(this.options.url + 'access_token', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(options).toString(),
        })
            .catch((err) => {
                // Log error message
                console.error(err.message);

                // Throw new error
                throw new Error('Unable to fetch token');
            })
            .then((response) => {
                // If response is OK; return JSON
                if (response.ok) {
                    return response.json();
                }
                // Else; throw OAuth2Error
                else {
                    return response.json()
                        .then((error) => {
                            throw new OAuth2Error(error.message, error.error);
                        });
                }
            })
            .then((token) => {
                // Set token timestamp
                token.timestamp = Math.floor(Date.now() / 1000);

                // Store access token in localStorage
                localStorage.setItem(this.options.token_name, JSON.stringify(token));

                // Set token expiry timestamp
                this.tokenExpiresAt = token.expires_in + token.timestamp;
                this.tokenResolved = true;

                return token;
            });
        return this.token;
    }

    /**
     * Clear token
     */
    clearToken() {
        console.debug('OAuth2Client#clearToken');

        // Remove access token from self
        this.token = false;

        // Remove access token from localStorage
        localStorage.removeItem(this.options.token_name);
    }

    /**
     * Wrapper for jQuery.ajax
     * @param {object} options - Options for request
     * @returns {object} Promise
     */
    ajax(options) {
        console.debug('OAuth2Client#ajax');

        // Get OAuth2 token
        return this.getToken()
            .then((token) => {
                // Add "Authorization" header
                options.headers = _.extend({}, options.headers, {
                    'Authorization': token.token_type + ' ' + token.access_token,
                });

                // Initiate ajax call
                return jQuery.ajax(options)
                    .fail((jqXHR, textStatus, errorThrown) => {
                        if (jqXHR.readyState === 0) {
                            alert('Connection error.');
                            throw new Error('Connection error');
                        }
                        else if (jqXHR.readyState === 4) {
                            if (jqXHR.status >= 500) {
                                alert('Server error.');
                                throw new Error(errorThrown);
                            }
                        }
                    });
            });
    }

    /**
     * Fetch
     * @param {*} resource
     * @param {*} init
     * @return {promise}
     */
    fetch(resource, init) {
        console.debug('OAuth2Client#fetch');

        return this.getToken()
            .then((token) => {
                // Add "Authorization" header
                init.headers = _.extend({}, init.headers, {
                    'Authorization': token.token_type + ' ' + token.access_token,
                });
                return fetch(resource, init)
                    .catch(() => {
                        alert('Connection error.');
                        throw new Error('Connection error');
                    });
            });
    }

    fetchJSON(resource, init = {}) {
        console.debug('OAuth2Client#fetchJSON');

        init.headers = _.extend({}, init.headers, {
            'Accept': 'application/json',
        });
        return this.fetch(resource, init)
            .then((response) => {
                // If response is OK; return JSON
                if (response.ok) {
                    if (response.status === 204) {
                        return;
                    } else {
                        return response.json();
                    }
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            });
    }

    /**
     * Download file using fetch
     * @param {object} resource
     * @param {object} init
     * @param {object} options
     * @return {promise}
     */
    download(resource, init, options) {
        // Proxy to fetch
        return this.fetch(resource, init)
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.blob();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((blob) => {
                // If IE/Edge
                if (window.navigator && window.navigator.msSaveBlob) {
                    // Save blob (easy!)
                    window.navigator.msSaveBlob(blob, options.filename);
                }
                // Else Chrome/Firefox/Safari
                else {
                    // Create blob URL
                    const url = window.URL.createObjectURL(blob);
                    // Create temporary <a> element
                    const tmpAnchor = document.createElement('a');
                    document.body.appendChild(tmpAnchor);
                    // Set download filename
                    tmpAnchor.download = options.filename;
                    // Set URL
                    tmpAnchor.href = url;
                    // Trigger click
                    tmpAnchor.click();
                    // Remove temporary <a> element
                    document.body.removeChild(tmpAnchor);
                    // Remove blob URL
                    window.URL.revokeObjectURL(url);
                }
            });
    }

    csvDownload(resource, init, options) {
        console.debug('OAuth2Client#csvDownload');

        // Add accept header
        init.headers = _.extend({}, init.headers, {
            'Accept': 'text/csv',
        });

        return this.download(resource, init, options);
    }

    xlsxDownload(resource, init, options) {
        console.debug('OAuth2Client#xlsxDownload');

        // Add accept header
        init.headers = _.extend({}, init.headers, {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        return this.download(resource, init, options);
    }
}

class OAuth2Error extends Error {
    constructor(message, error) {
        // Call Error class contructor
        super(message);
        this.name = 'OAuth2Error';

        // Assign code property
        this.error = error;
    }
}
