import { history, View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import CitiesView from '@/js/app/address/views/cities';
import PrefecturesView from '@/js/app/address/views/prefectures';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import * as TextHelper from '@/js/app/text-helper';
import template from '../templates/modal_new.html';
import resultsTemplate from '../templates/modal_search_results.html';

export default class PropertyNewView extends View {
    preinitialize() {
        this.id = 'modal-new_property';

        this.className = 'modal fade';

        this.events = {
            'shown.bs.modal': this.buildup,
            'hidden.bs.modal': this.teardown,
            'click [data-action="lookup"]': this.handleClickLookupAddress,
            'change [data-group="address"]': this.handleChangeAddressField,
            'change [name="prefecture"]': this.handleChangePrefecture,
            'set [name="prefecture"]': this.handleSetPrefecture,
            'submit #addressSearchForm': this.searchProperties,
            'click #propertyResults a': this.handleClickPropertyLink,
            'click [data-action="forceCreate"]': this.handleClickForceCreate,
            'click [data-action="create"]': this.handleClickCreate,
        };
    }

    initialize() {
        this.subviews = {
            prefecture: new PrefecturesView({
                attributes: {
                    name: 'prefecture',
                    'data-group': 'address',
                    required: true,
                },
            }),
            city: new CitiesView({
                attributes: {
                    name: 'city',
                    'data-group': 'address',
                    required: true,
                },
            }),
        };

        // Update outlets
        this.listenTo(this.model, 'change', this.updateOutlets);
    }

    render() {
        console.debug('PropertyNewView#render');

        // Render template
        this.el.innerHTML = template();

        this.subviews.prefecture
            .render(this.el.querySelector('[data-slot="prefecture"]'))
            .collection.fetch();

        this.subviews.city.render(this.el.querySelector('[data-slot="city"]'));

        // Trigger modal display
        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });
    }

    async searchProperties(e) {
        console.debug('PropertyNewView#searchProperties');

        e.preventDefault();

        const { prefecture, city, neighborhood, address } = this.model.attributes;

        const data = await OAuth2Client.fetchJSON(
            `${config.api.url}buildings?prefecture=${prefecture}&city=${city}&neighborhood=${neighborhood}&address=${address}&language=en&limit=50`
        );
        this.el.querySelector('#propertyResults').innerHTML =
            resultsTemplate({
                properties: data.results,
                Formatter,
                TextHelper,
            });

        return data;
    }

    handleClickLookupAddress() {
        console.debug('PropertyNewView#handleClickLookupAddress');

        // If postcode blank, return
        const fieldPostcode = this.el.querySelector('[name="postcode"]');
        if (fieldPostcode.value === '') {
            return;
        }

        this.lookupAddress(fieldPostcode.value);
    }

    async lookupAddress(postcode) {
        const address = await OAuth2Client.fetchJSON(config.api.url + 'postcodes/' + postcode);

        // Set address components in model
        this.changeAddress({
            postcode: address.postcode,
            prefecture: address.prefecture.name,
            city: address.city.name,
            neighborhood: address.neighborhood,
        });
    }

    handleChangePrefecture(e) {
        console.debug('PropertyNewView#handleChangePrefecture');

        this.fetchCities(e.currentTarget.value);
    }

    handleSetPrefecture(e, params) {
        console.debug('PropertyNewView#handleSetPrefecture');

        this.fetchCities(params.value);
    }

    handleChangeAddressField(e) {
        console.debug('PropertyNewView#handleChangeAddressField');

        const t = this.$el.find(e.currentTarget);

        this.changeAddress({
            [t.prop('name')]: t.val()
        });
    }

    changeAddress(changes) {
        console.debug('PropertyNewView#changeAddress');

        // Set model attribute to value of updated field
        this.model.set(changes);
    }

    updateOutlets(model) {
        console.debug('PropertyNewView#updateOutlets');

        const changes = model.changedAttributes();

        populateForm(this.el, _.omit(changes, ['prefecture', 'city']));

        if (_.has(changes, 'prefecture')) {
            this.subviews.prefecture.setValue(model.get('prefecture'));
        }
        if (_.has(changes, 'city')) {
            this.subviews.city.setValue(model.get('city'));
        }
    }

    fetchCities(prefecture) {
        console.debug('PropertyNewView#fetchCities');

        // Initiate fetch with form data
        this.subviews.city.collection.fetch({
            data: { prefecture },
        });
    }

    buildup() {
        console.debug('PropertyNewView#buildup');

        // Set focus
        this.el.querySelector('[name="postcode"]').focus();
    }

    handleClickForceCreate() {
        console.debug('PropertyNewView#handleClickForceCreate');

        if (confirm('A property already exists at this address. Are you sure?')) {
            this.create();
        }
    }

    handleClickCreate() {
        console.debug('PropertyNewView#handleClickCreate');

        this.create();
    }

    create() {
        const data = this.model.pick(['postcode', 'prefecture', 'city', 'neighborhood', 'address']);

        sessionStorage.setItem('new-property-data', JSON.stringify(data));

        // Hide modal
        this.$el.modal('hide');

        // Redirect to edit property (loadUrl must be called separately, so the page will reload even if we are already at this route)
        history.navigate('property/edit');
        history.loadUrl('property/edit');
    }

    teardown() {
        console.debug('PropertyNewView#teardown');

        this.$el.data('modal', null);

        // Remove view
        this.remove();
    }

    handleClickPropertyLink(e) {
        console.debug('PropertyNewView#handleClickPropertyLink');

        // If neither [meta] key nor [ctrl] key being pressed, hide modal
        if (!e.metaKey && !e.ctrlKey) {
            this.$el.modal('hide');
        }
    }
}
