import Criteria from '@/js/app/criteria-model';

const DealCriteria = Criteria.extend({
    defaults: function () {
        return {
            include: ['assigned_to'],
            limit: 20,
            offset: 0,
        };
    },
});

export default DealCriteria;
