import { View } from 'backbone';
import template from '../templates/kamisama.html';
import * as Formatter from '@/js/app/formatter';
import { getFinancialYear } from '@/js/app/formatter';
import ReportYtdGenerateView from '@/js/app/report/ytd/views/generate';
import Session from '@/js/app/session';

export default class DashboardKamisamaView extends View {

    preinitialize() {
        // Create subviews
        this.subviews = {
            ytd: new ReportYtdGenerateView({
                financialYear: getFinancialYear()
            }),
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('DashboardKamisamaView#render');

        this.el.innerHTML = template({
            Formatter,
            Session
        });

        this.subviews.ytd.setElement(this.el.querySelector('#ytdReport')).render();

        return this;
    }

}