module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal-dialog ' +
((__t = ( size ? `modal-${size}` : '' )) == null ? '' : __t) +
' ' +
((__t = ( centered ? 'modal-dialog-centered' : '' )) == null ? '' : __t) +
' ' +
((__t = ( scrollable ? 'modal-dialog-scrollable' : '' )) == null ? '' : __t) +
'" role="document">\n    <div class="modal-content"></div>\n</div>';

}
return __p
};
