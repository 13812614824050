import { View } from 'backbone';
import PeopleCollection from '@/js/app/people/collections/people';
import ClientCriteria from '../criterias/client';
import template from '../templates/latest.html';
import ClientResultsView from './results';

export default class ClientLatestView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'clients';

        // Create criteria model
        this.criteria = new ClientCriteria();

        // Create people collection
        this.peopleCollection = new PeopleCollection;

        // Create subviews
        this.subviews = {
            clientsResults: new ClientResultsView({
                collection: this.peopleCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        this.render();

        // Set criteria
        this.criteria.set({
            created_from: moment.utc().subtract(2, 'weeks').format('YYYY-MM-DD'),
        });
    }

    render() {
        console.debug('ClientLatestView#render');

        // Render template
        this.el.innerHTML = template();

        // Attach clients results view to DOM
        this.subviews.clientsResults.setElement(this.$el.find('#results')).render();

        return this;
    }
}