module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">People</h2>\n\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Type</th>\n            <th>Name</th>\n            <th>Phone</th>\n            <th>Assigned to</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 people.forEach((p) => { ;
__p += '\n        <tr data-id="' +
((__t = ( p.id )) == null ? '' : __t) +
'">\n            <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( p.id )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( Formatter.determinePersonType(p) )) == null ? '' : __t) +
'</td>\n            <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(p) )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( p.telephone )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (p.assigned_staff) { ;
__p += '\n                    ' +
((__t = ( p.assigned_staff_id == Session.data.staff_id ? 'Me' : Formatter.getName(p.assigned_staff) )) == null ? '' : __t) +
'\n                ';
 } else { ;
__p += '\n                    <span class="badge badge-secondary">Unassigned</span>\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
