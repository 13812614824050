import BloodhoundBmCompanyDataset from '@/js/app/bloodhound/datasets/bm_company';
import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import BloodhoundStaffDataset from '@/js/app/bloodhound/datasets/staff';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import PeopleCollection from '@/js/app/people/collections/people';
import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import * as TextHelper from '@/js/app/text-helper';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import {
    TypeaheadCompanyDefaults,
    TypeaheadStaffDefaults,
} from '@/js/app/typeahead/defaults';
import UiAgentPMNameSelectorView from '@/js/app/ui/views/agent-pm-name-selector';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import populateForm from '@/js/libs/populate-form';
import { getFieldValue } from '@/js/libs/view-utils';
import { history, View } from 'backbone';
import BuildingAutomatchCollection from '../collections/building-automatch';
import BuildingFileCollection from '../collections/building-files';
import BuildingImageCollection from '../collections/building-images';
import template from '../templates/edit_structure.html';
import templateOverviewImage from '../templates/overview_image.html';
import PropertyEditMediaView from './edit-media';
import ModalPropertyAutomatchClientsView from './modal-automatch-clients';
import ModalFilesView from './modal-files';

export default class PropertyEditStructureView extends View {
    #mode = 'read';
    #tabs = {};

    preinitialize(options) {
        this.events = {
            'typeahead:select #cntr-rec_by input[type="text"]': this.typeaheadRecBySelected,
            'click #cntr-rec_by [data-action="delete"]': this.handleRecByDeleteClick,
            'typeahead:select #cntr-agent_company_1 input[type="text"]': this.typeaheadAgentCompanySelected,
            'click #cntr-agent_company_1 [data-action="delete"]': this.handleAgentCompanyDeleteClick,
            'typeahead:select #cntr-agent_company_2 input[type="text"]': this.typeaheadAgentCompanySelected,
            'click #cntr-agent_company_2 [data-action="delete"]': this.handleAgentCompanyDeleteClick,
            'typeahead:select #cntr-agent_company_3 input[type="text"]': this.typeaheadAgentCompanySelected,
            'click #cntr-agent_company_3 [data-action="delete"]': this.handleAgentCompanyDeleteClick,
            'typeahead:select #cntr-bm_company input[type="text"]': this.typeaheadBMCompanySelected,
            'click #cntr-bm_company [data-action="delete"]': this.handleBMCompanyDeleteClick,
            'change form': this.handleChangeForm,
            'click button[data-action="delete"]': this.handleStructureDeleteClick,
            'click button[data-action="force-update"]': this.handleStructureForceUpdateClick,
            'click ul.nav-tabs li a': this.handleTabClick,
            'click button[data-action="files"]': this.handleFilesClick,
            'click button[data-action="automatch"]': this.handleAutomatchClick,
            'click button[data-action="changelog"]': this.handleChangelogClick,
            'change textarea[name],select[name],input[type!="file"][name]': this.updateModel,
            'change [data-name="road_type"],[data-name="road_direction"],[data-name="road_width"]': this.handleRoadChange,
            'click button[data-action="edit"]': this.handleEditClick,
            'click button[data-action="cancel"]': this.handleCancelClick,
            'click button[data-action="save"]': this.handleSaveClick,
        };

        this.query = options.query;
        this.location = options.location;

        this.subviews = {
            recBy: new UiTypeaheadView({
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset)
                ],
            }),
            agentCompany: [],
            agentName: [],
            bmCompany: new UiTypeaheadView({
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundBmCompanyDataset)
                ],
            }),
            mediaTab: new PropertyEditMediaView({
                collection: new BuildingImageCollection(
                    options.model.get('images'),
                    {
                        building_id: options.model.get('id'),
                    }
                ),
                model: options.model,
                structure_type: options.model.get('structure_type'),
            }),
            filesModal: new ModalFilesView({
                title: 'Building Files',
                collection: new BuildingFileCollection(),
            }),
            automatchModal: new ModalPropertyAutomatchClientsView({
                collection: new BuildingAutomatchCollection(null, {
                    building_id: options.model.get('id'),
                }),
            }),
        };

        this.subviews.agentCompany[1] = new UiTypeaheadView({
            options: TypeaheadCompanyDefaults,
            datasets: [
                typeaheadCompanyDatasource(BloodhoundCompanyDataset)
            ],
        });
        this.subviews.agentCompany[2] = new UiTypeaheadView({
            options: TypeaheadCompanyDefaults,
            datasets: [
                typeaheadCompanyDatasource(BloodhoundCompanyDataset)
            ],
        });
        this.subviews.agentCompany[3] = new UiTypeaheadView({
            options: TypeaheadCompanyDefaults,
            datasets: [
                typeaheadCompanyDatasource(BloodhoundCompanyDataset)
            ],
        });
        this.subviews.agentName[1] = new UiAgentPMNameSelectorView({
            collection: new PeopleCollection(),
            name: 'agent_name_1_id',
        });
        this.subviews.agentName[2] = new UiAgentPMNameSelectorView({
            collection: new PeopleCollection(),
            name: 'agent_name_2_id',
        });
        this.subviews.agentName[3] = new UiAgentPMNameSelectorView({
            collection: new PeopleCollection(),
            name: 'agent_name_3_id',
        });
    }

    initialize() {
        _.bindAll(this, 'render', 'setFieldTabInvalid', 'resetCustomValidation');

        // When model changes, update fields
        this.listenTo(this.model, 'change:structure_type', this.handleStructureTypeChange);
        this.listenTo(this.model, 'change:published change:published_ja', this.handlePublishedChange);
        this.listenTo(this.model, 'change:bm_room_telephone', this.handleBMRoomTelephoneChange);
        this.listenTo(this.model, 'change:price', this.handlePriceChange);
        this.listenTo(this.model, 'change:updated_at', this.updateLastUpdated);

        // When model changes, update outlets
        this.listenTo(this.model, 'change', this.updateOutlets);
    }

    render() {
        console.debug('PropertyEditStructureView#render');

        const structure = this.model.toJSON();
        const location = this.location.toJSON();

        const urls = {
            seo: {
                en: Formatter.building_seo_url(
                    location,
                    structure,
                    'sales',
                    'en'
                ),
                ja: Formatter.building_seo_url(
                    location,
                    structure,
                    'sales',
                    'ja'
                ),
            },
            building: {
                // Building page URLs
                en: {
                    rent: Formatter.building_page_url(location, structure, 'rent', 'en'),
                    sales: Formatter.building_page_url(location, structure, 'sales', 'en'),
                    'short-term': Formatter.building_page_url(location, structure, 'monthly', 'en'),
                },
                ja: {
                    rent: Formatter.building_page_url(location, structure, 'rent', 'ja'),
                    sales: Formatter.building_page_url(location, structure, 'sales', 'ja'),
                    'short-term': Formatter.building_page_url(location, structure, 'monthly', 'ja'),
                },
            },
            print: {
                en: Formatter.building_print_url(
                    location,
                    structure,
                    Formatter.determineType(structure),
                    'en'
                ),
                ja: Formatter.building_print_url(
                    location,
                    structure,
                    Formatter.determineType(structure),
                    'ja'
                ),
            },
        };

        // Set images in collection
        this.subviews.mediaTab.collection.reset(this.model.get('images'));

        this.el.innerHTML = template({
            propertyId: Formatter.propertyId(structure, this.query),
            s: structure,
            last_updated_at: Formatter.timestamp(this.model.get('updated_at')),
            urls,
            isPeopleReadAllowed: Session.isAllowed('phnx:people:r'),
            isPropertyBlockAllowed: Session.isAllowed('phnx:properties:blk'),
            isPropertyDeleteAllowed: Session.isAllowed('phnx:properties:d'),
            TextHelper,
        });

        // Define tabs
        this.#tabs.details = this.el.querySelector('#tab-building-basics');
        this.#tabs.whole_building = this.el.querySelector('#tab-building-whole-building-analysis');
        this.#tabs.fees = this.el.querySelector('#tab-building-fees');
        this.#tabs.features = this.el.querySelector('#tab-building-features');
        this.#tabs.campaigns = this.el.querySelector('#tab-building-campaigns');
        this.#tabs.admin = this.el.querySelector('#tab-building-admin');
        this.#tabs.pm = this.el.querySelector('#tab-building-pm');

        this.hideElementsForDivision();
        this.toggleBuildingLinks();
        this.toggleSiteLinks();
        this.togglePrintLinks();

        // Render typeahead subviews
        this.$el.find('#cntr-rec_by').html(this.subviews.recBy.render().el);
        this.$el.find('#cntr-bm_company').html(this.subviews.bmCompany.render().el);
        this.$el.find('#cntr-agent_company_1').html(this.subviews.agentCompany[1].render().el);
        this.$el.find('#cntr-agent_company_2').html(this.subviews.agentCompany[2].render().el);
        this.$el.find('#cntr-agent_company_3').html(this.subviews.agentCompany[3].render().el);

        // Render agents name selectors at specific points in DOM
        this.subviews.agentName[1].setValue(this.model.get('agent_name_1_id'));
        this.subviews.agentName[1]
            .setElement(this.el.querySelector('#field-agent_name_1'))
            .render();
        this.subviews.agentName[2].setValue(this.model.get('agent_name_2_id'));
        this.subviews.agentName[2]
            .setElement(this.el.querySelector('#field-agent_name_2'))
            .render();
        this.subviews.agentName[3].setValue(this.model.get('agent_name_3_id'));
        this.subviews.agentName[3]
            .setElement(this.el.querySelector('#field-agent_name_3'))
            .render();

        // Render overview images
        this.el.querySelector('[data-outlet="cover-photo-image"]').innerHTML =
            templateOverviewImage({
                baseUrl: config.image.url + 'b/' + this.model.get('id'),
                imageUrl: `${Formatter.getCoverPhotoFilename(
                    this.model.get('images')
                )}`,
            });
        this.el.querySelector('[data-outlet="floorplan-image"]').innerHTML =
            templateOverviewImage({
                baseUrl: config.image.url + 'b/' + this.model.get('id'),
                imageUrl: `${Formatter.getFloorplanFilename(
                    this.model.get('images')
                )}`,
            });

        // Setup lightbox
        this.$el.find('a.lightbox').fancybox({
            margin: 30,
        });

        // Fetch agent staff names for each agent company
        this.subviews.agentName[1].fetch(this.model.get('agent_company_1_id'));
        this.subviews.agentName[2].fetch(this.model.get('agent_company_2_id'));
        this.subviews.agentName[3].fetch(this.model.get('agent_company_3_id'));

        // Assigned staff selectors
        const staffCollection1 = new StaffCollection();
        if (this.model.get('assigned_staff_1')) {
            staffCollection1.add(this.model.get('assigned_staff_1'));
        }
        const assignedStaff1 = new UiIndividualSelectorView({
            id: 'field-assigned_staff_1',
            collection: staffCollection1,
            attributes: {
                name: 'assigned_staff_1_id',
            },
            selected: this.model.get('assigned_staff_1_id'),
        });
        this.$el.find('#cntr-assigned_staff_1').html(assignedStaff1.el);

        const staffCollection2 = new StaffCollection();
        if (this.model.get('assigned_staff_2')) {
            staffCollection2.add(this.model.get('assigned_staff_2'));
        }
        const assignedStaff2 = new UiIndividualSelectorView({
            id: 'field-assigned_staff_2',
            collection: staffCollection2,
            attributes: {
                name: 'assigned_staff_2_id',
            },
            selected: this.model.get('assigned_staff_2_id'),
        });
        this.$el.find('#cntr-assigned_staff_2').html(assignedStaff2.el);

        // Fetch staff
        staffCollection1.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });
        staffCollection2.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });

        // Render tabs
        this.subviews.mediaTab
            .setElement(this.el.querySelector('#tab-building-media'))
            .render();

        this.#initializeReadEditMode();

        this.updateOutlets();

        return this;
    }

    handleRoadChange() {
        const roads = [];

        this.$el.find('#roads div.col-8').each((i, roadControls) => {
            const road = {
                road_type: roadControls.querySelector('[data-name="road_type"]').value || null,
                road_direction: roadControls.querySelector('[data-name="road_direction"]').value || null,
                road_width: roadControls.querySelector('[data-name="road_width"]').value || null,
            };

            roads.push(road);
        });

        this.model.set('road', roads);
    }

    updateModel($e) {
        console.debug('PropertyEditUnitView#updateModel');

        const field = $e.currentTarget;

        // If no name, return
        if (field.name === '') {
            return;
        }

        // If the field is a partial data field or is NOT an update field; return
        if (
            field.dataset.partial === 'true' ||
            field.dataset.update === 'false'
        ) {
            return;
        }

        const value = getFieldValue(this.el, field);

        this.model.set(field.name, value);
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();

        // Remove was-validated class from form
        const form = this.el.querySelector('form');
        form.classList.remove('was-validated');

        this.#disablePromptBeforeUnload();
    }

    switchEditMode() {
        console.debug('PropertyEditStructureView#switchEditMode');

        // Snapshot model
        this.model.snapshot();

        this.#mode = 'edit';

        this.#initializeReadEditMode();

        this.#enablePromptBeforeUnload();
    }

    promptBeforeUnload(e) {
        // Prevent default (modern browsers)
        e.preventDefault();

        // Set return value (legacy browsers)
        e.returnValue = true;
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            // Loop over each tab
            for (const [, tab] of Object.entries(this.#tabs)) {
                // Disable fields in tab
                tab.querySelectorAll('input,select,textarea').forEach(el => el.disabled = true);
            }

            // Show edit button
            this.el.querySelector('[data-action="edit"]').classList.remove('d-none');

            // Hide cancel and save buttons
            this.el.querySelectorAll('[data-action="cancel"],[data-action="save"]').forEach(el => el.classList.add('d-none'));

            // Switch subviews into read mode
            this.subviews.recBy.switchReadMode();
            this.subviews.bmCompany.switchReadMode();
            this.subviews.agentCompany[1].switchReadMode();
            this.subviews.agentCompany[2].switchReadMode();
            this.subviews.agentCompany[3].switchReadMode();
            this.subviews.mediaTab.switchReadMode();
        } else if (this.#mode === 'edit') {
            // Loop over each tab
            for (const [, tab] of Object.entries(this.#tabs)) {
                // Enable fields in tab
                tab.querySelectorAll('input,select,textarea').forEach(el => el.disabled = false);
            }

            // Hide edit button
            this.el.querySelector('[data-action="edit"]').classList.add('d-none');

            // Hide cancel and save buttons
            this.el.querySelectorAll('[data-action="cancel"],[data-action="save"]').forEach(el => el.classList.remove('d-none'));

            // Switch subviews into edit mode
            this.subviews.recBy.switchEditMode();
            this.subviews.bmCompany.switchEditMode();
            this.subviews.agentCompany[1].switchEditMode();
            this.subviews.agentCompany[2].switchEditMode();
            this.subviews.agentCompany[3].switchEditMode();
            this.subviews.mediaTab.switchEditMode();
        }
    }

    #disablePromptBeforeUnload() {
        // Remove prompt before unload
        window.removeEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload--;
    }

    #enablePromptBeforeUnload() {
        // Add prompt before unload
        window.addEventListener('beforeunload', this.promptBeforeUnload);

        window.warnOnUnload++;
    }

    updateOutlets() {
        console.debug('PropertyEditStructureView#updateOutlets');

        const changes = this.model.changedAttributes();

        console.debug('PropertyEditStructureView#updateOutlets: Changes', changes);

        // Populate form fields
        populateForm(this.el, changes);

        if (_.has(changes, 'bm_room_telephone')) {
            this.updateBMRoomTelephoneLink(this.model.get('bm_room_telephone'));
        }

        // Populate rec by field
        if (_.has(changes, 'rec_by')) {
            this.subviews.recBy.setValue(Formatter.getName(this.model.get('rec_by')));
        }

        // Populate agent and BM company fields
        if (_.has(changes, 'agent_company_1')) {
            this.populateAgentBMCompanyField(this.subviews.agentCompany[1], 'agent_company_1');
        }
        if (_.has(changes, 'agent_company_2')) {
            this.populateAgentBMCompanyField(this.subviews.agentCompany[2], 'agent_company_2');
        }
        if (_.has(changes, 'agent_company_3')) {
            this.populateAgentBMCompanyField(this.subviews.agentCompany[3], 'agent_company_3');
        }
        if (_.has(changes, 'bm_company')) {
            this.populateAgentBMCompanyField(this.subviews.bmCompany, 'bm_company');
        }

        // Fill up road inputs
        if (_.has(changes, 'road')) {
            this.model.get('road').forEach((road, i) => {
                this.el.querySelector(`[data-road="${i}"] [data-name="road_type"]`).value = road.road_type;
                this.el.querySelector(`[data-road="${i}"] [data-name="road_direction"]`).value = road.road_direction;
                this.el.querySelector(`[data-road="${i}"] [data-name="road_width"]`).value = road.road_width;
            });
        }
    }

    handleChangeForm($e) {
        console.debug('PropertyEditStructureView#handleChangeForm');

        if ($e instanceof Object) {
            this.resetCustomValidation($e.target);
        }
    }

    resetCustomValidation(fieldEl) {
        console.debug('PropertyEditStructureView#resetCustomValidation');

        // Reset invalid feedback slot
        const errorFeedback = fieldEl.parentElement.querySelector('.invalid-feedback');
        if (errorFeedback) {
            errorFeedback.innerText = '';
        }

        // Reset custom validity
        fieldEl.setCustomValidity('');

        // If field's tab-pane has no more invalid valids inside
        const invalidFields = this.el.querySelectorAll('.tab-pane :invalid');
        if (invalidFields.length === 0) {
            this.setFieldTabValid(fieldEl);
        }
    }

    save() {
        const form = this.el.querySelector('form');

        // Add was-validated class to form
        form.classList.add('was-validated');

        // Get invalid fields
        const invalidFields = this.el.querySelectorAll('form :invalid');

        // Reset custom validation on all invalid fields (otherwise won't attempt to perform server side validation)
        invalidFields.forEach(this.resetCustomValidation);

        this.hideFormValidationMessage();

        if (form.checkValidity() === false) {
            // Set field's tab to invalid
            invalidFields.forEach(this.setFieldTabInvalid);

            return;
        }

        // Set images on model
        this.model.set('images', this.subviews.mediaTab.collection.toJSON());

        let payload;
        if (this.model.isNew()) {
            // Use entire model contents
            payload = this.model.toJSON();
        } else {
            // Get model changes since last snapshot
            payload = this.model.changedAttributesSinceSnapshot();
        }

        // If no changes; immediately switch to read mode
        if (_.isEmpty(payload)) {
            this.switchReadMode();
        }
        else {
            // Save new value
            this.model.save(payload, { patch: true, wait: true })
                .then(() => {
                    this.switchReadMode();
                })
                .catch(({ responseJSON }) => {
                    // If type of error is App\Exceptions\Validation
                    if (responseJSON.type === 'App\\Exceptions\\Validation') {
                        for (const field of responseJSON.fields) {
                            const fieldEl = this.el.querySelector(`[name="${field.name}"]`);

                            // Set validation error in invalid feedback slot
                            const errorFeedback = fieldEl.parentElement.querySelector('.invalid-feedback');
                            if (errorFeedback) {
                                errorFeedback.innerText = field.message;
                            }

                            // Set validation error on fields
                            fieldEl.setCustomValidity(field.message);

                            this.setFieldTabInvalid(fieldEl);
                        }

                        this.showValidationMessage();
                    }
                });
        }
    }

    setFieldTabValid(fieldEl) {
        // Get tab pane
        const tabPaneEl = fieldEl.closest('.tab-pane');

        // Get tab
        const tabEl = this.el.querySelector(`[href="#${tabPaneEl.id}"]`);

        // Remove class to represent invalid
        tabEl.classList.remove('text-danger');
    }

    setFieldTabInvalid(fieldEl) {
        // Get tab pane
        const tabPaneEl = fieldEl.closest('.tab-pane');

        // Get tab
        const tabEl = this.el.querySelector(`[href="#${tabPaneEl.id}"]`);

        // Add class to represent invalid
        tabEl.classList.add('text-danger');
    }

    showValidationMessage() {
        this.el.querySelector('[data-outlet="form-invalid"]').classList.remove('d-none');
    }

    hideFormValidationMessage() {
        this.el.querySelector('[data-outlet="form-invalid"]').classList.add('d-none');
    }

    hideElementsForDivision() {
        // Get form-group fields with data-division
        const fields = this.el.querySelectorAll('[data-hide-division]');
        const currentDivision = Session.data.division_id?.toString();
        fields.forEach((field) => {
            // Get list of divisions to hide field
            const divisions = field.dataset.hideDivision.split(',');

            // If current division in list; hide field
            if (divisions.includes(currentDivision)) {
                field.classList.add('d-none');
            }
        });
    }

    handleStructureTypeChange(model, value) {
        // Toggle tabs for chosen structure type
        this.toggleTabsForStructureType(value);

        // Hide elements for division again, incase anything reappeared
        this.hideElementsForDivision();

        // Toggle links for building
        this.toggleBuildingLinks(value);
    }

    toggleTabsForStructureType(structure_type) {
        const tabs = this.el.querySelectorAll(
            '[data-tab="whole-building"],[data-tab="fees"],[data-tab="campaigns"],[data-tab="automatch"]'
        );
        const outletPropertyId = this.el.querySelector(
            '[data-outlet="propertyId"]'
        );

        if (structure_type === 'house') {
            // Hide tabs that are aren't needed for houses
            tabs.forEach((el) => el.classList.add('d-none'));

            // Hide property ID
            outletPropertyId.classList.add('d-none');
        } else {
            // Show all tabs
            tabs.forEach((el) => el.classList.remove('d-none'));

            // Show property ID
            outletPropertyId.classList.remove('d-none');
        }
    }

    toggleBuildingLinks() {
        const buildingLinks = this.el.querySelectorAll('[data-building-links]');

        if (this.model.get('structure_type') === 'house') {
            // Hide building links
            buildingLinks.forEach(el => el.classList.add('d-none'));
        } else {
            // Show building links
            buildingLinks.forEach(el => el.classList.remove('d-none'));
        }
    }

    roadDataToObject() {
        const roads = [];

        this.el.querySelectorAll('#roads div.col-8').forEach(roadControls => {
            const road = {};

            roadControls.querySelectorAll('select,input').forEach(field => {
                road[field.name] = field.value;
            });

            // If all values in road object are NOT empty; push road to roads array
            if (Object.values(road).every(val => val === '') === false) {
                roads.push(road);
            }
        });

        return roads;
    }

    updateLastUpdated(model) {
        // Update timestamp
        this.el.querySelector(
            '#update-info strong[data-outlet="updated_at"]'
        ).innerText = Formatter.timestamp(model.get('updated_at'));
    }

    handleBMRoomTelephoneChange(model, value) {
        this.updateBMRoomTelephoneLink(value);
    }

    handlePublishedChange() {
        this.toggleSiteLinks();
    }

    handlePriceChange() {
        this.toggleSiteLinks();
        this.togglePrintLinks();
    }

    toggleSiteLinks() {
        // If structure has a price
        if (Number(this.model.get('price')) > 0) {
            const siteLinksEn = this.el.querySelectorAll('[data-site-links="en"]');
            const siteLinksJa = this.el.querySelectorAll('[data-site-links="ja"]');

            // Toggle English site link
            if (Number(this.model.get('published')) === 1) {
                siteLinksEn.forEach(el => el.classList.remove('d-none'));
            } else {
                siteLinksEn.forEach(el => el.classList.add('d-none'));
            }

            // Toggle Japanese site link
            if (Number(this.model.get('published_ja')) === 1) {
                siteLinksJa.forEach(el => el.classList.remove('d-none'));
            } else {
                siteLinksJa.forEach(el => el.classList.add('d-none'));
            }
        } else {
            // Hide all site links
            this.el.querySelectorAll('[data-site-links]').forEach(el => el.classList.add('d-none'));
        }
    }

    togglePrintLinks() {
        // If structure has a price
        if (Number(this.model.get('price')) > 0) {
            // Show all print links
            this.el.querySelectorAll('[data-print-links]').forEach(el => el.classList.remove('d-none'));
        } else {
            // Hide all print links
            this.el.querySelectorAll('[data-print-links]').forEach(el => el.classList.add('d-none'));
        }
    }

    updateBMRoomTelephoneLink(value) {
        const el = this.$el.find('#link-bm_room_tel');
        el.prop('href', 'tel:' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }

    populateAgentBMCompanyField(typeaheadSubview, name) {
        const company = this.model.get(name);

        // If model contains object for given field name
        if (company) {
            // Set value of field to formatted company name
            typeaheadSubview.setValue(
                Formatter.getCompanyName(company)
            );

            // Show company phone number
            this.el.querySelector(`#outlet-${name}_telephone`).innerText = Formatter.getCompanyTel(company);
        } else {
            // Set field value to empty string (different from "clear", which will also enable)
            typeaheadSubview.setValue('');

            // Clear company phone number
            this.el.querySelector(`#outlet-${name}_telephone`).innerText = '';
        }
    }

    typeaheadRecBySelected($e, datum) {
        // Set staff ID in model
        this.model.set('rec_by_id', datum.id);
    }

    handleRecByDeleteClick($e) {
        $e.preventDefault();

        // Remove staff ID in model
        this.model.set('rec_by_id', null);
    }

    typeaheadAgentCompanySelected($e, datum) {
        // Get container index
        const index = Number(
            $($e.currentTarget).closest('[data-cntr-index]').data('cntrIndex')
        );

        // Set company ID in model
        this.model.set(`agent_company_${index}_id`, datum.id);

        // Display company phone number
        this.el.querySelector(`#outlet-agent_company_${index}_telephone`).innerText = Formatter.getCompanyTel(datum);

        // Fetch agent names
        this.subviews.agentName[index].fetch(datum.id);
    }

    handleAgentCompanyDeleteClick($e) {
        $e.preventDefault();

        // Get container index
        const index = Number(
            $($e.currentTarget).closest('[data-cntr-index]').data('cntrIndex')
        );

        // Remove agent company and name IDs in model
        this.model.set({
            [`agent_company_${index}_id`]: null,
            [`agent_name_${index}_id`]: null,
        });

        // Hide company phone number
        this.el.querySelector(`#outlet-agent_company_${index}_telephone`).innerText = '';

        // Reset agent names
        this.subviews.agentName[index].clear();
    }

    typeaheadBMCompanySelected($e, datum) {
        // Set company ID in model
        this.model.set('bm_company_id', datum.id);

        // Display company phone number
        this.el.querySelector('#outlet-bm_company_telephone').innerText = Formatter.getCompanyTel(datum);
    }

    handleBMCompanyDeleteClick($e) {
        $e.preventDefault();

        // Remove company ID in model
        this.model.set('bm_company_id', null);

        // Hide company phone number
        this.el.querySelector('#outlet-bm_company_telephone').innerText = '';
    }

    handleStructureDeleteClick() {
        if (
            confirm(
                'Are you sure you want to delete this building? This cannot be undone!'
            )
        ) {
            this.model
                .destroy()
                .then(() => {
                    history.navigate('property/search', { trigger: true });
                })
                .catch((model, response) => {
                    alert(response.responseText);
                });
        }
    }

    handleStructureForceUpdateClick() {
        console.debug('PropertyEditStructureView#handleStructureForceUpdateClick');

        // Save updated_at as current time
        this.model.save({
            updated_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        }, {
            patch: true,
            wait: true
        });
    }

    handleTabClick() {
        this.$el
            .find('input[data-action="mass_override"]')
            .prop('checked', false)
            .trigger('change');
    }

    handleFilesClick() {
        console.debug('PropertyEditStructureView#handleFilesClick');

        this.subviews.filesModal.render();

        // Set building_id
        this.subviews.filesModal.collection.building_id = this.model.id;

        // Fetch files
        this.subviews.filesModal.collection.fetch({
            data: {
                order: 'last_modified_at',
                include: ['last_modified_by'],
            },
        });
    }

    handleAutomatchClick($e) {
        console.debug('PropertyEditStructureView#handleAutomatchClick');

        $e.stopPropagation();

        this.subviews.automatchModal.render();
    }

    handleChangelogClick(e) {
        e.stopPropagation();

        // Create modal view, with collection
        const changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        changelogView.collection.fetch({
            data: {
                resource_type: 'property_structure',
                resource_id: this.model.get('id'),
            },
        });
    }

    handleEditClick(e) {
        console.debug('PropertyEditStructureView#handleEditClick');

        e.stopPropagation();

        this.switchEditMode();
    }

    handleCancelClick(e) {
        console.debug('PropertyEditStructureView#handleCancelClick');

        e.stopPropagation();

        this.model.restoreFromSnapshot();

        // Reset images
        this.subviews.mediaTab.collection.reset(this.model.get('images'));

        // Reset typeaheads
        this.subviews.recBy.setValue(Formatter.getName(this.model.get('rec_by')));
        this.populateAgentBMCompanyField(this.subviews.agentCompany[1], 'agent_company_1');
        this.populateAgentBMCompanyField(this.subviews.agentCompany[2], 'agent_company_2');
        this.populateAgentBMCompanyField(this.subviews.agentCompany[3], 'agent_company_3');
        this.populateAgentBMCompanyField(this.subviews.bmCompany, 'bm_company');

        this.hideFormValidationMessage();

        this.switchReadMode();
    }

    handleSaveClick(e) {
        console.debug('PropertyEditStructureView#handleSaveClick');

        e.stopPropagation();

        this.save();
    }
}
