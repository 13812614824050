module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (imageUrl) { ;
__p += '\n    <a class="lightbox" href="' +
((__t = ( baseUrl )) == null ? '' : __t) +
'/' +
((__t = ( imageUrl )) == null ? '' : __t) +
'?w=1140&h=641&nc&ne&f=webp">\n        <img src="' +
((__t = ( baseUrl )) == null ? '' : __t) +
'/' +
((__t = ( imageUrl )) == null ? '' : __t) +
'?w=245&h=184" class="img-thumbnail p-0" loading="lazy">\n    </a>\n';
 } else { ;
__p += '\n    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" style="width:245px;height:184px;" class="img-thumbnail">\n';
 } ;


}
return __p
};
