module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n    <div class="row align-items-center">\n        <div class="col">\n            <h1 class="h2 my-0">\n                ';
 if (visibility === 'private') { ;
__p += '\n                    <i class="fa fa-search" aria-hidden="true"></i> Search My Lists\n                    ';
 } else if (visibility === 'shared') { ;
__p += '\n                    <i class="fa fa-search" aria-hidden="true"></i> Search Shared Lists\n                    ';
 } else if (visibility === 'public') { ;
__p += '\n                    <i class="fa fa-search" aria-hidden="true"></i> Search Public Lists\n                ';
 } ;
__p += '\n            </h1>\n        </div>\n\n        <div class="col-auto">\n        ';
 if (visibility === 'private') { ;
__p += '\n            <button type="button" id="btn-new_list" title="New Private List" class="btn btn-success">\n                <i class="fa fa-plus" aria-hidden="true"></i> Private List\n            </button>\n        ';
 } else if (visibility === 'shared') { ;
__p += '\n            <button type="button" id="btn-new_list" title="New Shared List" class="btn btn-success">\n                <i class="fa fa-plus" aria-hidden="true"></i> Shared List\n            </button>\n        ';
 } else if (visibility === 'public') { ;
__p += '\n            <button type="button" id="btn-new_list" title="New Public List" class="btn btn-success">\n                <i class="fa fa-plus" aria-hidden="true"></i> Public List\n            </button>\n        ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n\n<hr>\n\n<form id="frm-list-search" autocomplete="off">\n    <div class="row">\n        <div class="col-6">\n            <fieldset>\n                <div class="form-group row">\n                    <label class="col-3 col-form-label" for="field-name">List Name</label>\n                    <div class="col-6">\n                        <input class="form-control" id="field-name" name="name" type="text">\n                    </div>\n                </div>\n            </fieldset>\n            <a href="#" data-action="reset" class="small">Clear Criteria</a>\n        </div>\n    </div>\n\n    <div class="form-group text-center">\n        <button type="submit" class="btn btn-primary px-5">Search</button>\n    </div>\n</form>\n\n<div id="results"></div>';

}
return __p
};
