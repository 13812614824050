module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Search Results</h2>\n\n';
 if ('undefined' !== typeof people && people.length > 0) { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Type</th>\n            <th>Company</th>\n            <th>Name</th>\n            <th>Email</th>\n            <th>Phone</th>\n            <th>Assigned to</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 people.forEach((p) => { ;
__p += '\n        <tr data-id="' +
((__t = ( p.id )) == null ? '' : __t) +
'">\n            <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( p.id )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( Formatter.determinePersonType(p) )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (p.company) { ;
__p += '\n                <a href="#company/edit/' +
((__t = ( p.company.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getCompanyName(p.company) )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                <a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(p) )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>\n                ';
 if (p.email) { ;
__p += '\n                <a href="mailto:' +
((__t = ( p.email )) == null ? '' : __t) +
'"><i class="fa fa-envelope fa-lg" aria-hidden="true"></i></a>\n                ';
 } ;
__p += '\n            </td>\n            <td>' +
((__t = ( p.telephone )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( Formatter.getAssignedStaff(criteria, p) )) == null ? '' : __t) +
'</td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n';
 } else { ;
__p += '\n<p>No people match your search query.</p>\n';
 } ;


}
return __p
};
