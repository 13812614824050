import { Collection } from 'backbone';
import config from '@/js/app/config';
import KpiModel from '../models/kpi';

export default Collection.extend({

    url: function () {
        return config.api.url + 'staff/' + this.staffId + '/kpis';
    },

    initialize: function (models, options) {
        this.staffId = options.staffId;
    },

    model: KpiModel,

});