module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="actionsBar mb-3">\n    <button type="button" title="New Viewing" class="btn btn-success btn-sm" data-action="new"><i class="fa fa-plus" aria-hidden="true"></i> Viewing</button>\n    <!--<button type="button" title="Refresh" class="btn btn-sm pull-right" data-action="refresh"><i class="fa fa-refresh" aria-hidden="true"></i></button>-->\n</div>\n\n<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Viewing Date</th>\n            <th># Properties</th>\n            <th>Created By</th>\n            <th>Created Date</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n    ';
 viewings.forEach((v) => { ;
__p += '\n        <tr>\n            <td><a href="#property/viewing/edit/' +
((__t = ( v.id )) == null ? '' : __t) +
'">' +
((__t = ( v.id )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( v.viewing_date )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( _.size(v.properties) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( _.isObject(v.created_by) ? Formatter.getName(v.created_by) : '' )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( v.created_at )) == null ? '' : __t) +
'</td>\n            <td>\n                <a href="#property/viewing/edit/' +
((__t = ( v.id )) == null ? '' : __t) +
'" class="btn btn-secondary btn-sm">Edit</a>\n                ';
 if (_.size(v.properties) > 0) { ;
__p += '\n                    <a href="' +
((__t = ( Formatter.viewing_url(v, true) )) == null ? '' : __t) +
'" target="' +
((__t = ( _.uniqueId('print_zumen') )) == null ? '' : __t) +
'" class="btn btn-secondary btn-sm"><i class="fa fa-print"></i></a>\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
