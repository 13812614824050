import { Collection } from 'backbone';
import config from '@/js/app/config';
import PropertyAlertModel from '../models/property-alert';

export default Collection.extend({

    url: config.api.url + 'property_alert',

    model: PropertyAlertModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});