import { View } from 'backbone';

export default class DynamicSelect extends View {
    #hasRendered = false;

    preinitialize(options) {
        this.tagName = 'select';
        this.className = 'form-control';

        this.selected = options.selected || null;
        this.mapper = options.mapper || null;
        this.prependOptions = options.prependOptions || [];

        // Option to trigger change when calling setSelected() or when rendering with a selected value
        this.triggerChange = options.triggerChange !== undefined ? Boolean(options.triggerChange) : true;
    }

    initialize() {
        _.bindAll(this, 'render');

        // When collection updates or resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('UiDynamicSelectView#render');

        // If mapper is NOT a function
        if (!_.isFunction(this.mapper)) {
            throw "Property 'mapper' is not a function";
        }

        // If prependOptions is NOT an array
        if (!_.isArray(this.prependOptions)) {
            throw "Property 'prependOptions' is not an array";
        }

        // Map collection to label+value pairs using custom mapper function
        let options = this.collection.map(this.mapper);

        // Build and set options
        this.el.innerHTML = '';
        if (options.length > 0) {
            // Add options to initial options
            options = this.prependOptions.concat(options);

            // Build DocumentFragment of options
            const fragment = document.createDocumentFragment();
            options.forEach((v) => {
                const option = document.createElement('option');
                option.value = v.value;
                option.innerHTML = v.label;
                fragment.appendChild(option);
            });

            // Append options fragment
            this.el.appendChild(fragment);
            // Set selected value
            if (this.selected) {
                this.el.value = this.selected;

                if (this.triggerChange) {
                    this.$el.trigger('change');
                }
            }
        }

        this.#hasRendered = true;

        return this;
    }

    setSelected(value) {
        console.debug('DynamicSelect#setValue');

        if (value !== undefined) {
            this.selected = value;
        }

        // Store original value
        const originalValue = this.el.value;

        if (this.#hasRendered) {
            // Change element's selected value
            this.el.value = this.selected;

            // If new value is different from originally selected value; trigger change
            if (originalValue !== this.el.value && this.triggerChange) {
                this.$el.trigger('change');
            }
        }
    }

    clear() {
        this.el.innerHTML = '';
    }
}