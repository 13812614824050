import ModalView from '@/js/app/generic/modal';
import template from '../templates/modal_files.html';
import PropertyEditFilesView from './edit-files';

export default class ModalFilesView extends ModalView {
    preinitialize(options) {
        super.preinitialize();
        this.title = options.title || 'Files';
    }

    render() {
        console.debug('ModalAutomatchClientsView#render');

        super.render({
            size: 'xl'
        }).modalContent.innerHTML = template({
            title: this.title,
        });

        const view = new PropertyEditFilesView({
            collection: this.collection,
        });

        // Attach view to body slot and render
        view.setElement(this.$el.find('[data-slot="body"]')).render();

        return this;
    }
}