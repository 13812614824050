import async from 'async';
import { View } from 'backbone';
import uploader from '@/js/app/file-uploader/uploader';
import template from '../templates/files.html';
import DealFileItemView from './file-item';

export default class DealFilesView extends View {
    preinitialize() {
        this.events = {
            'click .btn-file-upload': this.handleUploadClick,
            'change .file-selector': this.uploadFile,
        };
    }

    initialize() {
        _.bindAll(this, 'addFileItem');

        // When model added to collection; add file item
        this.listenTo(this.collection, 'add', this.addFileItem);
    }

    render() {
        console.debug('DealFilesView#render');

        this.el.innerHTML = template();

        this.collection.each(this.addFileItem);

        return this;
    }

    addFileItem(model) {
        console.debug('DealFilesView#addFileItem');

        // Create file item view with model
        const fileItem = new DealFileItemView({
            model: model,
        });

        // Render view and append to file list
        this.$el.find('.file-list tbody').append(fileItem.render().el);
    }

    handleUploadClick() {
        console.debug('DealFilesView#handleUploadClick');

        // Trigger click on file upload input
        this.el.querySelector('.file-selector').click();
    }

    uploadFile($e) {
        console.debug('DealFilesView#uploadFile');

        const files = $e.currentTarget.files;
        const uploadStatusContainer = this.$el.find('.cntr-upload-status');

        // Create file upload queue with handler
        const q = async.queue((file, callback) => {
            // Upload file
            /**
             * Moving forward, file uploader could either connect to a collection, or only connect to the file upload API.
             * This would negate the need for extra parameters, like deal_id
             */
            uploader(
                file,
                {
                    method: 'PUT',
                    url: this.collection.url() + '/' + file.name,
                },
                {
                    uploadStatusContainer: uploadStatusContainer,
                }
            )
                .then((response) => {
                    // Add new file model to collection using response data
                    this.collection.add(response);
                })
                .then(callback)
                .catch(callback);
        }, 1);

        // Loop through the FileList
        for (const file of files) {
            // Add file to queue
            q.push(file);
        }

        // Clear file selector
        $e.currentTarget.value = '';
    }
}
