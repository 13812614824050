import { history, View } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';
import Preferences from '@/js/app/preferences';
import PropertySearchResultsView from './search-results';
import template from '../templates/unit-list.html';

export default class PropertyEditUnitList extends View {
    preinitialize(options) {
        this.events = {
            'click [data-action="new-unit"]': this.newUnit,
        };

        this.structure = options.structure;

        const query = {
            limit: 1000,
            include: ['agent_company'],
            sort_key: Preferences.sort_key,
            sort_order: Preferences.sort_order,
            building_id: this.structure.get('id'),
            structure_type: 'unit',
        };

        this.subviews = {
            propertySearchResultsView: new PropertySearchResultsView({
                query,
                format: 'long',
                buttons: {
                    type: 'unit',
                    add_to_list: true,
                    send: true,
                    print: true,
                    add_to_viewing: true,
                    bulkedit: true,
                },
            }),
        };
    }

    render() {
        const structure = this.structure.toJSON();

        this.el.innerHTML = template({
            structure_name: structure.structure_name_en || '',
        });

        this.subviews.propertySearchResultsView
            .setElement(this.el.querySelector('#results'))
            .render()
            .fetch();

        return this;
    }

    newUnit($e) {
        $e.preventDefault();
        $e.stopPropagation();

        // Send POST request to create new unit
        OAuth2Client.fetchJSON(config.api.url + 'units', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                building_id: this.structure.id,
                structure_type: 'unit',
            }),
        }).then((result) => {
            // Navigate to edit property
            history.navigate(
                'property/edit/' + result.building_id + '.0/' + result.id,
                { trigger: true }
            );
        });
    }
}
