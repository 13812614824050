import ModalView from '@/js/app/generic/modal';
import Preferences from '@/js/app/preferences';
import PropertySearchResultsView from '@/js/app/property/views/search-results';
import template from '../templates/automatch_properties.html';

export default class PeopleAutomatchPropertiesModalView extends ModalView {
    preinitialize(options) {
        super.preinitialize();
        this.type = options.type;
    }

    render() {
        console.debug('PeopleAutomatchPropertiesModalView#render');

        super.render({
            size: 'xl'
        }).modalContent.innerHTML = template({
            title: this.type.charAt(0).toUpperCase() + this.type.slice(1)
        });

        const view = new PropertySearchResultsView({
            query: this.compileQuery(),
            format: 'short',
            buttons: {
                type: 'unit',
                add_to_list: true,
                send: true,
                print: true,
                add_to_viewing: true,
            },
        });

        // Render view and attach to modal body
        this.$el.find('.modal-body').html(view.render().el);

        // Fetch collection
        view.fetch();

        return this;
    }

    compileQuery() {
        console.debug('PeopleAutomatchPropertiesModalView#compileQuery');

        let propertyType = '';
        if (this.type === 'leasing') {
            propertyType = 'rent';
        } else if (this.type === 'sales') {
            propertyType = 'sales';
        }

        const query = {
            sort_key: 'updated_at',
            sort_order: 'desc',
            limit: Preferences.limit,
            property_type: propertyType,
            availability_status: '!unavailable',
        };

        /**
         * @todo Existing property API endpoint can't handle null values in criteria.
         * For now we have to check for a non-falsy value before adding the criteria key.
         * This can be collapsed into a single definition once the API endpoint is upgraded.
         */

        if (this.model.get(propertyType + '_structure_type')) {
            query.structure_type = this.model.get(propertyType + '_structure_type');
        }

        const bedroom_criteria = [];
        if (this.model.get(propertyType + '_bedroom_0')) {
            bedroom_criteria.push(0);
        }
        if (this.model.get(propertyType + '_bedroom_1')) {
            bedroom_criteria.push(1);
        }
        if (this.model.get(propertyType + '_bedroom_2')) {
            bedroom_criteria.push(2);
        }
        if (this.model.get(propertyType + '_bedroom_3')) {
            bedroom_criteria.push(3);
        }
        if (this.model.get(propertyType + '_bedroom_4')) {
            bedroom_criteria.push(4);
        }
        if (bedroom_criteria.length > 0) {
            query.bedroom_no = bedroom_criteria;
        }

        if (this.model.get(propertyType + '_size_low')) {
            query.size = {
                low: this.model.get(propertyType + '_size_low'),
            };
        }

        if (this.model.get(propertyType + '_completion_year')) {
            query.completion_year = {
                low: this.model.get(propertyType + '_completion_year'),
            };
        }

        if (this.model.get(propertyType + '_feature_front_desk')) {
            query.front_desk = 1;
        }

        if (this.model.get(propertyType + '_feature_pets')) {
            query.pets = ['yes', 'large', 'small', 'negotiable'];
        }

        if (this.model.get(propertyType + '_feature_gym')) {
            query.gym = 1;
        }

        if (propertyType === 'rent') {
            if (this.model.get('rent_budget_high')) {
                query.rent = {
                    high: this.model.get('rent_budget_high'),
                };
            }
        } else {
            if (this.model.get('sales_budget_high')) {
                query.price = {
                    high: this.model.get('sales_budget_high')
                };
            }

            if (this.model.get('sales_target_yield')) {
                query.yield = {
                    low: this.model.get('sales_target_yield')
                };
            }
        }

        return query;
    }
}
