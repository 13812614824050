module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Search Results</h2>\n\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Name</th>\n            <th>Visibility</th>\n            <th># Properties</th>\n            <th># Buildings</th>\n            <th>Created By</th>\n            <th>Created Date</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n    ';
 lists.forEach((l) => { ;
__p += '\n        <tr data-id="' +
((__t = ( l.id )) == null ? '' : __t) +
'">\n            <td>\n                <a href="#property/lists/edit/' +
((__t = ( l.id )) == null ? '' : __t) +
'">' +
((__t = ( l.id )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>\n                <a href="#property/lists/edit/' +
((__t = ( l.id )) == null ? '' : __t) +
'">' +
((__t = ( l.name )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>\n                <span class="badge ' +
((__t = ( l.visibility === 'public' ? 'badge-warning' : 'badge-secondary' )) == null ? '' : __t) +
'">' +
((__t = ( l.visibility.capitalize() )) == null ? '' : __t) +
'</span>\n            </td>\n            <td>' +
((__t = ( _.size(l.properties) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( _.size(l.buildings) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( _.isObject(l.created_by) ? Formatter.getName(l.created_by) : '' )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( moment.utc(l.created_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'</td>\n            <td>\n            ';
 if (_.size(l.properties) > 0) { ;
__p += '\n                <a href="' +
((__t = ( Formatter.zumen_url(l.type, l.properties, 'en') )) == null ? '' : __t) +
'" target="' +
((__t = ( _.uniqueId('print_zumen') )) == null ? '' : __t) +
'"><i class="fa fa-print"></i></a>\n            ';
 } ;
__p += '\n            </td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '';

}
return __p
};
