import { View } from 'backbone';
import template from '../templates/results.html';
import ReportTenantsResultView from './result';

export default class ReportTenantsResultsView extends View {
    initialize() {
        _.bindAll(this, 'addResult');

        // When model added to collection; add result
        this.listenTo(this.collection, 'add', this.addResult);
    }

    render() {
        console.debug('ReportTenantsResultsView#render');

        this.el.innerHTML = template();

        return this;
    }

    addResult(model) {
        console.debug('ReportTenantsResultsView#addResult');

        // Create file item view with model
        const result = new ReportTenantsResultView({
            model: model,
        });

        // Render view and append to file list
        this.$el.find('table tbody').append(result.render().el);
    }
}
