import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import * as TextHelper from '@/js/app/text-helper';
import template from '../templates/search_results.html';

export default class ClientResultsView extends View {
    preinitialize(options) {
        this.events = {
            'click ul.pagination a': this.changePage,
            'click [data-action="export"]': this.export,
        };

        this.criteria = options.criteria || null;
    }

    initialize() {
        // When criteria changes; search
        this.listenTo(this.criteria, 'change', this.search);

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    search() {
        console.debug('ClientResultsView#search');

        // Fetch collection
        this.collection.fetch({
            data: _.pick(this.criteria.toJSON(), (value) => {
                return value !== '';
            }),
            reset: true,
        });
    }

    changePage($e) {
        console.debug('ClientResultsView#changePage');

        $e.preventDefault();

        // Set criteria offset from clicked UI element
        this.criteria.set('offset', Number($e.target.dataset.offset));
    }

    render() {
        console.debug('ClientResultsView#render');

        this.el.innerHTML = template({
            people: this.collection.toJSON(),
            criteria: this.criteria.toJSON(),
            total_rows: this.collection.total_rows,
            limit: this.criteria.get('limit'),
            offset: this.criteria.get('offset'),
            TextHelper,
            Formatter,
            Session,
        });

        return this;
    }

    export() {
        console.debug('ClientResultsView#export');

        const criteria = _.pick(this.criteria.toJSON(), (value) => {
            return value !== '';
        });
        criteria.include = ['count_alerts', 'assigned_staff', 'company'];

        const url = new URL(_.result(this.collection, 'url'));
        url.search = jQuery.param(_.omit(criteria, ['limit', 'offset'])); // Remove keys from criteria for CSV download

        OAuth2Client.csvDownload(
            url,
            {},
            {
                filename:
                    'clients-' + moment().format('YYYYMMDD-HHmmss') + '.csv',
            }
        );
    }
}
