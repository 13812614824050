module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


showPager = 'undefined' === typeof showPager ? true : showPager;
let c_offset = 0;
let c = 0;

let start = (offset / limit) - 4;
if (start <= 0) {
    start = 1;
}

total_pages = Math.ceil(total_rows / limit) || 1;

let previous = offset - limit;
if (previous < 0) {
    previous = false;
}

let next = offset + limit;
if (next >= total_rows) {
    next = false;
}
;
__p += '\n\n<div class="row align-items-center">\n    <div class="col-8 offset-2">\n        ';
 if (showPager && limit < total_rows) { ;
__p += '\n        <nav aria-label="Page navigation" class="d-flex justify-content-center">\n            <ul class="pagination mb-0">\n                <li class="page-item prev ' +
((__t = ( false === previous ? 'disabled' : '' )) == null ? '' : __t) +
'">\n                    <a class="page-link" href="#" data-offset="0">First</a>\n                </li>\n                <li class="page-item prev ' +
((__t = ( false === previous ? 'disabled' : '' )) == null ? '' : __t) +
'">\n                    <a class="page-link" href="#" data-offset="' +
((__t = ( previous )) == null ? '' : __t) +
'">\n                        <i class="fa fa-arrow-left" aria-hidden="true"></i> Previous\n                    </a>\n                </li>\n                ';
 for (let page = start; c < 10 && page <= total_pages; page++) {
                    c_offset = (limit * page) - limit;
                ;
__p += '\n                <li class="page-item ' +
((__t = ( offset == c_offset ? 'active' : '' )) == null ? '' : __t) +
'">\n                    <a class="page-link" href="#" data-offset="' +
((__t = ( c_offset )) == null ? '' : __t) +
'">' +
((__t = ( page )) == null ? '' : __t) +
'</a>\n                </li>\n                ';
 c++;
                } ;
__p += '\n                <li class="page-item ' +
((__t = ( !next ? 'disabled' : '' )) == null ? '' : __t) +
'">\n                    <a class="page-link" href="#" data-offset="' +
((__t = ( next )) == null ? '' : __t) +
'">\n                        Next <i class="fa fa-arrow-right" aria-hidden="true"></i>\n                    </a>\n                </li>\n                <li class="page-item next ' +
((__t = ( !next ? 'disabled' : '' )) == null ? '' : __t) +
'">\n                    <a class="page-link" href="#" data-offset="' +
((__t = ( (total_pages - 1) * limit )) == null ? '' : __t) +
'">Last</a>\n                </li>\n            </ul>\n        </nav>\n        ';
 } ;
__p += '\n    </div>\n\n    <div class="col-2">\n        <p class="my-0 text-right">\n            ' +
((__t = ( total_rows )) == null ? '' : __t) +
' matches\n        </p>\n    </div>\n</div>';

}
return __p
};
