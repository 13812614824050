import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import BloodhoundStaffDataset from '@/js/app/bloodhound/datasets/staff';
import config from '@/js/app/config';
import { getName } from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import BuildingModel from '@/js/app/property/models/building';
import UnitModel from '@/js/app/property/models/unit';
import propertyParseIdService from '@/js/app/property/services/parse-id';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import { TypeaheadStaffDefaults } from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import template from '../templates/edit.html';

export default class ApplicationEditView extends View {
    preinitialize() {
        this.events = {
            'change input[name="property_id"]': this.populatePropertyData,
            'submit form': this.save,
            'click .btn[data-action="close"]': this.close,
        };

        // Create subviews
        this.subviews = {
            staffTypeahead: new UiTypeaheadView({
                placeholder: 'Agent',
                options: TypeaheadStaffDefaults,
                datasets: [
                    typeaheadIndividualDatasource(BloodhoundStaffDataset),
                ],
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'render', 'save');
    }

    render() {
        console.debug('ApplicationEditView#render');

        this.el.innerHTML = template({
            isRent: this.model.get('type') === 'rent',
        });

        populateForm(this.el, this.model.toJSON());

        this.$el
            .find('#cntr-staff_id')
            .html(this.subviews.staffTypeahead.render().el);

        if (this.model.get('assigned_to')) {
            // Fetch information for given staff ID
            /** @todo Ideally we could set the ID in typeahead, and have it reverse lookup the staff, but Bloodhound isn't built for this */
            OAuth2Client.fetchJSON(
                config.api.url + 'staff/' + this.model.get('assigned_to'),
                {}
            ).then((response) => {
                // Set datum
                this.subviews.staffTypeahead.selectDatum(response);

                // Set value
                this.subviews.staffTypeahead.setValue(
                    getName(response)
                );
            });
        }

        return this;
    }

    populatePropertyData($e) {
        console.debug('ApplicationEditView#populatePropertyData');

        $e.preventDefault();

        const propertyId = propertyParseIdService($e.currentTarget.value);

        // If no property ID, return
        if (!propertyId) {
            return;
        }

        // Create appropriate model, based on structure_type
        let model;
        if (
            propertyId.structure_type === 'B' ||
            propertyId.structure_type === 'L'
        ) {
            model = new BuildingModel({
                id: propertyId.id,
            });
        } else if (
            propertyId.structure_type === 'A' ||
            propertyId.structure_type === 'H'
        ) {
            model = new UnitModel({
                id: propertyId.id,
            });
        }

        const all_fields = this.$el.find(
            'input[data-populate-by="property_id"]'
        );
        const structure_name_field = this.$el.find('input[name="物件名"]');
        const room_no_field = this.$el.find('input[name="物件名_部屋番号"]');

        structure_name_field.removeClass('error').empty();
        all_fields.val('');

        model.fetch().then((p) => {
            const data = {};

            // Add building and unit ID based on structure type
            if (
                p.structure_type === 'building' ||
                p.structure_type === 'land'
            ) {
                data.building_id = p.id;
            } else if (
                p.structure_type === 'unit' ||
                p.structure_type === 'house'
            ) {
                data.building_id = p.building_id;
                data.unit_id = p.id;
            }

            // Set data on model
            this.model.set(data);

            // Structure type
            structure_name_field
                .val(p.structure_name_en || '')
                .trigger('change');

            // Room number
            room_no_field.val(p.room_no).trigger('change');
        });
    }

    save($e) {
        console.debug('ApplicationEditView#save');

        $e.preventDefault();

        const options = {
            patch: true,
            wait: true,
        };

        // If model is new, set callback to add model to collection
        if (this.model.isNew()) {
            options.success = (model) => {
                this.collection.add(model);
            };
        }

        // Set model
        this.model.save(
            {
                property_id: this.$el.find('[name="property_id"]').val(),
                物件名: this.$el.find('[name="物件名"]').val(),
                物件名_部屋番号: this.$el
                    .find('[name="物件名_部屋番号"]')
                    .val(),
                賃貸条件_賃料_月額: this.$el
                    .find('[name="賃貸条件_賃料_月額"]')
                    .val(),
                賃貸条件_Offer: this.$el.find('[name="賃貸条件_Offer"]').val(),
                代金_総額_円: this.$el.find('代金_総額_円').val(),
                代金_内訳_土地_円: this.$el.find('代金_内訳_土地_円').val(),
                代金_内訳_建物_円: this.$el.find('代金_内訳_建物_円').val(),
                代金_内訳_内消費税_円: this.$el
                    .find('代金_内訳_内消費税_円')
                    .val(),
                代金_内訳_付属物_円: this.$el.find('代金_内訳_付属物_円').val(),
                代金_内訳_付属物_内消費税_円: this.$el
                    .find('代金_内訳_付属物_内消費税_円')
                    .val(),
                assigned_to: this.subviews.staffTypeahead.datum
                    ? this.subviews.staffTypeahead.datum.id
                    : null,
            },
            options
        );

        // Remove view
        this.remove();
    }

    close() {
        console.debug('ApplicationEditView#close');

        // Remove view
        this.remove();
    }
}
