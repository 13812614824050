import { Model, history } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

export default class PropertyLocationModel extends Model {

    urlRoot = config.api.url + 'locations';

    geocodeInSync = true;

    addressInSync = true;

    #savedData = {};

    snapshot() {
        console.debug('PropertyLocationModel#snapshot');

        this.#savedData = this.toJSON();
    }

    changedAttributesSinceSnapshot() {
        console.debug('PropertyLocationModel#changedAttributesSinceSnapshot');

        const changedKeys = _.keys(this.changedAttributes(this.#savedData));
        return changedKeys.length === 0 ? false : this.pick(changedKeys);
    }

    restoreFromSnapshot() {
        console.debug('PropertyLocationModel#restoreFromSnapshot');

        this.set(this.#savedData);
    }

    persist() {
        console.debug('PropertyLocationModel#persist');

        // If model is new
        if (this.isNew()) {
            console.debug('Saving all attributes:', this.attributes);

            let l_id;

            // Save all attributes and update URL on success
            return this.save(this.attributes)
                .then((response) => {
                    // Save location ID
                    l_id = response.id;

                    // Create new building at new location
                    return OAuth2Client.fetchJSON(config.api.url + 'buildings', {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                        },
                        body: JSON.stringify({
                            l_id,
                        }),
                    });
                })
                .then(() => {
                    history.navigate('property/edit/' + l_id + '.0', { trigger: true });
                });
        }
    }

    defaults() {
        return {
            postcode: null,
            prefecture: null,
            city: null,
            neighborhood: null,
            address: null,
            latitude: null,
            longitude: null,
            use_geocode: 0,
            approved_by: null,
            approved_at: null,
            modified_by: null,
            stations: null,
        };
    }

}