import { history, Router } from 'backbone';
import BuildingsSearchView from './building/views/search';
import ClientAllView from './client/views/all';
import ClientLatestView from './client/views/latest';
import ClientMineView from './client/views/mine';
import ClientSearchView from './client/views/search';
import CompanyEditView from './company/views/edit';
import CompanySearchView from './company/views/search';
import DashboardBlankView from './dashboard/views/blank';
import DashboardDivisionView from './dashboard/views/division';
import DashboardKamisamaView from './dashboard/views/kamisama';
import DealModel from './deal/models/deal';
import DealEditView from './deal/views/edit';
import DealSearchView from './deal/views/search';
import DealSummaryView from './deal/views/summary';
import InquiryDetailPageView from './inquiry/views/page-detail';
import InquirySearchView from './inquiry/views/search';
import LoginView from './login/views/login';
import Logout from './logout';
import oAuth2Client from './oauth2-client';
import PeopleEditView from './people/views/edit';
import PeopleSearchView from './people/views/search';
import PreferenceEditView from './preference/views/edit';
import Preferences from './preferences';
import PropertyAlertEditView from './property-alert/views/edit';
import PropertyListAddView from './property-list/views/add';
import PropertyListEditView from './property-list/views/edit';
import PropertyListSearchView from './property-list/views/search';
import propertyRestoreSelectedService from './property/services/restore-selected';
import PropertyCreateView from './property/views/create';
import PropertyEditView from './property/views/edit';
import PropertySearchView from './property/views/search';
import PropertySendView from './property/views/send';
import Report3nosukeIndexView from './report/3nosuke/views/index';
import ReportAccountingCriteriaView from './report/accounting/views/criteria';
import ReportCommissionCriteriaView from './report/commission/views/criteria';
import ReportInquiriesIndexView from './report/inquiries/views';
import ReportMotozukeIndexView from './report/motozuke/views/index';
import ReportRevenueCriteriaView from './report/revenue/views/criteria';
import ReportStaffCriteriaView from './report/staff/views/criteria';
import ReportTenantsIndexView from './report/tenants/views';
import ReportYtdIndexView from './report/ytd/views/index';
import Session from './session';
import StaffEditView from './staff/views/edit';
import StaffSearchView from './staff/views/search';
import ViewingAddView from './viewing/views/add';
import ViewingEditView from './viewing/views/edit';

// Counter for views on a given page that want to warn on unload
window.warnOnUnload = 0;

// Create reference to original loadUrl() function
const originalLoadUrlFunction = history.loadUrl;

// Set base title and separator
const titleBase = document.title;
const titleSeparator = ' | ';

// Override loadUrl function
history.loadUrl = function () {
    if (this.fragment === 'login' && arguments[0] === 'login') {
        window.warnOnUnload = 0;
        return originalLoadUrlFunction.apply(this, arguments);
    }

    if (window.warnOnUnload > 0 && !confirm('Are you sure you want to leave this page?')) {
        window.location.hash = '#' + history.fragment;
        return false;
    } else {
        window.onbeforeunload = '';
        window.warnOnUnload = 0;
        return originalLoadUrlFunction.apply(this, arguments);
    }
}

export default class AppRouter extends Router {

    preinitialize() {
        this.contentContainer = $('#content');

        this.routes = {
            '': this.dashboard,
            'buildings/lists/add': this.buildings_lists_add,
            'buildings/search': this.buildings_search,
            'buildings/send': this.buildings_send,
            'buildings/send/id/:id': this.buildings_send,
            'clients/search': this.clients_search,
            'clients/mine': this.clients_mine,
            'clients/latest': this.clients_latest,
            'clients/all': this.clients_all,
            'property/alerts/edit/:id': this.property_alerts_edit,
            'property/edit': this.property_create,
            'property/edit/:ls_id': this.property_edit,
            'property/edit/:ls_id/:id': this.property_edit,
            'property/lists': this.property_lists,
            'property/lists/add': this.property_lists_add,
            'property/lists/add/:p_id': this.property_lists_add,
            'property/lists/edit/:id': this.property_lists_edit,
            'property/lists/:visibility': this.property_lists,
            'property/search': this.property_search,
            'property/search/:query': this.property_search,
            'property/send': this.property_send,
            'property/send/id/:id': this.property_send,
            'property/viewing/add': this.property_viewing_add,
            'property/viewing/edit/:v_id': this.property_viewing_edit,
            'people/search': this.people_search,
            'people/edit/:id': this.people_edit,
            'company/search': this.company_search,
            'company/edit/:id': this.company_edit,
            'inquiries': this.inquiry_search,
            'inquiries/:id': this.inquiry_detail,
            'enquiries': this.inquiry_search,   // Legacy URL
            'enquiries/:id': this.inquiry_detail,   // Legacy URL
            'deals': this.deal_search,
            'deals/new_sales': this.deal_new_sales,
            'deals/new_leasing': this.deal_new_leasing,
            'deals/summary': this.deal_summary,
            'deals/edit/:id': this.deal_edit,
            'contracts/edit/:id': this.contract_edit,  // Legacy URL for old links
            'preferences': this.preferences,
            'login': this.routeLogin,
            'logout': this.routeLogout,
            'reports/3nosuke': this.reports_3nosuke,
            'reports/accounting': this.reports_accounting,
            'reports/commission': this.reports_commission,
            'reports/commission/:staff_id/:date_from/:date_to': this.reports_commission,
            'reports/motozuke': this.reports_motozuke,
            'reports/revenue': this.reports_revenue,
            'reports/revenue/:date_from/:date_to': this.reports_revenue,
            'reports/revenue/:date_from/:date_to/:division_id': this.reports_revenue,
            'reports/staff': this.reports_staff,
            'reports/staff/:staff_id/:date_from/:date_to': this.reports_staff,
            'reports/ytd': this.reports_ytd,
            'reports/ytd/:financialYear/:divisionId': this.reports_ytd,
            'reports/tenants': this.reports_tenants,
            'reports/inquiries': this.reports_inquiries,
            'staff/search': this.staff_search,
            'staff/edit/:id': this.staff_edit,
        };

        this.noTokenRoutes = [
            this.routeLogin.name,
        ];
    }

    initialize() {
        // Start session
        Session.start();
    }

    execute(callback, args) {
        // If not login route
        if (!this.noTokenRoutes.includes(callback.name)) {
            // If user does NOT have token
            if (!oAuth2Client.hasToken()) {
                sessionStorage.setItem('redirect', history.fragment);

                // Redirect to login
                this.navigate('login', { trigger: true });
                return false;
            }
        }

        console.debug('Router#execute: Executing "' + callback.name + '"');

        // Scroll to top
        window.scrollTo(0, 0);

        if (callback) callback.apply(this, args);
    }

    dashboard() {
        // Update title
        document.title = 'Dashboard' + titleSeparator + titleBase;

        // Create dashboard based on session
        let view;
        if (Session.data.dashboard === 'kamisama') {
            view = new DashboardKamisamaView();
        } else if (Session.data.dashboard === 'default') {
            view = new DashboardDivisionView();
        } else {
            view = new DashboardBlankView();
        }

        this.contentContainer.html(view.el);
    }

    buildings_search() {
        // Update title
        document.title = 'Search Buildings' + titleSeparator + titleBase;

        const view = new BuildingsSearchView();

        this.contentContainer.html(view.el);
    }

    buildings_send(id) {
        // Update title
        document.title = 'Send Buildings' + titleSeparator + titleBase;

        if (id) {
            // Set selected
            propertyRestoreSelectedService([Number(id)]);
        }

        // Create new view
        const view = new PropertySendView({
            type: 'building',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    buildings_lists_add(p_id) {
        // Update title
        document.title = 'Add Buildings to List' + titleSeparator + titleBase;

        if (p_id) {
            propertyRestoreSelectedService([Number(p_id)]);
        }

        // Create view
        const view = new PropertyListAddView({
            type: 'building',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    clients_search() {
        // Update title
        document.title = 'Search Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientSearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    clients_mine() {
        // Update title
        document.title = 'My Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientMineView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    clients_all() {
        // Update title
        document.title = 'All Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientAllView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    clients_latest() {
        // Update title
        document.title = 'Latest Clients' + titleSeparator + titleBase;

        // Create view
        const view = new ClientLatestView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    people_search() {
        // Update title
        document.title = 'Search People' + titleSeparator + titleBase;

        // Create view
        const view = new PeopleSearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    people_edit(id) {
        // Update title
        document.title = 'Edit Person' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new PeopleEditView({
            personId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    company_search() {
        // Update title
        document.title = 'Search Companies' + titleSeparator + titleBase;

        // Create view
        const view = new CompanySearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    company_edit(id) {
        // Update title
        document.title = 'Edit Company' + titleSeparator + titleBase;

        // Create new view
        const view = new CompanyEditView({
            companyId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_search(query) {
        // Update title
        document.title = 'Search Properties' + titleSeparator + titleBase;

        const view = new PropertySearchView({
            property_type: 'string' === typeof query ? query : false,
        });

        this.contentContainer.html(view.el);
    }

    property_send(id) {
        // Update title
        document.title = 'Send Properties' + titleSeparator + titleBase;

        if (id) {
            // Set selected
            propertyRestoreSelectedService([Number(id)]);
        }

        // Create new view
        const view = new PropertySendView({
            type: 'unit',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_lists_add(p_id) {
        // Update title
        document.title = 'Add Property List' + titleSeparator + titleBase;

        if (p_id) {
            propertyRestoreSelectedService([Number(p_id)]);
        }

        // Create view
        const view = new PropertyListAddView({
            type: 'unit',
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_lists(visibility) {
        // Update title
        document.title = 'Search Property Lists' + titleSeparator + titleBase;

        // Create view
        const view = new PropertyListSearchView({
            visibility,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_lists_edit(id) {
        // Update title
        document.title = 'Edit Property List' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new PropertyListEditView({
            propertyListId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_viewing_add() {
        // Update title
        document.title = 'Add Viewing' + titleSeparator + titleBase;

        // Create view
        const view = new ViewingAddView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_viewing_edit(v_id) {
        // Update title
        document.title = 'Edit Viewing' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new ViewingEditView({
            viewingId: v_id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_alerts_edit(id) {
        // Update title
        document.title = 'Edit Property Alert' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new PropertyAlertEditView({
            propertyAlertId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_create() {
        // Update title
        document.title = 'Add Property' + titleSeparator + titleBase;

        let newPropertyData = sessionStorage.getItem('new-property-data');

        if (newPropertyData) {
            newPropertyData = JSON.parse(newPropertyData)
        }

        const view = new PropertyCreateView({
            new_property_data: newPropertyData
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    property_edit(ls_id = false, id = false) {
        // Update title
        document.title = 'Edit Property' + titleSeparator + titleBase;

        const view = new PropertyEditView({
            ls_id,
            id,
            property_type: Preferences.property_type || 'rent'
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    inquiry_search() {
        // Update title
        document.title = 'Search Enquiries' + titleSeparator + titleBase;

        // Create view
        const view = new InquirySearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    inquiry_detail(id) {
        // Update title
        document.title = 'Inquiry' + titleSeparator + titleBase;

        // Create view
        const view = new InquiryDetailPageView({
            inquiryId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    deal_search() {
        // Update title
        document.title = 'Search Deals' + titleSeparator + titleBase;

        // Create view
        const view = new DealSearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    deal_new_sales() {
        // Update title
        document.title = 'New Deal' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new DealEditView({
            model: new DealModel({
                type: 'sales',
            }),
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    deal_new_leasing() {
        // Update title
        document.title = 'New Deal' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new DealEditView({
            model: new DealModel({
                type: 'rent',
            }),
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    deal_summary() {
        // Update title
        document.title = 'Deals Summary' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new DealSummaryView({
            model: new DealModel({
                type: 'sales',
            }),
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    deal_edit(id) {
        // Update title
        document.title = 'Edit Deal' + titleSeparator + titleBase;

        // Create new view, with new model
        const view = new DealEditView({
            model: new DealModel({
                id,
            }),
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    contract_edit(id) {
        // Redirect to deals edit
        this.navigate('deals/edit/' + id, { trigger: true });
    }

    preferences() {
        // Update title
        document.title = 'Preferences' + titleSeparator + titleBase;

        const view = new PreferenceEditView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    routeLogin() {
        // Update title
        document.title = 'Login' + titleSeparator + titleBase;

        if (Session.isInitialized()) {
            history.navigate('', { trigger: true });
        }
        else {
            // Make sure any old sessions are fully cleared out
            Session.end();

            const view = new LoginView();

            // Attach view to DOM
            this.contentContainer.html(view.el);
        }
    }

    routeLogout() {
        // Update title
        document.title = 'Logout' + titleSeparator + titleBase;

        Logout();
    }

    reports_3nosuke() {
        // Update title
        document.title = '3nosuke' + titleSeparator + titleBase;

        const view = new Report3nosukeIndexView();

        this.contentContainer.html(view.el);
    }

    reports_accounting() {
        // Update title
        document.title = 'Accounting Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportAccountingCriteriaView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_commission(staff_id, date_from, date_to) {
        // Update title
        document.title = 'Commission Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportCommissionCriteriaView({
            criteria: {
                staff_id: staff_id,
                date_from: date_from,
                date_to: date_to,
            },
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_motozuke() {
        // Update title
        document.title = 'Motozuke Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportMotozukeIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_revenue(date_from = null, date_to = null, division_id = null) {
        // Update title
        document.title = 'Revenue Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportRevenueCriteriaView(_.omit({
            date_from: date_from,
            date_to: date_to,
            division_id: division_id,
        }, _.isNull));

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_staff(staff_id, date_from, date_to) {
        // Update title
        document.title = 'Staff Deal Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportStaffCriteriaView({
            criteria: {
                staff_id: staff_id,
                date_from: date_from,
                date_to: date_to,
            },
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_ytd(financialYear = null, divisionId = null) {
        // Update title
        document.title = 'Year-to-date Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportYtdIndexView(_.omit({
            financialYear,
            divisionId
        }, _.isNull));

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_tenants() {
        // Update title
        document.title = 'Tenants Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportTenantsIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    reports_inquiries() {
        // Update title
        document.title = 'Enquiries Report' + titleSeparator + titleBase;

        // Create view
        const view = new ReportInquiriesIndexView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    staff_search() {
        // Update title
        document.title = 'Search Staff' + titleSeparator + titleBase;

        // Create view
        const view = new StaffSearchView();

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

    staff_edit(id) {
        // Update title
        document.title = 'Edit Staff' + titleSeparator + titleBase;

        // Create new view
        const view = new StaffEditView({
            staffId: id,
        });

        // Attach view to DOM
        this.contentContainer.html(view.el);
    }

}