module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Search Results</h2>\n\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Contract Date</th>\n            <th>Property Name</th>\n            <th>Seller / Owner</th>\n            <th>Buyer / Leasee</th>\n            <!-- <th>Assigned To</th> -->\n            <th>Approved?</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 deals.forEach((deal) => { ;
__p += '\n        <tr>\n            <td><a href="#deals/edit/' +
((__t = ( deal.id )) == null ? '' : __t) +
'">' +
((__t = ( deal.custom_id ? deal.custom_id : deal.id )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( deal.取引の概要_契約成立年月日 ? deal.取引の概要_契約成立年月日 : deal.賃貸条件_契約期間_start )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( deal.物件名 )) == null ? '' : __t) +
' ' +
((__t = ( deal.物件名_部屋番号 ? '#' + deal.物件名_部屋番号 : '' )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (deal.貸主_本人_type === 'individual') { ;
__p += '\n                    <a href="#people/edit/' +
((__t = ( deal.貸主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( deal.貸主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } else if (deal.貸主_本人_type === 'company') { ;
__p += '\n                    <a href="#company/edit/' +
((__t = ( deal.貸主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( deal.貸主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (deal.借主_本人_type === 'individual') { ;
__p += '\n                    <a href="#people/edit/' +
((__t = ( deal.借主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( deal.借主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } else if (deal.借主_本人_type === 'company') { ;
__p += '\n                    <a href="#company/edit/' +
((__t = ( deal.借主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( deal.借主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n            </td>\n            <!-- <td>' +
((__t = ( _.isObject(deal.split_agent_0) ? Formatter.getName(deal.split_agent_0) : 'System' )) == null ? '' : __t) +
'</td> -->\n            <td>\n                ';
 if (1 === deal.division_approved) { ;
__p += '\n                <span data-placement="left" rel="tooltip" title="Division approved on ' +
((__t = ( deal.division_approved_date )) == null ? '' : __t) +
'"><i class="fa fa-check-circle" aria-hidden="true"></i></span>\n                ';
 } ;
__p += '\n                ';
 if (1 === deal.accounting_approved) { ;
__p += '\n                <span data-placement="left" rel="tooltip" title="Accounting approved on ' +
((__t = ( deal.accounting_approved_date )) == null ? '' : __t) +
'"><i class="fa fa-check-circle" aria-hidden="true"></i></span>\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n        ';
 }) ;
__p += '\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '';

}
return __p
};
