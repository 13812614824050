import { Collection } from 'backbone';
import config from '@/js/app/config';
import StaffModel from '../models/staff';

export default Collection.extend({

    url: config.api.url + 'staff',

    model: StaffModel,

    comparator: 'last_name',

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});