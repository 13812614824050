module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-10 offset-1">\n    <p>\n        ' +
((__t = ( name )) == null ? '' : __t) +
'\n        <a href="#" title="Remove" class="pull-right d-none" data-action="clear">\n            <i class="fa fa-times fa-lg" style="color:#000000" aria-hidden="true"></i>\n        </a>\n    </p>\n    <div class="progress">\n        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>\n    </div>\n    <p class="status-text" style="font-weight: bold"></p>\n</div>';

}
return __p
};
