import config from './config';
import { getName } from './formatter';
import OAuth2Client from './oauth2-client';
import Preferences from './preferences';

class Session {
    constructor() {
        this.initialize();
    }

    initialize() {
        this.data = {
            initialized: false,
            user_id: undefined,
            division_id: undefined,
            company_id: undefined,
            email: '',
            username: '',
            last_name: '',
            first_name: '',
            scopes: [],
            preferences: {},
            dashboard: '',
        };
    }

    async load() {
        console.debug('Session.load(): loading session info');

        // Load session info from API
        const data = await OAuth2Client.fetchJSON(config.api.url + 'session_info', { credentials: 'include' });

        // Load returned session data into local storage
        this.data.user_id = data.results.user_id;
        this.data.division_id = data.results.division_id;
        this.data.company_id = data.results.company_id;
        this.data.email = data.results.email;
        this.data.first_name = data.results.first_name;
        this.data.last_name = data.results.last_name;
        this.data.scopes = data.results.scopes;
        this.data.preferences = data.results.preferences || {};
        this.data.initialized = true;

        // Set dashboard from preferences
        /** @todo Remove dashboard from preferences on server side, since it's not controllable by the user */
        this.data.dashboard = this.data.preferences.dashboard;
        delete this.data.preferences.dashboard;

        // Save session data to localStorage
        this.persist();
    }

    start() {
        console.debug('Session.start(): starting session');

        // If session not initialized
        if (this.data.initialized === false) {
            // Reload session data from session storage
            this.data = JSON.parse(localStorage.getItem('Session.data')) || this.data;
        }

        // Apply preferences from session
        _.extend(Preferences, this.data.preferences);

        // Load preferences from localStorage
        const storedPreferences = JSON.parse(localStorage.getItem('preferences')) || {};

        // Apply stored preferences
        _.extend(Preferences, storedPreferences);

        // Show navbar
        $('nav.navbar').removeClass('d-none');

        this.applySettings();
    }

    applySettings() {
        $('.navbar [data-outlet="account-name"]').text(getName(this.data));

        // Hide items for which user does not have permission to view
        $('.navbar [data-permission]').each((i, obj) => {
            const permissions = obj.dataset.permission.split(',');

            // If none of the permissions are allowed (OR); hide the item
            if (_.some(permissions, this.isAllowed.bind(this))) {
                obj.style.display = '';
            } else {
                obj.style.display = 'none';
            }
        });
    }

    persist() {
        // Save session data to session storage
        localStorage.setItem('Session.data', JSON.stringify(this.data));
    }

    end() {
        this.initialize();

        OAuth2Client.clearToken();

        localStorage.removeItem('Session.data');
        console.log('Session.end(): session terminated');
    }

    /**
     * Checks whether session is initialized or not
     */
    isInitialized() {
        return this.data.initialized;
    }

    isAllowed(permission) {
        return this.data.scopes.includes(permission);
    }

    isDivision(division_id) {
        return this.data.division_id === Number(division_id);
    }
}

export default new Session();