module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n    <div class="row align-items-center">\n        <div class="col">\n            <h1 class="h2 my-0">Property Alert: ' +
((__t = ( alert.id )) == null ? '' : __t) +
'\n            </h1>\n        </div>\n\n        ';
 if (Session.isAllowed('phnx:property_alerts:d')) { ;
__p += '\n            <div class="col-auto">\n                <button type="button" title="Delete Alert" class="btn btn-danger" data-action="delete">Delete</button>\n            </div>\n            ';
 } ;
__p += '\n    </div>\n</div>\n\n<hr>\n\n<form>\n    <div class="row">\n        <div class="col-6">\n            <div class="form-group row">\n                <label class="col-4" for="field-email_address">Email Address</label>\n                <div class="col-8">\n                    <input class="form-control" id="field-email_address" name="email_address" type="email">\n                </div>\n            </div>\n        </div>\n        <div class="col-6">\n            <div class="form-group row">\n                <label class="col-4 col-form-label" for="field-status">Status</label>\n                <div class="col-4">\n                    <select class="form-control" id="field-status" name="status">\n                        <option value="pending">pending</option>\n                        <option value="active">active</option>\n                        <!-- <option value="inactive">inactive</option> -->\n                        <option value="deleted">deleted</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class="form-group row">\n        <div class="col-2">Criteria</div>\n        <div class="col-9">\n            ' +
((__t = ( alert.search_text ? alert.search_text.join('<br>') : '' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n    <div class="row">\n        <div class="col-6">\n            <div class="form-group row">\n                <label class="col-4 col-form-label" for="field-created_at">Created at</label>\n                <div class="col-6">\n                    <input class="form-control" id="field-created_at" type="text" readonly>\n                </div>\n            </div>\n        </div>\n        <div class="col-6">\n            <div class="form-group row">\n                <label class="col-4 col-form-label" for="field-updated_at">Updated at</label>\n                <div class="col-6">\n                    <input class="form-control" id="field-updated_at" type="text" readonly>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class="form-group row">\n        <label class="col-2 col-form-label" for="field-criteria">Criteria code</label>\n        <div class="col-9">\n            <textarea id="field-criteria" name="criteria" rows="5" class="form-control" readonly></textarea>\n        </div>\n    </div>\n</form>';

}
return __p
};
