module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (Array.isArray(people) && people.length > 0) { ;
__p += '\n';
 if (Session.isAllowed('phnx:people:exp')) { ;
__p += '\n<button type="button" data-action="export" class="btn btn-secondary mb-3">Export CSV</button>\n';
 } ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Email</th>\n            <th>Name</th>\n            <th>Phone</th>\n            <th>Country</th>\n            <th>Type</th>\n            <th>Bdrm</th>\n            <th>Budget</th>\n            <th>Size</th>\n            <th>Registered</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 people.forEach((p) => { ;
__p += '\n            <tr data-id="' +
((__t = ( p.id )) == null ? '' : __t) +
'">\n                <td>\n                    ';
 if (p.email) { ;
__p += '\n                        <a href="mailto:' +
((__t = ( p.email )) == null ? '' : __t) +
'"><i class="fa fa-envelope fa-lg" aria-hidden="true"></i></a>\n                    ';
 } ;
__p += '\n                </td>\n                <td>\n                    <a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(p) )) == null ? '' : __t) +
'</a><br>\n                    ';
 if (p.company) { ;
__p += '\n                        ' +
((__t = ( Formatter.getCompanyName(p.company) )) == null ? '' : __t) +
'\n                    ';
 } ;
__p += '\n                </td>\n                <td>' +
((__t = ( p.telephone )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.countryName(p.country) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaStructureType(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaBedrooms(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaBudget(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaSize(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.timestamp(p.created_at, -1) )) == null ? '' : __t) +
'</td>\n            </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n';
 } else { ;
__p += '\n    <div class="text-center">No people match your search query.</div>\n';
 } ;


}
return __p
};
