import { history, View } from 'backbone';
import { bindAll } from 'underscore';
import Spinner from '@/js/app/ui/views/spinner';
import OAuth2Client from '@/js/app/oauth2-client';
import template from '../templates/login.html';

export default class LoginView extends View {
    preinitialize() {
        this.events = {
            'submit form': this.handleSubmit,
        };

        this.spinner = new Spinner;
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        bindAll(this, 'login');

        this.render();
    }

    render() {
        console.debug('LoginView#render');

        // Hide navigation
        $('nav.navbar').addClass('d-none');

        // Render template
        this.el.innerHTML = template();

        return this;
    }

    handleSubmit($e) {
        console.debug('LoginView#handleSubmit');

        $e.preventDefault();

        // Get username and password
        const username = $e.currentTarget.querySelector('#field-email').value;
        const password = $e.currentTarget.querySelector('#field-password').value;

        // Login with username and password
        this.login(username, password);
    }

    login(username, password) {
        console.debug('LoginView#login');

        // Get alert and button elements
        const cntrAlert = this.el.querySelector('.alert');
        const submitButton = this.el.querySelector('button[type="submit"]');

        // Hide existing alerts while attempting to login
        cntrAlert.classList.add('d-none');

        // Disable button and start spinner
        submitButton.disabled = true;
        this.spinner.spin(submitButton);

        // Authenticate
        OAuth2Client.authenticate(username, password)
            .then(() => {
                // Navigate to redirect item from session storage, or dashboard
                const redirect = sessionStorage.getItem('redirect');
                if (redirect) {
                    sessionStorage.removeItem('redirect');

                    if (redirect[0] === '/') {
                        window.location.href = redirect;
                    } else {
                        history.navigate(redirect, { trigger: true });
                    }
                } else {
                    history.navigate('', { trigger: true });
                }
            })
            .catch((err) => {
                // Set error message depending on error name
                let errorMessage;
                if (err.name === 'OAuth2Error' && err.error === 'invalid_grant') {
                    console.warn(err.message);

                    errorMessage = 'Your username or password was incorrect';
                } else {
                    console.error(err.message);

                    errorMessage = 'An unknown error occurred. Please try again';
                }

                // Update alert text and show
                cntrAlert.innerHTML = errorMessage;
                cntrAlert.classList.remove('d-none');

                // Stop spinner and enable button
                this.spinner.stop();
                submitButton.disabled = false;
            });
    }
}