import { View } from 'backbone';
import template from './templates/alert.html';

export default class ServiceWorkerAlertView extends View {
    preinitialize(options) {
        this.events = {
            'click [data-action="update"]': this.handleUpdateClick,
        };

        this.onUpdate = options.onUpdate;
    }

    render() {
        console.debug('ServiceWorkerAlertView#render');

        this.el.innerHTML = template();

        return this;
    }

    handleUpdateClick() {
        console.debug('ServiceWorkerAlertView#handleUpdateClick');

        this.onUpdate();
    }
}