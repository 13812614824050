import numeral from 'numeral';

export function getFieldValue(viewEl, field) {
    console.debug('getFieldValue');

    let value;
    const checkboxGroup = viewEl.querySelectorAll('[name="' + field.name + '"][type="checkbox"]');

    // If field type is checkbox
    if (field.type === 'checkbox') {
        if (checkboxGroup.length > 1) {
            value = [];
            checkboxGroup.forEach((element) => {
                if (element.checked) {
                    if (element.value === '') {
                        value.push(null);
                    } else if (typeof element.dataset.number !== 'undefined') {
                        value.push(numeral(element.value).value());
                    } else {
                        value.push(element.value);
                    }
                }
            });
        } else {
            if (field.checked) {
                const fieldValue = field.value;

                if (fieldValue) {
                    if (typeof field.dataset.number !== 'undefined') {
                        value = numeral(fieldValue).value();
                    } else {
                        value = fieldValue;
                    }
                } else {
                    value = true;
                }
            } else {
                const uncheckedValue = field.dataset.uncheckedValue;

                if (uncheckedValue) {
                    if (typeof field.dataset.number !== 'undefined') {
                        value = numeral(uncheckedValue).value();
                    } else {
                        value = uncheckedValue;
                    }
                } else {
                    value = false;
                }
            }
        }
    }

    // Else if field type is radio
    else if (field.type === 'radio') {
        if (field.checked) {
            if (field.value === '') {
                value = null;
            } else if (typeof field.dataset.number !== 'undefined') {
                value = numeral(field.value).value();
            } else {
                value = field.value;
            }
        } else {
            value = null;
        }
    }

    // Else
    else {
        if (field.value === '') {
            value = null;
        }
        else if (field.type === 'number' || typeof field.dataset.number !== 'undefined') {
            value = field.value.replace(/\D/g, '');

            if (field.value === '') {
                value = null;
            } else {
                value = numeral(field.value).value();
            }
        } else {
            value = field.value;
        }
    }

    return value;
}