import ActivitiesCollection from '@/js/app/activity/collections/activities';
import * as Formatter from '@/js/app/formatter';
import { getFinancialYear } from '@/js/app/formatter';
import ReportYtdResultModel from '@/js/app/report/ytd/models/result';
import Session from '@/js/app/session';
import { View } from 'backbone';
import ReportYtdGenerateView from '../../report/ytd/views/generate';
import template from '../templates/division.html';
import DashboardClientsActivityView from './clients-activity';
import DashboardSelfKpisView from './self-kpis';

export default class DashboardDivisionView extends View {

    preinitialize() {
        const ytdModel = new ReportYtdResultModel();

        // Create subviews
        this.subviews = {
            ytd: new ReportYtdGenerateView({
                financialYear: getFinancialYear(),
                divisionId: Session.data.division_id,
            }),
            selfKpi: new DashboardSelfKpisView({
                model: ytdModel,
            }),
            clientsActivity: new DashboardClientsActivityView({
                collection: new ActivitiesCollection(),
            }),
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('DashboardDivisionView#render');

        this.el.innerHTML = template({
            Formatter,
            Session
        });

        // YTD Chart
        this.subviews.ytd.setElement(this.el.querySelector('#ytdReport')).render();
        this.subviews.selfKpi.setElement(this.el.querySelector('#targets_widget'));
        this.subviews.clientsActivity.setElement(this.el.querySelector('#my_clients_widget'));

        // Fetch clients feed
        this.subviews.clientsActivity.collection.fetch({
            data: {
                clients_assigned_staff_id: Session.data.staff_id,
                type: [
                    'inquiry',
                    'property_alert_create',
                    'property_alert_delete',
                    'property_alert_open',
                    'property_alert_click',
                ],
                order: 'timestamp DESC',
                limit: 20,
                include: ['person'],
            },
            reset: true,
        });

        return this;
    }

}