module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card">\n    <div class="img-card-top">\n        <a class="lightbox" href="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'?w=1140&h=641&nc&ne&f=jpg" draggable="false">\n            <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'?w=245&h=184" class="img-fluid" loading="lazy" draggable="false" width="245" height="184">\n        </a>\n    </div>\n\n    <div class="card-body">\n        <select name="type" class="form-control" data-partial>\n            <option value=""></option>\n            <option value="cover-photo">Cover Photo</option>\n            <option value="floorplan">Floorplan</option>\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="building">Building</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'land') { ;
__p += '\n            <option value="land">Land</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house' || structure_type === 'unit') { ;
__p += '\n            <option value="balcony">Balcony</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n            <option value="bathroom">Bathroom</option>\n            <option value="bedroom">Bedroom</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="courtyard">Courtyard</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n            <option value="den">Den</option>\n            <option value="dining">Dining</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="entrance">Entrance</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building') { ;
__p += '\n            <option value="lobby">Lobby</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building') { ;
__p += '\n            <option value="lobby-library">Lobby Library</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="lounge">Lounge</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="garden">Garden</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n            <option value="hallway">Hallway</option>\n            <option value="kitchen">Kitchen</option>\n            <option value="living">Living</option>\n            <option value="living-dining">Living & Dining</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="garage">Garage</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="gym">Gym</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n            <option value="family">Family</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="fitness-room">Fitness Room</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house' || structure_type === 'unit') { ;
__p += '\n            <option value="view">View</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n            <option value="master-bedroom">Master Bedroom</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house' || structure_type === 'unit') { ;
__p += '\n            <option value="play-room">Play Room</option>\n            <option value="powder-room">Powder Room</option>\n            <option value="roof-top-garden">Roof Top Garden</option>\n            <option value="storage">Storage</option>\n            <option value="study">Study</option>\n            <option value="sauna">Sauna</option>\n            <option value="staircase">Staircase</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building') { ;
__p += '\n            <option value="parking-lobby">Parking Lobby</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="swimming-pool">Swimming Pool</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house') { ;
__p += '\n            <option value="trunk-room">Trunk Room</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building' || structure_type === 'house' || structure_type === 'unit') { ;
__p += '\n            <option value="utility-room">Utility Room</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'building') { ;
__p += '\n            <option value="building-experience">Building Experience</option>\n            ';
 } ;
__p += '\n            ';
 if (structure_type === 'unit' || structure_type === 'house') { ;
__p += '\n            <option value="model-room">Model Room</option>\n            ';
 } ;
__p += '\n            <option value="cg-imagery">CG Imagery</option>\n            ';
 if (structure_type === 'building' || structure_type === 'house' || structure_type === 'land') { ;
__p += '\n            <option value="local-area">Local Area</option>\n            ';
 } ;
__p += '\n            <option value="other">Other</option>\n        </select>\n\n        <div class="d-flex justify-content-end mt-2">\n            <button type="button" class="btn btn-link text-danger p-0" data-action="delete">Delete</button>\n        </div>\n    </div>\n</div>';

}
return __p
};
