import formToObject from '@/js/libs/form-utils';
import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import StaffCollection from '@/js/app/staff/collections/staff';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import CompanyCollection from '../collections/companies';
import CompanyCriteria from '../criterias/company';
import CompanyModel from '../models/company';
import template from '../templates/search.html';
import CompanyNewView from './new';
import CompanyResultsView from './results';

export default class CompanySearchView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'company';

        this.events = {
            'submit form': this.handleSubmit,
            'click [data-action="reset"]': this.handleReset,
            'click #btn-new_company': this.newCompany,
        };

        // Create criteria model
        this.criteria = new CompanyCriteria();

        this.staffCollection = new StaffCollection()

        this.companyCollection = new CompanyCollection();

        // Create subviews
        this.subviews = {
            assignedStaff: new UiIndividualSelectorView({
                id: 'field-assigned_staff',
                collection: this.staffCollection,
                attributes: {
                    name: 'assigned_staff_id',
                },
            }),
            companyResults: new CompanyResultsView({
                collection: this.companyCollection,
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');

        this.render();

        // Fetch staff
        this.staffCollection.fetch({
            data: {
                order: 'last_name',
            },
        });
    }

    render() {
        console.debug('CompanySearchView#render');

        // Render template
        this.el.innerHTML = template();

        // Attach assignedStaff subview to DOM
        this.$el.find('#cntr-assigned_staff').html(this.subviews.assignedStaff.el);

        // Attach companyResults subview to DOM
        this.subviews.companyResults.setElement(this.el.querySelector('#results'));

        // Get search criteria from sessionStorage
        this.companySearchCriteria = JSON.parse(sessionStorage.getItem(
            'companySearchCriteria'
        ));

        // If search conditions exists
        if (this.companySearchCriteria) {
            const { assigned_staff_id } = this.companySearchCriteria;
            populateForm(this.el.querySelector('#frm-company-search'), this.companySearchCriteria);
            this.subviews.assignedStaff.setSelected(assigned_staff_id);
        }

        return this;
    }

    handleSubmit($e) {
        console.debug('CompanySearchView#handleSubmit');

        $e.preventDefault();

        // Get defaults, and merge with criteria from form
        const criteria = this.criteria.defaults();

        const form = this.el.querySelector('#frm-company-search');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        sessionStorage.setItem('companySearchCriteria', JSON.stringify(criteria));

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('form').reset();

        sessionStorage.removeItem('companySearchCriteria');
    }

    newCompany() {
        console.debug('CompanySearchView#newCompany');

        new CompanyNewView({
            model: new CompanyModel()
        }).render();
    }
}