module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="actionsBar mb-3">\n    <a href="#deals/new_sales" title="New Sales Deal" class="btn btn-success btn-sm"><i class="fa fa-plus" aria-hidden="true"></i> Sales Deal</a>\n    <a href="#deals/new_leasing" title="New Leasing Deal" class="btn btn-success btn-sm"><i class="fa fa-plus" aria-hidden="true"></i> Leasing Deal</a>\n    <!--<button type="button" title="Refresh" class="btn btn-sm pull-right" data-action="refresh"><i class="fa fa-refresh" aria-hidden="true"></i></button>-->\n</div>\n\n<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Type</th>\n            <th>Contract Date</th>\n            <th>Property</th>\n            <th>Created Date</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 deals.forEach((deal) => { ;
__p += '\n            <tr>\n                <td><a href="#deals/edit/' +
((__t = ( deal.id )) == null ? '' : __t) +
'" title="Edit">' +
((__t = ( deal.id )) == null ? '' : __t) +
'</a></td>\n                <td>' +
((__t = ( Formatter.dealType(deal.type) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( deal.取引の概要_契約成立年月日 ? deal.取引の概要_契約成立年月日 : deal.賃貸条件_契約期間_start )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( deal.物件名 )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( deal.created_at )) == null ? '' : __t) +
'</td>\n                <td>\n                    <a href="#deals/edit/' +
((__t = ( deal.id )) == null ? '' : __t) +
'" title="Edit" class="btn btn-secondary btn-sm">Edit</a>\n                    ';
 if (deal.accounting_approved === 1) { ;
__p += '\n                    <span class="badge badge-success">Approved (Accounting)</span>\n                    ';
 } else if (deal.division_approved === 1) { ;
__p += '\n                    <span class="badge badge-primary">Approved (Division)</span>\n                    ';
 } ;
__p += '\n                </td>\n            </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
