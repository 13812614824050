module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-check" data-part="sales">\n    <input id="field-edm-sales" class="form-check-input" type="checkbox" name="sales" value="1">\n    <label for="field-edm-sales" class="form-check-label">Sales</label>\n    <span class="form-text" data-part="unsubscribed" style="display: none"\n        title="User has unsubscribed from email updates">\n        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>\n    </span>\n</div>\n\n';
 /*
<div class="form-check" data-part="alt">
    <input id="field-edm-alt" class="form-check-input" type="checkbox" name="alt" value="1">
    <label for="field-edm-alt" class="form-check-label">Ken's Place</label>
    <span class="form-text" data-part="unsubscribed" style="display: none"
        title="User has unsubscribed from email updates">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </span>
</div>

<div class="form-check" data-part="alt-corporate">
    <input id="field-edm-alt-corporate" class="form-check-input" type="checkbox" name="alt-corporate" value="1">
    <label for="field-edm-alt-corporate" class="form-check-label">Ken's Place - Corporate</label>
    <span class="form-text" data-part="unsubscribed" style="display: none"
        title="User has unsubscribed from email updates">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </span>
</div>

<div class="form-check" data-part="alt-japanese">
    <input id="field-edm-alt-japanese" class="form-check-input" type="checkbox" name="alt-japanese" value="1">
    <label for="field-edm-alt-japanese" class="form-check-label">Ken's Place - Japanese</label>
    <span class="form-text" data-part="unsubscribed" style="display: none"
        title="User has unsubscribed from email updates">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </span>
</div>

<div class="form-check" data-part="alt-relocation">
    <input id="field-edm-alt-relocation" class="form-check-input" type="checkbox" name="alt-relocation" value="1">
    <label for="field-edm-alt-relocation" class="form-check-label">Ken's Place - Relocation</label>
    <span class="form-text" data-part="unsubscribed" style="display: none"
        title="User has unsubscribed from email updates">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </span>
</div>
*/ ;
__p += '\n\n<div class="form-check" data-part="resorts">\n    <input id="field-edm-resorts" class="form-check-input" type="checkbox" name="resorts" value="1">\n    <label for="field-edm-resorts" class="form-check-label">Resorts</label>\n    <span class="form-text" data-part="unsubscribed" style="display: none"\n        title="User has unsubscribed from email updates">\n        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>\n    </span>\n</div>\n\n<div class="form-check" data-part="invest-sales">\n    <input id="field-edm-invest-sales" class="form-check-input" type="checkbox" name="invest-sales" value="1">\n    <label for="field-edm-invest-sales" class="form-check-label">Investment Sales</label>\n    <span class="form-text" data-part="unsubscribed" style="display: none"\n        title="User has unsubscribed from email updates">\n        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>\n    </span>\n</div>\n\n<div class="form-check" data-part="japanese">\n    <input id="field-edm-japanese" class="form-check-input" type="checkbox" name="japanese" value="1">\n    <label for="field-edm-japanese" class="form-check-label">Japanese</label>\n    <span class="form-text" data-part="unsubscribed" style="display: none"\n        title="User has unsubscribed from email updates">\n        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>\n    </span>\n</div>';

}
return __p
};
