module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td class="align-middle">\n    <a href="#deals/edit/' +
((__t = ( result.id )) == null ? '' : __t) +
'">' +
((__t = ( result.id )) == null ? '' : __t) +
'</a>\n</td>\n<td class="align-middle">\n    <a href="#people/edit/' +
((__t = ( result.借主_入居者_id )) == null ? '' : __t) +
'">' +
((__t = ( result.借主_入居者 )) == null ? '' : __t) +
'</a>\n    <br>\n    ';
 if (result.借主_本人_type === 'company') { ;
__p += '\n    <a href="#company/edit/' +
((__t = ( result.借主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( result.借主_本人 )) == null ? '' : __t) +
'</a>\n    ';
 } ;
__p += '\n</td>\n<td class="align-middle">\n    ' +
((__t = ( result.物件名 )) == null ? '' : __t) +
'\n    ';
 if (result.物件名_部屋番号) { ;
__p += '\n    - ' +
((__t = ( result.物件名_部屋番号 )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</td>\n<td class="align-middle">' +
((__t = ( result.物件所在地_部屋番号 )) == null ? '' : __t) +
'</td>\n<td class="align-middle text-right text-nowrap">' +
((__t = ( numeral(result.賃貸条件_賃料_月額).format('0,0') )) == null ? '' : __t) +
'円</td>\n<td class="align-middle text-nowrap">' +
((__t = ( result.賃貸条件_契約期間_end )) == null ? '' : __t) +
'</td>\n<td class="align-middle">\n    <div class="d-flex">\n        <button type="button" class="btn btn-success btn-sm mr-1" data-action="renew">\n            Renew\n        </button>\n        <button type="button" class="btn btn-danger btn-sm mr-1" data-action="cancel">\n            Cancel\n        </button>\n        <button type="button" class="btn btn-warning btn-sm mr-1" data-action="expire">\n            Expire\n        </button>\n        <div class="spinner-border d-none" role="status">\n            <span class="sr-only">Loading...</span>\n        </div>\n    </div>\n</td>';

}
return __p
};
