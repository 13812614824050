module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


let amountCol = '';
switch (query.property_type) {
    case 'rent':
        amountCol = 'rent';
        break;

    case 'sales':
        amountCol = 'price';
        break;

    case 'monthly':
        amountCol = 'monthly_rate';
        break;

    case 'weekly':
        amountCol = 'weekly_rate';
        break;
}
;
__p += '\n';
 if (_.isArray(properties) && properties.length > 0) { ;
__p += '\n<div class="card-body">\n    ' + require("./search_results_actions.html").apply(null,arguments) + ';\n\n    ' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n</div>\n\n<table class="table table-sm table-striped bulkedit">\n    <thead>\n        <tr>\n            <th><input type="checkbox" disabled></th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('id') )) == null ? '' : __t) +
'" data-sort-column="id">ID ' +
((__t = ( Formatter.sc(query, 'id') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('updated_at') )) == null ? '' : __t) +
'" data-sort-column="updated_at">Updated ' +
((__t = ( Formatter.sc(query, 'updated_at') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('structure_type') )) == null ? '' : __t) +
'" data-sort-column="structure_type">T ' +
((__t = ( Formatter.sc(query, 'structure_type') )) == null ? '' : __t) +
'</th>\n            <th></th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('neighborhood') )) == null ? '' : __t) +
'" data-sort-column="neighborhood">Area ' +
((__t = ( Formatter.sc(query, 'neighborhood') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('structure_name_en') )) == null ? '' : __t) +
'" data-sort-column="structure_name_en">Name ' +
((__t = ( Formatter.sc(query, 'structure_name_en') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('floor_no') )) == null ? '' : __t) +
'" data-sort-column="floor_no">Floor ' +
((__t = ( Formatter.sc(query, 'floor_no') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('room_no') )) == null ? '' : __t) +
'" data-sort-column="room_no">Unit ' +
((__t = ( Formatter.sc(query, 'room_no') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd(amountCol) )) == null ? '' : __t) +
'" data-sort-column="' +
((__t = ( amountCol )) == null ? '' : __t) +
'">Amount ' +
((__t = ( Formatter.sc(query, amountCol) )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('bedroom_no') )) == null ? '' : __t) +
'" data-sort-column="bedroom_no">Bed ' +
((__t = ( Formatter.sc(query, 'bedroom_no') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('floorplan_type') )) == null ? '' : __t) +
'" data-sort-column="floorplan_type" title="Floorplan">間取り ' +
((__t = ( Formatter.sc(query, 'floorplan_type') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('bedroom_no') )) == null ? '' : __t) +
'" data-sort-column="bedroom_no">Bath ' +
((__t = ( Formatter.sc(query, 'bathroom_no') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('size') )) == null ? '' : __t) +
'" data-sort-column="size">Size ' +
((__t = ( Formatter.sc(query, 'size') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('pets') )) == null ? '' : __t) +
'" data-sort-column="pets">Pets ' +
((__t = ( Formatter.sc(query, 'pets') )) == null ? '' : __t) +
'</th>\n            <th>Agent</th>\n            <th>PM/Owner</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('availability_status') )) == null ? '' : __t) +
'" data-sort-column="availability_status">Status ' +
((__t = ( Formatter.sc(query, 'availability_status') )) == null ? '' : __t) +
'</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('published') )) == null ? '' : __t) +
'" data-sort-column="published" title="Published (EN)">Pub (EN)</th>\n            <th class="header" data-sort-direction="' +
((__t = ( Formatter.sd('published_ja') )) == null ? '' : __t) +
'" data-sort-column="published_ja" title="Published (JA)">Pub (JA)</th>\n        </tr>\n    </thead>\n    <tbody>\n    ';
 properties.forEach((p) => { ;
__p += '\n        <tr data-id="' +
((__t = ( p.id )) == null ? '' : __t) +
'" class="' +
((__t = ( 1 == p.published ? '' : 'unpublished' )) == null ? '' : __t) +
'">\n            <td class="property_selection"><input type="checkbox" disabled></td>\n            <td><a href="' +
((__t = ( Formatter.property_edit_url(p) )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.propertyId(p, query) )) == null ? '' : __t) +
'</a></td>\n            <td class="timestamp">' +
((__t = ( Formatter.timestamp(p.updated_at, true, 'date') )) == null ? '' : __t) +
'</td>\n            <td><img src="/images/icons/16x16/' +
((__t = ( p.structure_type )) == null ? '' : __t) +
'.png" alt="' +
((__t = ( p.structure_type )) == null ? '' : __t) +
'" title="' +
((__t = ( p.structure_type )) == null ? '' : __t) +
'" /></td>\n            <td>\n                ' +
((__t = ( Formatter.hasFloorplan(p) ? '<img src="/images/icons/16x16/blueprint-horizontal.png" alt="This property has a floorplan" title="This property has a floorplan" width="16" height="16">' : '' )) == null ? '' : __t) +
'\n                <br>\n                ' +
((__t = ( Formatter.hasImages(p) ? '<img src="/images/icons/16x16/pictures.png" alt="This property has photos" title="This property has photos" width="16" height="16">' : '' )) == null ? '' : __t) +
'\n            </td>\n            <td>' +
((__t = ( p.structure.location.city )) == null ? '' : __t) +
'<br>' +
((__t = ( p.structure.location.neighborhood )) == null ? '' : __t) +
'</td>\n            <td>\n                <a href="' +
((__t = ( Formatter.building_edit_url(p) )) == null ? '' : __t) +
'">' +
((__t = ( p.structure.structure_name_en || '' )) == null ? '' : __t) +
'</a>\n            </td>\n            <td>' +
((__t = ( p.floor_no )) == null ? '' : __t) +
'</td>\n            <td><a href="' +
((__t = ( Formatter.property_edit_url(p) )) == null ? '' : __t) +
'">' +
((__t = ( p.room_no )) == null ? '' : __t) +
'</a></td>\n            ';

            let ptype;

            if (!((p.price != null && p.price > -1) && (p.price != null && p.rent > -1))) {
                if (p.price != null && p.price > -1) {
                    ptype = 'price';
                }
                else if (p.rent != null && p.rent > -1) {
                    ptype = 'rent';
                }
            }
            else if (query.property_type) {
                ptype = 'rent' === query.property_type ? 'rent' : 'price';
            }
            ;
__p += '\n            <td>\n                ';
 if (ptype) { ;
__p += '\n                    ' +
((__t = ( ptype.capitalize() )) == null ? '' : __t) +
': <input class="form-control spread-wide" name="' +
((__t = ( ptype )) == null ? '' : __t) +
'" type="text" data-numeral="0,0" pattern="[0-9]+([,0-9]+)?">\n                ';
 } else { ;
__p += '\n                    Rent: <input class="form-control spread-wide" name="rent" type="text" data-numeral="0,0" pattern="[0-9]+([,0-9]+)?">\n                    <br>\n                    Price: <input class="form-control spread-wide" name="price" type="text" data-numeral="0,0" pattern="[0-9]+([,0-9]+)?">\n                ';
 } ;
__p += '\n\n                ';
 if ('rent' == query.property_type) { ;
__p += '\n                    <br>D' +
((__t = ( p.deposit_by_months )) == null ? '' : __t) +
' K' +
((__t = ( p.key_money_by_months )) == null ? '' : __t) +
'\n                    ' +
((__t = ( p.management_fee > 0 ? '<br>M' + numeral(p.management_fee).format('0,0') : '' )) == null ? '' : __t) +
'\n                ';
 } else if ('rent' == ptype) { ;
__p += '\n                    <br>Bottom Rent: <input class="form-control spread-wide" name="bottom" type="text" data-numeral="0,0" pattern="[0-9]+([,0-9]+)?">\n                ';
 } ;
__p += '\n            </td>\n            <td>' +
((__t = ( 0 == p.bedroom_no ? 'S' : Formatter.bedrooms(p, true) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( p.floorplan_type || '' )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( p.bathroom_no )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( p.size )) == null ? '' : __t) +
'</td>\n            <td>\n                <select class="form-control spread" name="pets">\n                    <option value=""></option>\n                    <option value="yes">OK</option>\n                    <option value="large">Large</option>\n                    <option value="small">Small</option>\n                    <option value="negotiable">Neg</option>\n                    <option value="no">No</option>\n                </select>\n            </td>\n            <td>\n                ';

                const agents = _.filter([p.agent_company_1, p.agent_company_2, p.agent_company_3], function(a) {
                    return !!a;
                });
                let _c = 0;
                agents.forEach((a) => {
                    const tel = Formatter.getCompanyTel(a);
                    _c++;
                ;
__p += '\n                    ' +
((__t = ( Formatter.getCompanyName(a) )) == null ? '' : __t) +
'\n                    ' +
((__t = ( tel ? '(' + tel + ')' : '' )) == null ? '' : __t) +
'\n                    ' +
((__t = ( _c < agents.length ? ', ' : '' )) == null ? '' : __t) +
'\n                ';
 }); ;
__p += '\n            </td>\n            <td>\n                ';

                if (p.pm_company) {
                    const tel = Formatter.getCompanyTel(p.pm_company);
                    ;
__p += '\n                    ' +
((__t = ( Formatter.getCompanyName(p.pm_company) )) == null ? '' : __t) +
'\n                    ' +
((__t = ( tel ? '(' + tel + ')' : '' )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                <select class="form-control spread" name="availability_status">\n                    <option value="tba">TBA</option>\n                    <option value="available">Avail.</option>\n                    <option value="unavailable">Unavailable</option>\n                    <option value="app_1" disabled>App 1</option>\n                    <option value="app_2" disabled>App 2</option>\n                    <option value="app_3" disabled>App 3</option>\n                </select>\n                ' +
((__t = ( 'unavailable' !== p.availability_status ? '<br>' + Formatter.datestamp(p.available_on, 'human') : '' )) == null ? '' : __t) +
'\n            </td>\n            <td><input type="checkbox" name="published" value="1"></td>\n            <td><input type="checkbox" name="published_ja" value="1"></td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n<div class="card-body">\n    ' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n\n    ' + require("./search_results_actions.html").apply(null,arguments) + '\n</div>\n';
 } else { ;
__p += '\n    <div class="card-body text-center">\n        No units found\n    </div>\n';
 } ;
__p += '\n';

}
return __p
};
