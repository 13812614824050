import { Model } from 'backbone';
import config from '@/js/app/config';

export default Model.extend({

    idAttribute: 'filename',

    urlRoot: function () {
        return config.api.url + 'buildings/' + this.building_id + '/files';
    },

    initialize: function (attrs, options) {
        // Set building ID attribute in model
        this.building_id = options.building_id || options.collection.building_id || null;
    },

    defaults: function () {
        return {
            building_id: null,
            filename: null,
            mime_type: null,
            size: null,
            content_md5: null,
            last_modified_at: null,
            last_modified_by: null,
        };
    },
});