module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


function contact_type(c) {
    const types = {
        'is_real_estate_vendor': 'Real-estate Vendor',
        'is_corporate_client': 'Corporate Client',
        'is_insurer': 'Insurer Company',
        'is_financier': 'Financier Company',
    };

    let list = '';

    _.each(types, function(type, key) {
        list += 1 == c[key] ? type + ', ' : '';
    });

    return list.substring(0, list.length - 2);
}
;
__p += '\n<h2 class="h3">Search Results</h2>\n\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Type</th>\n            <th>Name</th>\n            <th>Phone</th>\n            <th>Assigned to</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 companies.forEach((c) => { ;
__p += '\n        <tr data-id="' +
((__t = ( c.id )) == null ? '' : __t) +
'">\n            <td><a href="#company/edit/' +
((__t = ( c.id )) == null ? '' : __t) +
'">' +
((__t = ( c.id )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( contact_type(c) )) == null ? '' : __t) +
'</td>\n            <td><a href="#company/edit/' +
((__t = ( c.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getCompanyName(c) )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( _.compact([c.telephone, c.rent_telephone, c.sales_telephone]).join(' / ') )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (c.assigned_staff) { ;
__p += '\n                ' +
((__t = ( c.assigned_staff_id == Session.data.staff_id ? 'Me' : Formatter.getName(c.assigned_staff) )) == null ? '' : __t) +
'\n                ';
 } else { ;
__p += '\n                <span class="badge badge-secondary">Unassigned</span>\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '';

}
return __p
};
