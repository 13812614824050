import { View } from 'backbone';
import Report3nosukeResultsCollection from '../collection/results';
import Report3nosukeResultsView from './results';
import template from '../templates/index.html';
import oauth2Client from '@/js/app/oauth2-client';
import config from '@/js/app/config';

export default class Report3nosukeIndexView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'reports';

        this.events = {
            'click [data-action="export"]': this.handleClickExport,
        };

        this.resultsCollection = new Report3nosukeResultsCollection;

        // Create subviews
        this.subviews = {
            resultsView: new Report3nosukeResultsView({
                collection: this.resultsCollection,
            }),
        };
    }

    initialize() {
        this.render();

        this.cntrAlert = this.el.querySelector('.alert');

        // Initiate fetch on results collection
        this.resultsCollection.fetch({
            data: {
                property_type: 'rent',
                published_ja: 1,
                availability_status: '!unavailable',
                include: ['structure', 'location', 'station'],
                language: 'ja',
            },
        });
    }

    render() {
        console.debug(Report3nosukeIndexView.name + '#render');

        // Render template
        this.el.innerHTML = template();

        // Bind results view and render
        this.subviews.resultsView.setElement(this.el.querySelector('#results')).render();

        return this;
    }

    handleClickExport() {
        this.send();
    }

    hideAlert() {
        this.cntrAlert.classList.add('d-none');
    }

    displaySuccessAlert() {
        this.cntrAlert.innerHTML = 'Success!';
        this.cntrAlert.classList.remove('alert-danger');
        this.cntrAlert.classList.add('alert-success');
        this.cntrAlert.classList.remove('d-none');
    }

    displayErrorAlert(message) {
        this.cntrAlert.innerHTML = `Error: ${message}`;
        this.cntrAlert.classList.remove('alert-success');
        this.cntrAlert.classList.add('alert-danger');
        this.cntrAlert.classList.remove('d-none');
    }

    async send() {
        this.hideAlert();

        try {
            // Send POST request to trigger 3nosuke export
            await oauth2Client.fetchJSON(config.api.url + 'export/3nosuke', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
            });

            this.displaySuccessAlert();
        } catch (error) {
            console.error(error);

            // Default to error.message
            let errorMessage = error.message;

            // If error contains exception; use message of first exception
            if (error.exception) {
                errorMessage = error.message;
            }

            this.displayErrorAlert(errorMessage);
        }
    }
}