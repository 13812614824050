module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 model.kpis.forEach(kpi => { ;
__p += '\n<tr data-year="' +
((__t = ( kpi.year )) == null ? '' : __t) +
'" data-month="' +
((__t = ( kpi.month )) == null ? '' : __t) +
'">\n    <th scope="row" class="align-middle" style="width: 180px;">\n        ' +
((__t = ( moment().year(kpi.year).month(kpi.month - 1).format('MMM YYYY') )) == null ? '' : __t) +
'\n    </th>\n    <td data-slot="division"></td>\n    <td style="width: 170px;">\n        <div class="input-group">\n            <div class="input-group-prepend">\n                <span class="input-group-text">&yen;</span>\n            </div>\n            <input type="text" class="form-control text-right" name="revenue" value="' +
((__t = ( kpi.revenue )) == null ? '' : __t) +
'" pattern="[0-9]+([,0-9]+)?">\n        </div>\n    </td>\n</tr>\n';
 }) ;


}
return __p
};
