module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="actionsBar mb-3">\n';
 if (propertyType === 'sales') { ;
__p += '\n    <button type="button" title="New Offer" class="btn btn-success btn-sm" data-action="new"><i class="fa fa-plus" aria-hidden="true"></i> Offer</button>\n';
 } else if (propertyType === 'rent') { ;
__p += '\n    <button type="button" title="New Application" class="btn btn-success btn-sm" data-action="new"><i class="fa fa-plus" aria-hidden="true"></i> Application</button>\n';
 } ;
__p += '\n    <!--<button type="button" title="Refresh" class="btn btn-sm pull-right" data-action="refresh"><i class="fa fa-refresh" aria-hidden="true"></i></button>-->\n</div>\n\n<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Type</th>\n            <th>Property</th>\n            <th>Created Date</th>\n            <th>Assigned</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 applications.forEach((a) => { ;
__p += '\n            <tr>\n                <td>' +
((__t = ( a.id )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.dealType(a.type) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( a.物件名 )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( a.created_at )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( _.isObject(a.assignee) ? Formatter.getName(a.assignee) : '' )) == null ? '' : __t) +
'</td>\n                <td>\n                ';
 if (a.status === 'approved') { ;
__p += '\n                    <span class="badge badge-success">Accepted</span>\n                ';
 } else if (a.status === 'denied') { ;
__p += '\n                    <span class="badge badge-danger">Denied</span>\n                ';
 } else { ;
__p += '\n                    <button type="button" title="Edit" class="btn btn-secondary btn-sm" data-action="edit" data-id="' +
((__t = ( a.id )) == null ? '' : __t) +
'">Edit</button>\n                    <button type="button" title="Approve" class="btn btn-success btn-sm" data-action="approve" data-id="' +
((__t = ( a.id )) == null ? '' : __t) +
'"><i class="fa fa-check"></i></button>\n                    <button type="button" title="Deny" class="btn btn-danger btn-sm" data-action="deny" data-id="' +
((__t = ( a.id )) == null ? '' : __t) +
'"><i class="fa fa-times"></i></button>\n                ';
 } ;
__p += '\n                </td>\n            </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n<div id="edit_application"></div>';

}
return __p
};
