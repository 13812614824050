module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Structure Name</th>\n            <th>Unit</th>\n            <th>Rent</th>\n            <th>Deposit</th>\n            <th>Key Money</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 results.forEach((result) => { ;
__p += '\n        <tr>\n            <td><a href="' +
((__t = ( Formatter.property_edit_url(result) )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.propertyId(result) )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( result.structure.structure_name_ja )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( result.room_no )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( Formatter.currency() + Formatter.price(result) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( Formatter.monthsText(result.deposit_by_months) )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( Formatter.monthsText(result.key_money_by_months) )) == null ? '' : __t) +
'</td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
