import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import PeopleCollection from '@/js/app/people/collections/people';
import Session from '@/js/app/session';
import StaffCollection from '@/js/app/staff/collections/staff';
import * as TextHelper from '@/js/app/text-helper';
import UiIndividualSelectorView from '@/js/app/ui/views/individual-selector';
import populateForm from '@/js/libs/populate-form';
import { getFieldValue } from '@/js/libs/view-utils';
import { history, View } from 'backbone';
import CompanyModel from '../models/company';
import template from '../templates/edit.html';
import CompanyPeopleView from './people';

export default class CompanyEditView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'company_edit';

        this.events = {
            'change form input,form select,form textarea': this.updateModel,
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="changelog"]': this.changelog,
        };

        this.model = new CompanyModel({
            id: options.companyId,
        });

        this.companyPeopleCollection = new PeopleCollection();

        this.subviews = {
            assignedStaff: new UiIndividualSelectorView({
                id: 'field-assigned_staff',
                collection: new StaffCollection(),
                attributes: {
                    name: 'assigned_staff_id',
                },
                triggerChange: false,
            }),
            contactPoint: new UiIndividualSelectorView({
                id: 'field-contact_point',
                collection: this.companyPeopleCollection,
                attributes: {
                    name: 'contact_point_id',
                },
                selected: this.model.get('contact_point_id'),
            }),
            companyPeopleView: new CompanyPeopleView({
                collection: this.companyPeopleCollection,
            }),
        };
    }

    initialize() {
        this.render();

        this.listenTo(this.model, 'change:company_name', this.handleCompanyNameChange);
        this.listenTo(this.model, 'change:is_real_estate_vendor', this.toggleTelFields);
        this.listenTo(this.model, 'change:assigned_staff_id', this.handleAssignedStaffIdChange);
        this.listenTo(this.model, 'change:assigned_staff', this.handleAssignedStaffChange);
        this.listenTo(this.model, 'change', this.updateOutlets);

        // Trigger fetch
        this.model.fetch({
            data: {
                include: ['assigned_staff', 'contact_point'],
            },
        });

        // Fetch staff
        this.subviews.assignedStaff.collection.fetch({
            data: {
                active: 1,
                order: 'last_name',
            },
            merge: false,
            remove: false,
        });

        // Fetch people collection
        this.companyPeopleCollection.fetch({
            data: {
                company_id: this.model.id,
                order: 'last_name',
                include: ['assigned_staff'],
            },
        });
    }

    render() {
        console.debug('CompanyEditView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            country: TextHelper._source.country,
            isDeleteAllowed: Session.isAllowed('phnx:people:d')
        });

        // Setup assigned staff field
        this.$el.find('#cntr-assigned_staff').html(this.subviews.assignedStaff.el);

        // Setup contact point field
        this.$el.find('#cntr-contact_point').html(this.subviews.contactPoint.el);

        // Attach company people view to DOM
        this.subviews.companyPeopleView.setElement(this.el.querySelector('#results'));

        return this;
    }

    handleCompanyNameChange(model, value, options) {
        console.debug('CompanyEditView#handleCompanyNameChange');

        if (!options.initialLoad) {
            this.changeCompanyName(value);
        }
    }

    changeCompanyName(value) {
        console.debug('CompanyEditView#changeCompanyName');

        this.el.querySelector('[data-outlet="company_name"]').innerText = value;
    }

    handleAssignedStaffIdChange(model, value) {
        console.debug('CompanyEditView#handleAssignedStaffIdChange');

        this.subviews.assignedStaff.setSelected(value);
    }

    handleAssignedStaffChange(model, value) {
        console.debug('CompanyEditView#handleAssignedStaffChange');

        this.subviews.assignedStaff.collection.add(value);
    }

    toggleTelFields(model) {
        console.debug('CompanyEditView#toggleTelFields');

        if (model.get('is_real_estate_vendor')) {
            $('[data-is-real-estate-vendor]').show();
        } else {
            $('[data-is-real-estate-vendor]').hide();
        }
    }

    updateModel($e) {
        const field = $e.currentTarget;

        // If no name, return
        if (field.name === '') {
            return;
        }

        const value = getFieldValue(this.el, field);

        // Save new value
        this.model.save(field.name, value, {
            patch: true,
            wait: true
        });
    }

    updateOutlets(model) {
        console.debug('CompanyEditView#updateOutlets');

        // Populate form with changed attributes
        populateForm(this.el, model.changedAttributes());
    }

    delete() {
        console.debug('CompanyEditView#delete');

        if (confirm('Are you sure you want to delete this company? This cannot be undone!')) {
            this.model.destroy({
                success: function () {
                    history.navigate('company/search', { trigger: true });
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    changelog() {
        console.debug('CompanyEditView#changelog');

        // Create modal view, with collection
        const changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        changelogView.collection.fetch({
            data: {
                resource_type: 'company',
                resource_id: this.model.get('id'),
            },
        });
    }
}