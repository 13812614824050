import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import * as TextHelper from '@/js/app/text-helper';
import template from '../templates/edit_automatch.html';

export default class PropertyAutomatchClientsView extends View {
    preinitialize() {
        this.events = {
            'click [data-action="export"]': this.export
        };
    }

    initialize() {
        // When collection updates; render
        this.listenTo(this.collection, 'update', this.render);
    }

    render() {
        console.debug('PropertyAutomatchClientsView#render');

        this.el.innerHTML = template({
            people: this.collection.toJSON(),
            TextHelper,
            Formatter,
            Session,
        });

        return this;
    }

    export() {
        console.debug('PropertyAutomatchClientsView#export');

        OAuth2Client.csvDownload(_.result(this.collection, 'url'), {}, {
            filename: 'automatch-clients-' + moment().format('YYYYMMDD-HHmmss') + '.csv',
        });
    }
}