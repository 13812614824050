import { View } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';
import Spinner from '@/js/app/ui/views/spinner';
import template from '../templates/location-picker.html';

export default class PropertyLocationPickerView extends View {
    preinitialize() {
        this.events = {
            'click a[data-action="uncheck"]': this.uncheckAll,
            'click #filterlocation li': this.toggleLocationType,
            'click [data-group]': this.toggleGroup,
            'click [data-child]': this.toggleChild,
        };
    }

    initialize() {
        this.locationType = 'custom_areas';
        this.selectedLocations = {};
        this.locationData = {};
    }

    render() {
        this.el.innerHTML = template();
        this.renderCheckboxes();
    }

    renderCheckboxes() {
        const s = new Spinner();
        s.spin(this.el.querySelector('.locations_picker'));

        const requestURL = new URL(config.api.url + 'immutable_data');
        requestURL.search = jQuery.param({
            data_type: this.locationType,
            language: 'ja',
            prefecture_id: [13, 14],
        });

        OAuth2Client.fetchJSON(requestURL, {})
            .then((data) => {
                this.locationData = data;

                const fragment = document
                    .createDocumentFragment()
                    .appendChild(document.createElement('ul'));
                const group_item = document.createElement('li');
                group_item.dataset.group = '';
                const child_item = document.createElement('li');
                child_item.dataset.child = '';
                const checkbox = document.createElement('input');
                checkbox.type = 'checkbox';

                // Loop through each location group
                _.each(this.locationData, (group_locations, group_name) => {
                    const group = group_item.cloneNode(true);
                    const group_checkbox = checkbox.cloneNode(true);

                    group_checkbox.value = group_name;

                    group.appendChild(group_checkbox);
                    group.appendChild(document.createTextNode(group_name));

                    const children = document.createElement('ul');

                    // Loop through each location in location group
                    _.each(group_locations, (child_data, child_name) => {
                        if ('__en' !== child_name) {
                            const child = child_item.cloneNode(true);
                            const child_checkbox = checkbox.cloneNode(true);

                            // Set checkbox value to neighborhood name (for now...)
                            child_checkbox.value = child_name;

                            // Append checkbox to child
                            child.appendChild(child_checkbox);

                            // Append text label to child
                            child.appendChild(
                                document.createTextNode(child_name)
                            );

                            // Append child to children
                            children.appendChild(child);
                        }
                    });

                    group.appendChild(children);
                    fragment.appendChild(group);
                });

                this.$el.find('.locations_picker').html(fragment);

                s.stop();
            })
            .catch(() => {
                s.stop();
            });
    }

    uncheckAll($e) {
        $e.preventDefault();

        this.$el.find('.locations_picker li').removeClass('selected');
        this.$el.find('.locations_picker input:checked').prop('checked', false);
        this.selectedLocations = {};
    }

    toggleLocationType($e) {
        $e.preventDefault();

        this.$el.find('#filterlocation .active').removeClass('active');
        this.locationType = $e.currentTarget.dataset.type;
        this.renderCheckboxes();
        $e.currentTarget.classList.add('active');
    }

    toggleGroup($e) {
        $e.stopPropagation();

        const item =
            'undefined' === typeof $e.currentTarget ? $e : $e.currentTarget;
        const isCheckbox =
            'undefined' === typeof $e.target
                ? false
                : 'INPUT' === $e.target.nodeName;

        // If checkbox triggered event
        if (isCheckbox) {
            const group_name = item.firstChild.value;

            // If checkbox selected; uncheck
            if ($(item).hasClass('selected')) {
                $(item).removeClass('selected');
                item.firstChild.checked = false;

                $('input', item).prop('checked', false);
                $('li', item).removeClass('selected');

                // Remove entire group from "selected" list
                delete this.selectedLocations[group_name];
            }
            // Else not selected; check
            else {
                $(item).addClass('selected').addClass('expanded');
                item.firstChild.checked = true;
                $('input', item).prop('checked', true);
                $('li', item).addClass('selected');

                // Add group and all children to "selected" list
                const children = {};
                _.each(
                    this.locationData[group_name],
                    function (child_data, child_name) {
                        children[child_name] = child_data.id;
                    }
                );
                this.selectedLocations[group_name] = children;
            }
        }
        // Else list item triggered event
        else {
            // Expand/collapse group
            if ($(item).hasClass('expanded')) {
                $(item).removeClass('expanded');
            } else {
                $(item).addClass('expanded');
            }
        }
    }

    toggleChild(event) {
        event.stopPropagation();

        const item =
            'undefined' === typeof event.currentTarget
                ? event
                : event.currentTarget;

        // If child selected; unselect and uncheck
        if ($(item).hasClass('selected')) {
            $(item).removeClass('selected');
            item.firstChild.checked = false;
        }
        // Else child not selected; select and check
        else {
            $(item).addClass('selected');
            item.firstChild.checked = true;
        }

        // If all children checked; check parent
        if (
            item.parentNode.children.length ==
            $('input:checked', item.parentNode).length
        ) {
            item.parentNode.parentNode.firstChild.checked = true;
            $(item.parentNode.parentNode).addClass('selected');
        }
        // Else not all children checked; uncheck parent
        else {
            item.parentNode.parentNode.firstChild.checked = false;
            $(item.parentNode.parentNode).removeClass('selected');
        }

        const group_name = item.parentNode.parentNode.firstChild.value;
        const child_name = item.firstChild.value;

        if (item.firstChild.checked) {
            // If group not in "selected" list; add group
            if ('undefined' == typeof this.selectedLocations[group_name]) {
                this.selectedLocations[group_name] = {};
            }
            // Add child to group in "selected" list
            this.selectedLocations[group_name][child_name] =
                this.locationData[group_name][child_name].id;
        } else {
            // Remove child from group in "selected" list
            delete this.selectedLocations[group_name][child_name];
        }

        // If no more children in group checked; remove group from "selected" list
        if (0 === $('input:checked', item.parentNode).length) {
            delete this.selectedLocations[group_name];
        }
    }

    getWardsNeighborhoods() {
        const cities = [];
        const neighborhoods = [];

        _.each(this.selectedLocations, function (group_locations, group_name) {
            cities.push(group_name);

            _.each(group_locations, function (child_data, child_name) {
                if ('__en' !== child_name) {
                    neighborhoods.push(child_name);
                }
            });
        });

        return {
            cities: cities,
            neighborhoods: neighborhoods,
        };
    }

    getStations() {
        const locations_data = this.locationData;
        const stations = [];

        _.each(this.selectedLocations, function (group_locations, group_name) {
            _.each(group_locations, function (child_data, child_name) {
                stations.push({
                    type: 'station',
                    id: locations_data[group_name][child_name].id,
                    d: 10,
                    u: 'min',
                });
            });
        });

        return stations;
    }

    getCustomAreas() {
        const locations_data = this.locationData;
        const custom_areas = [];

        _.each(this.selectedLocations, function (group_locations, group_name) {
            _.each(group_locations, function (child_data, child_name) {
                _.each(
                    locations_data[group_name][child_name].coords,
                    function (coords) {
                        custom_areas.push(coords);
                    }
                );
            });
        });

        return custom_areas;
    }
}
