import { View } from 'backbone';
import Chart from 'chart';
import numeral from 'numeral';
import config from '@/js/app/config';
import template from '../templates/summary_company.html';

export default class ReportRevenueCompanySummaryView extends View {
    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ReportRevenueCompanySummaryView#render');

        // Calculate grand total
        const grandTotal = _.reduce(
            this.model.toJSON(),
            (memo, num) => num.kpis.revenue.actual + memo,
            0
        );

        // Calculate target total
        const targetTotal = _.reduce(
            this.model.toJSON(),
            (memo, num) => num.kpis.revenue.target + memo,
            0
        );

        // Render template and attach to this.$el
        this.el.innerHTML = template({
            results: this.model.toJSON(),
            grandTotal: grandTotal,
            targetTotal: targetTotal,
        });

        // Filter out divisions with no staff, and negative revenue
        const raw_data = this.model
            .toJSON()
            .filter(
                (division) =>
                    _.size(division.staff) > 0 &&
                    division.kpis.revenue.actual >= 0
            );

        const chart_data = {
            datasets: [
                {
                    data: [],
                    borderWidth: 0,
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                },
            ],
            labels: [],
        };

        const colors = config.brand_colors.hsv(
            raw_data.length >= 5 ? raw_data.length : 5,
            'short'
        );

        // Add raw data to chart
        raw_data.forEach((division, index) => {
            const color = Object.create(colors[index]);

            // Add data to dataset
            chart_data.datasets[0].data.push(division.kpis.revenue.actual);
            chart_data.datasets[0].backgroundColor.push(
                color.toRgbString() || 'rgb(0, 0, 0)'
            );
            chart_data.datasets[0].hoverBackgroundColor.push(
                color.brighten().toRgbString()
            );

            // Add label
            chart_data.labels.push(division.name);
        });

        const ctx = document
            .getElementById('chart_company_summary')
            .getContext('2d');
        new Chart(ctx, {
            type: 'pie',
            data: chart_data,
            options: {
                easing: 'easeOutQuint',
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        label(tooltipItem, data) {
                            let dataLabel = data.labels[tooltipItem.index];
                            let value =
                                ': ' +
                                numeral(
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index]
                                ).format('0,0');

                            if (_.isArray(dataLabel)) {
                                // show value on first line of multiline label
                                // need to clone because we are changing the value
                                dataLabel = dataLabel.slice();
                                dataLabel[0] += value;
                            } else {
                                dataLabel += value;
                            }

                            return dataLabel;
                        },
                    },
                },
            },
        });

        return this;
    }
}
