import { history, View } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';
import template from '../templates/result.html';

export default class ReportTenantsResultView extends View {
    preinitialize() {
        this.tagName = 'tr';
        this.events = {
            'click [data-action="renew"]': 'renew',
            'click [data-action="cancel"]': 'cancel',
            'click [data-action="expire"]': 'expire',
        };
    }

    initialize() {
        _.bindAll(this, 'renew', 'cancel', 'expire');
    }

    render() {
        console.debug('ReportTenantsResultView#render');

        this.el.innerHTML = template({
            result: this.model.toJSON(),
        });

        return this;
    }

    async renew() {
        console.debug('ReportTenantsResultView#renew');

        if (confirm('Are you sure you want to renew this contract?')) {
            this.$el
                .find(`.spinner-border`)
                .addClass('text-success')
                .removeClass('d-none');

            await this.model.save(
                {
                    contract_status: 'renewed',
                },
                {
                    patch: true,
                    wait: true,
                }
            );

            const copyUrl = new URL(config.api.url + 'deals/' + this.model.id);
            copyUrl.search = jQuery.param({
                exclude: ['fees', 'splits', 'approvals'],
            });
            const response = await OAuth2Client.fetchJSON(copyUrl, {
                method: 'COPY',
            });

            // Redirect to edit deal
            history.navigate('deals/edit/' + response.id, { trigger: true });
        }
    }

    cancel() {
        console.debug('ReportTenantsResultView#cancel');

        if (confirm('Are you sure you want to cancel this contract?')) {
            this.$el
                .find(`.spinner-border`)
                .addClass('text-danger')
                .removeClass('d-none');

            this.model
                .save(
                    {
                        contract_status: 'cancelled',
                    },
                    {
                        patch: true,
                        wait: true,
                    }
                )
                .then(() => this.remove());
        }
    }

    expire() {
        console.debug('ReportTenantsResultView#expire');

        if (confirm('Are you sure you want to expire this contract?')) {
            this.$el
                .find(`.spinner-border`)
                .addClass('text-warning')
                .removeClass('d-none');

            this.model
                .save(
                    {
                        contract_status: 'expired',
                    },
                    {
                        patch: true,
                        wait: true,
                    }
                )
                .then(() => this.remove());
        }
    }
}
