module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="cntr-unit-list" class="card unit-list">\n    <div class="card-header" role="button" data-toggle="collapse" data-target="#card-body-unit-list">\n        <div class="d-flex justify-content-between align-items-center">\n            <h2 class="card-title h4 mb-0">\n                <i class="fa fa-sitemap" aria-hidden="true"></i> All Units in ' +
((__t = ( structure_name )) == null ? '' : __t) +
'\n            </h2>\n\n            <span>\n                <a href="#" class="btn btn-sm btn-secondary" data-action="new-unit">Add Unit</a>\n            </span>\n        </div>\n    </div>\n\n    <div id="card-body-unit-list" class="collapse">\n\n        <div id="results" style="position: relative"></div>\n\n    </div>\n</div>\n';

}
return __p
};
