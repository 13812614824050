import { View } from 'backbone';
import template from '../templates/summary.html';

export default class ReportAccountingSummaryView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('ReportAccountingSummaryView#render');

        const summary = {
            bf_total: 0,
            sf_total: 0,
            fees_total: 0,
        };

        // Summarize results
        this.collection.toJSON().forEach((result) => {
            summary.bf_total += result.split_bf;
            summary.sf_total += result.split_sf;
            summary.fees_total += result.split_fees;
        });

        this.el.innerHTML = template(summary);

        return this;
    }
}
