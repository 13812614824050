module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card">\n    <div class="card-header">\n        <h2 class="card-title h4 mb-0"><i class="fa fa-bar-chart" aria-hidden="true"></i> My Targets</h2>\n    </div>\n\n    <table class="table table-sm">\n        <thead>\n            <tr>\n                <th></th>\n                <th>Actual</th>\n                <th>Target</th>\n                <th>Percentage</th>\n            </tr>\n        </thead>\n        <tbody>\n            <tr>\n                <td><strong>This Month</strong></td>\n                <td>' +
((__t = ( numeral(revenue.thisMonth.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( numeral(revenue.thisMonth.target).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td class="color ' +
((__t = ( revenue.thisMonth.percent >= 100 ? 'good' : 'bad' )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( numeral(revenue.thisMonth.percent).format('0.[0]') )) == null ? '' : __t) +
'%\n                </td>\n            </tr>\n        ';
 if (revenue.nextMonth) { ;
__p += '\n            <tr>\n                <td><strong>Next Month</strong></td>\n                <td>' +
((__t = ( numeral(revenue.nextMonth.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( numeral(revenue.nextMonth.target).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td class="color ' +
((__t = ( revenue.nextMonth.percent >= 100 ? 'good' : 'bad' )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( numeral(revenue.nextMonth.percent).format('0.[0]') )) == null ? '' : __t) +
'%\n                </td>\n            </tr>\n        ';
 } ;
__p += '\n            <tr>\n                <td><strong>This Quarter</strong></td>\n                <td>' +
((__t = ( numeral(revenue.thisQtr.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( numeral(revenue.thisQtr.target).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td class="color ' +
((__t = ( revenue.thisQtr.percent >= 100 ? 'good' : 'bad' )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( numeral(revenue.thisQtr.percent).format('0.[0]') )) == null ? '' : __t) +
'%\n                </td>\n            </tr>\n            <tr>\n                <td><strong>YTD</strong></td>\n                <td>' +
((__t = ( numeral(revenue.ytd.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( numeral(revenue.ytd.target).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td class="color ' +
((__t = ( revenue.ytd.percent >= 100 ? 'good' : 'bad' )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( numeral(revenue.ytd.percent).format('0.[0]') )) == null ? '' : __t) +
'%\n                </td>\n            </tr>\n            <tr>\n                <td><strong>This Year</strong></td>\n                <td>' +
((__t = ( numeral(revenue.thisYear.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( numeral(revenue.thisYear.target).format('0,0') )) == null ? '' : __t) +
'</td>\n                <td class="color ' +
((__t = ( revenue.thisYear.percent >= 100 ? 'good' : 'bad' )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( numeral(revenue.thisYear.percent).format('0.[0]') )) == null ? '' : __t) +
'%\n                </td>\n            </tr>\n        </tbody>\n    </table>\n\n    <div class="card-footer">\n        <a href="#reports/staff/' +
((__t = ( Session.data.staff_id )) == null ? '' : __t) +
'/' +
((__t = ( moment().startOf('month').format('YYYY-MM-DD') )) == null ? '' : __t) +
'/' +
((__t = ( moment().endOf('month').format('YYYY-MM-DD') )) == null ? '' : __t) +
'">\n            Detailed Report\n        </a>\n    </div>\n</div>';

}
return __p
};
