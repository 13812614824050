import { Model } from 'backbone';
import config from '@/js/app/config';

const DealModel = Model.extend({

    urlRoot: config.api.url + 'deals',

    initialize: function () {
        // Calculate contract duration
        this.listenTo(this, 'change:賃貸条件_契約期間_start change:賃貸条件_契約期間_end', this.calcContractDuration);
    },

    /* Contract duration calculation */
    calcContractDuration: function (model) {
        console.debug('DealModel#calcContractDuration');
        let start = model.get('賃貸条件_契約期間_start');
        let end = model.get('賃貸条件_契約期間_end');

        if (start) {
            start = moment(start);
        }
        if (end) {
            // Add one day to end date for inclusive calculation
            end = moment(end).add(1, 'days');
        }

        // If start and end dates valid
        if (start && end) {
            const years = end.diff(start, 'years');
            const months = years > 0
                ? end.subtract('years', years).diff(start, 'months')
                : end.diff(start, 'months');
            model.set('賃貸条件_契約期間_length', years + '年' + months + 'ヶ月');
        }
    },

    // Default attributes for the contract item
    defaults: function () {
        return {
            id: null,
        };
    },

});

export default DealModel;
