import { Collection } from 'backbone';
import config from '@/js/app/config';
import DivisionModel from '../models/division';

export default Collection.extend({

    url: config.api.url + 'divisions',

    model: DivisionModel,

});