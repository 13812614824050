import { View } from 'backbone';
import template from '../templates/index.html';
import ReportYtdGenerateView from './generate';

export default class ReportYtdIndexView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'reports';

        // Create subviews
        this.subviews = {
            generate: new ReportYtdGenerateView(options),
        };
    }

    initialize() {
        this.render();
    }

    render() {
        console.debug('ReportYtdIndexView#render');

        // Render template
        this.el.innerHTML = template();

        // Bind report view
        this.subviews.generate.setElement(this.el.querySelector('#generate')).render();

        return this;
    }
}