import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import PeopleEdmModel from '../models/edm';
import template from '../templates/edit_profile_edm.html';

export default class PeopleEditProfileEdmView extends View {
    preinitialize(options) {
        this.peopleModel = options.peopleModel;
        this.model = new PeopleEdmModel({}, {
            peopleId: this.peopleModel.id
        });
        this.events = {
            'change input': this.updateModel,
        };
        this.hasEmailChanged = false;
    }

    initialize() {
        this.listenTo(this.peopleModel, 'change:email', this.handlePeopleModelEmailChange);
        this.listenTo(this.peopleModel, 'sync', this.handlePeopleModelSync);
        this.listenTo(this.model, 'change', this.handleModelChange);
        this.model.fetch({
            isForRender: true
        });
    }

    render() {
        console.debug('PeopleEditProfileEdmView#render');

        this.el.innerHTML = template();

        populateForm(this.el, this.model.toJSON());
        this.handleUnsubscribedLists();
    }

    handlePeopleModelEmailChange(model, value, options) {
        console.debug('PeopleEditProfileEdmView#handlePeopleModelEmailChange');

        if (options.initialLoad) {
            return;
        }

        this.hasEmailChanged = true;
    }

    handlePeopleModelSync() {
        console.debug('PeopleEditProfileEdmView#handlePeopleModelSync');

        if (this.hasEmailChanged) {
            this.hasEmailChanged = false;
            this.model.fetch();
        }
    }

    handleModelChange(model, options) {
        console.debug('PeopleEditProfileEdmView#handleModelChange');

        if (options.isForRender) {
            this.render();
            model.set('id', 1);
        } else {
            this.updateOutlets(model);
        }
    }

    handleUnsubscribedLists() {
        console.debug('PeopleEditProfileEdmView#handleUnsubscribedLists');

        const edm = this.model.toJSON();

        // Loop over lists
        for (const [list, status] of Object.entries(edm)) {
            // If list status is -1, user explicitly unsubscribed (we cannot resubscribe them)
            if (status === -1) {
                const listSection = this.el.querySelector(`[data-part="${list}"]`);
                if (listSection) {
                    listSection.querySelector(`[name="${list}"]`).disabled = true;
                    listSection.querySelector('[data-part="unsubscribed"]').style.display = 'inline';
                }
            }
        }
    }

    updateModel(e) {
        console.debug('PeopleEditProfileEdmView#updateModel');

        e.stopPropagation();

        const t = e.currentTarget;

        // If no name, return
        if (t.name === '') {
            return;
        }

        // Save new value
        this.model.save(t.name, t.checked, { patch: true, wait: true });
    }

    updateOutlets(model) {
        console.debug('PeopleEditProfileEdmView#updateOutlets');

        // Loop through all changed attributes, and set value of input fields
        /** @todo What if <select> or <textarea>? */
        _.each(model.changedAttributes(), (v, k) => {
            this.$el.find('input[name="' + k + '"]').prop('checked', v > 0);    // Only positive values are considered checked
        });
    }
}
