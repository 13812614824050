import { View } from 'backbone';
import OAuth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import templateNoResults from '../templates/no_results.html';
import template from '../templates/search_results.html';
import InquiryResultView from './result';

export default class InquiryResultsView extends View {
    preinitialize() {
        this.events = {
            'click ul.pagination a': this.changePage,
            'click [data-action="export"]': this.export,
        };
    }

    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    changePage($e) {
        console.debug('InquiryResultsView#changePage');

        $e.preventDefault();
        $e.stopPropagation();

        // Set criteria offset from clicked UI element
        this.criteria.offset = Number($e.target.dataset.offset);

        // Fetch
        this.collection.fetch({
            data: this.criteria,
            reset: true,
        });
    }

    render() {
        console.debug('InquiryResultsView#render');

        if (this.collection.length === 0) {
            this.el.innerHTML = templateNoResults();
        } else {
            this.el.innerHTML = template({
                criteria: this.criteria,
                total_rows: this.collection.total_rows,
                limit: this.criteria.limit,
                offset: this.criteria.offset,
                Session,
            });

            const rows = [];

            this.collection.each(function (model) {
                const row = new InquiryResultView({
                    model: model,
                });

                rows.push(row.render().el);
            });

            this.$el.find('tbody').html(rows);
        }

        return this;
    }

    export() {
        console.debug('InquiryResultsView#export');

        const criteria = _.clone(this.criteria);

        const url = new URL(_.result(this.collection, 'url'));
        url.search = jQuery.param(_.omit(criteria, ['limit', 'offset'])); // Remove keys from criteria for CSV download

        OAuth2Client.csvDownload(
            url,
            {},
            {
                filename:
                    'inquiries-' + moment().format('YYYYMMDD-HHmmss') + '.csv',
            }
        );
    }
}
