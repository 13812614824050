import { Model } from 'backbone';
import config from '@/js/app/config';

export default Model.extend({

    idAttribute: 'year',

    urlRoot: function () {
        return config.api.url + 'staff/' + this.staffId + '/kpis';
    },

    initialize: function (_, options) {
        this.staffId = options.staffId || options.collection.staffId || null;
    }

});