import { Collection } from 'backbone';
import config from '@/js/app/config';
import ApplicationModel from '../models/application';

export default Collection.extend({

    url: config.api.url + 'applications',

    model: ApplicationModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});