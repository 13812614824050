import { View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import Session from '@/js/app/session';
import PropertyAlertModel from '../models/property-alert';
import template from '../templates/edit.html';

export default class PropertyAlertEditView extends View {
    preinitialize(options) {
        this.events = {
            'change form input,form select,form textarea': this.updateModel,
            'click button[data-action="delete"]': this.delete,
        };

        this.model = new PropertyAlertModel({
            id: options.propertyAlertId,
        });
    }

    initialize() {
        // Functions that will trigger from DOM events need to bind to "this"
        _.bindAll(this, 'render', 'updateModel', 'delete');

        this.render();

        // Trigger fetch, and call "render" on success
        this.model.fetch({
            data: {
                include: ['search_text']
            },
            silent: true,	// Mark: I know this is not recommended, but we need to get data into this model without triggering any "change" events
            success: this.render,
        });
    }

    render() {
        console.debug('PropertyAlertEditView#render');

        this.el.innerHTML = template({
            alert: this.model.toJSON(),
            Session: Session
        });

        populateForm(this.el, this.model.toJSON());

        this.el.querySelector('#field-created_at').value = moment.utc(this.model.toJSON().created_at).local().format('YYYY-MM-DD HH:mm:ss');
        this.el.querySelector('[name="criteria"]').value = JSON.stringify(this.model.toJSON().criteria);

        return this;
    }

    updateModel($e) {
        console.debug('PropertyAlertEditView#updateModel');

        // Determine new value
        const t = $e.currentTarget;
        let value;

        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else; use value or null
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.save(t.name, value, { patch: true, wait: true });
    }

    delete() {
        console.debug('PropertyAlertEditView#delete');

        if (confirm('Are you sure you want to delete this alert?')) {
            this.model.destroy({
                success: function () {
                    window.history.go(-1);
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }
}