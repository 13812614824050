import { Events } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

export default class YtdReportResultModel {

    constructor() {
        _.extend(this, Events)
        this.data = null;
    }

    fetchCompany(options) {
        this.trigger('request');

        OAuth2Client.ajax(_.extend({
            url: config.api.url + 'reports/ytd/company',
            dataType: 'json',
        }, options))
            .then((response) => {
                this.trigger('request_done');
                this.data = response;

                this.trigger('change');
            });
    }

    fetchDivision(options) {
        this.trigger('request');

        OAuth2Client.ajax(_.extend({
            url: config.api.url + 'reports/ytd/division',
            dataType: 'json',
        }, options))
            .then((response) => {
                this.trigger('request_done');
                this.data = response;

                this.trigger('change', this.data);
            });
    }

    toJSON() {
        return this.data;
    }

}