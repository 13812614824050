import config from '@/js/app/config';
import Session from '@/js/app/session';
import OAuth2Client from '@/js/libs/oauth2-client';
import { history } from 'backbone';

export default new OAuth2Client({
    url: config.auth.url,
    client_id: config.auth.client_id,
    client_secret: config.auth.client_secret,
    token_name: 'hj-access-token',
    onRestoreToken: async () => {
        try {
            await Session.fetchInfo();
            Session.start();
        }
        catch (err) {
            // Log as warning and continue (don't rethrow error)
            console.warn('Unable to recover session:', err);
        }
    },
    onAuthenticate: async () => {
        await Session.fetchInfo()
        Session.start();
    },
    onTokenRefresh: async () => {
        await Session.fetchInfo();
        Session.start();
    },
    onTokenRefreshFail: async () => {
        Session.end();

        sessionStorage.setItem('redirect', history.fragment);

        history.navigate('login', { trigger: true });
    }
});
