module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal-header">\n    <h3 class="modal-title">Changelog</h3>\n    <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n        <i class="fa fa-times" aria-hidden="true"></i>\n    </button>\n</div>\n<div class="modal-body">\n    <table class="table table-sm table-responsive d-table">\n        <thead>\n            <tr>\n                <th>Timestamp</th>\n                <th>User</th>\n                <th>Field</th>\n                <th>Value</th>\n            </tr>\n        </thead>\n        <tbody>\n        ';
 changelogs.forEach((change) => { ;
__p += '\n            <tr>\n                <td>' +
((__t = ( change.timestamp.format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( change.author )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( change.field )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( change.value )) == null ? '' : __t) +
'</td>\n            </tr>\n        ';
 }) ;
__p += '\n        </tbody>\n    </table>\n</div>';

}
return __p
};
