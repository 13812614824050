class Preferences {
    constructor() {
        this.property_type = 'rent';
        this.sort_key = 'updated_at';
        this.sort_order = 'desc';
        this.limit = 50;
    }

    save() {
        return localStorage.setItem('preferences', JSON.stringify(this));
    }
}

export default new Preferences();