import { View } from 'backbone';
import template from '../templates/typeahead.html';

export default class Typeahead extends View {
    #mode = '';

    preinitialize(options) {
        this.events = {
            'typeahead:select .typeahead': this.select,
            'click [data-action="delete"]': this.handleClearClick,
        };

        this.placeholder = options.placeholder;
        this.value = options.value;
        this.options = options.options;
        this.datasets = options.datasets;
        this.datum = options.datum;
        this.showDelete = typeof options.showDelete === 'undefined' ? true : options.showDelete;
    }

    initialize() {
        _.bindAll(this, 'render');
    }

    render() {
        console.debug('UiTypeaheadView#render');

        this.el.innerHTML = template({
            placeholder: this.placeholder,
        });

        // Setup typeahead field
        this.$el
            .find('.typeahead')
            .typeahead(this.options, this.datasets);

        // Set value
        this.setValue(this.value);

        this.#initializeReadEditMode();

        return this;
    }

    switchReadMode() {
        this.#mode = 'read';

        this.#initializeReadEditMode();
    }

    switchEditMode() {
        this.#mode = 'edit';

        this.#initializeReadEditMode();
    }

    #initializeReadEditMode() {
        if (this.#mode === 'read') {
            this.#disableInput();

            if (this.showDelete) {
                this.#hideDeleteLink();
            }
        } else if (this.#mode === 'edit') {
            const currentValue = this.$el.find('.typeahead').val();
            if (currentValue) {
                this.#disableInput();

                if (this.showDelete) {
                    this.#showDeleteLink();
                }
            } else {
                this.#enableInput();

                if (this.showDelete) {
                    this.#hideDeleteLink();
                }
            }
        }
    }

    setValue(value) {
        console.debug('UiTypeaheadView#setValue');

        this.$el.find('.typeahead').typeahead('val', value);

        this.#initializeReadEditMode();
    }

    selectDatum(datum) {
        console.debug('UiTypeaheadView#selectDatum');

        this.$el.find('.typeahead').trigger('typeahead:select', datum);
    }

    select($e, datum) {
        console.debug('UiTypeaheadView#select');

        // Set datum in view
        this.datum = datum;

        // Trigger "select" with original event and datum as parameters
        this.trigger('select', $e, this.datum);

        if (this.showDelete) {
            this.#showDeleteLink();
        }

        // Disable input
        $e.currentTarget.disabled = true;
    }

    handleClearClick($e) {
        console.debug('UiTypeaheadView#clear');

        $e.preventDefault();

        this.clear();

        // Trigger "clear" with original event as parameter
        this.trigger('clear', $e);
    }

    clear() {
        // Clear datum in view
        this.datum = null;

        if (this.showDelete) {
            this.#hideDeleteLink();
        }

        // Clear and enable typeahead
        this.$el
            .find('.typeahead')
            .typeahead('val', '')
            .prop('disabled', false)
            .focus();
    }

    #enableInput() {
        this.el.querySelector('.typeahead').disabled = false;
    }

    #disableInput() {
        this.el.querySelector('.typeahead').disabled = true;
    }

    #showDeleteLink() {
        this.el.querySelector('[data-action="delete"]').style.display = '';
    }

    #hideDeleteLink() {
        this.el.querySelector('[data-action="delete"]').style.display = 'none';
    }
}
