import { View } from 'backbone';
import oauth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import ReportTenantsResultCollection from '../collections/results';
import template from '../templates/index.html';
import ReportTenantsResultsView from './results';

export default class ReportTenantsIndexView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'reports';

        this.events = {
            'click [data-action="export"]': this.export,
        }

        // Create subviews
        this.subviews = {
            resultsView: new ReportTenantsResultsView({
                collection: new ReportTenantsResultCollection(),
            }),
        };
    }

    initialize() {
        this.render();

        // Fetch collection
        this.subviews.resultsView.collection.fetch({
            data: {
                order: '賃貸条件_契約期間_end'
            },
        });
    }

    render() {
        console.debug('ReportTenantsIndexView#render');

        // Render template
        this.el.innerHTML = template({
            isExportAllowed: Session.isAllowed('phnx:people:exp')
        });

        // Attach and render resultsView subview
        this.subviews.resultsView.setElement(this.el.querySelector('#results')).render()

        return this;
    }

    export() {
        console.debug('ReportTenantsIndexView#export');

        const url = new URL(this.subviews.resultsView.collection.url);
        url.search = jQuery.param({
            order: '賃貸条件_契約期間_end'
        });

        oauth2Client.xlsxDownload(url, {}, {
            filename: 'Tenant Report - ' + moment().format('YYYYMMDD-HHmmss') + '.xlsx'
        });
    }
}
