module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Results</h2>\n\n';
 if (Session.isAllowed('phnx:people:exp')) { ;
__p += '\n<button type="button" data-action="export" class="btn btn-secondary mb-3">Export CSV</button>\n';
 } ;
__p += '\n\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Timestamp</th>\n            <th>Name</th>\n            <th>Type</th>\n            <th>Page</th>\n            <th>Country</th>\n            <th>Client ID</th>\n            <th></th>\n        </tr>\n    </thead>\n    <tbody></tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '';

}
return __p
};
