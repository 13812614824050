import { View } from 'backbone';
import numeral from 'numeral';
import populateForm from '@/js/libs/populate-form';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Preferences from '@/js/app/preferences';
import Session from '@/js/app/session';
import Spinner from '@/js/app/ui/views/spinner';
import * as TextHelper from '@/js/app/text-helper';
import propertyRestoreSelectedService from '../services/restore-selected';
import tmplBulkEdit from '../templates/bulkedit_results.html';
import tmplResults from '../templates/search_results.html';

export default class PropertySearchResultsView extends View {
    preinitialize(options) {
        this.events = {
            'click input[name="property_selection_all"]': this.toggleAll,
            'click input[type="checkbox"][name="property_selection"]': this.toggle,
            'click th[data-sort-column]': this.sort,
            'click ul.pagination a': this.changePage,
            'click [data-action="property-print"]': this.printSelected,
            'click [data-action="bulkedit"]': this.bulkedit,
            'change .bulkedit input': this.handleBulkFieldChange,
            'change .bulkedit select': this.handleBulkFieldChange,
            'change .bulkedit textarea': this.handleBulkFieldChange,
        };

        this.query = options.query;
        this.format = options.format || 'long';
        this.buttons = options.buttons || {};

        this.spinner = new Spinner({
            top: true,
        });
    }

    render() {
        console.debug('PropertySearchResultsView#render');

        // Determine template to use
        const tmpl = 'bulkedit' === this.format ? tmplBulkEdit : tmplResults;

        // Render template
        this.el.innerHTML = tmpl({
            mode: this.format,
            query: this.query,
            properties: this.properties,
            total_rows: this.total_rows,
            limit: this.query.limit,
            offset: this.query.offset,
            buttons: this.buttons,
            selected: propertyRestoreSelectedService(),
            Formatter,
            TextHelper,
            Preferences,
            Session,
        });

        if (this.format === 'bulkedit') {
            this.populateBulkedit(this.properties);
        }

        if (
            this.format === 'short' ||
            this.format === 'short-no-property-selection' ||
            this.format === 'short-send'
        ) {
            this.$el.find('.long').remove();
        }
        if (
            this.format === 'no-property-selection' ||
            this.format === 'short-no-property-selection'
        ) {
            this.$el
                .find(
                    '[name="property_selection"], [name="property_selection_all"]'
                )
                .remove();
        }

        if (this.format === 'bulkedit') {
            this.$el
                .find('div.actionsBar .btn:not([data-action="bulkedit"])')
                .prop('disabled', true)
                .addClass('disabled');
        } else {
            this.$el
                .find('div.actionsBar .btn:not([data-action="bulkedit"])')
                .prop('disabled', false);

            this.setPropertyActionSelectedButtons(
                propertyRestoreSelectedService()
            );
        }

        return this;
    }

    fetch() {
        // Start spinner
        this.spinner.spin(this.el);

        // Add "include" property
        this.query.include = ['location', 'structure', 'agent_company'];

        const url = new URL(config.api.url + 'units');
        url.search = jQuery.param(this.query);

        OAuth2Client.fetchJSON(url, {})
            .then((data) => {
                this.properties = data.results || [];
                this.rows = data.rows || 0;
                this.total_rows = data.total_rows || 0;

                this.render();
            })
            .finally(() => {
                // Stop spinner
                this.spinner.stop();
            });
    }

    toggleAll($e) {
        $e.stopPropagation();

        const selection = [];

        if ($e.currentTarget.checked) {
            // Check all
            this.$el
                .find('input[name="property_selection"]')
                .each(function (i, element) {
                    element.checked = true;
                    selection.push(Number(element.value));
                });
        } else {
            // Uncheck all
            this.$el
                .find('input[name="property_selection"]')
                .prop('checked', false);
        }

        // Store selected properties
        propertyRestoreSelectedService(selection);

        // Update property action buttons
        this.setPropertyActionSelectedButtons(selection);
    }

    toggle(e) {
        const id = Number(e.currentTarget.value);
        const selection = propertyRestoreSelectedService();

        // If not in selection array
        const idIndex = selection.indexOf(id);
        if (idIndex === -1) {
            // Add to selection
            selection.push(id);
        } else {
            // Remove from selection array
            selection.splice(idIndex, 1);
        }

        propertyRestoreSelectedService(selection);

        this.setPropertyActionSelectedButtons(selection);
    }

    setPropertyActionSelectedButtons(selected) {
        if (0 === selected.length) {
            this.$el
                .find('div.actionsBar .btn[data-button-requires="selection"]')
                .prop('disabled', true)
                .addClass('disabled');

            this.$el
                .find('div.actionsBar .btn[data-url="property/export"]')
                .text('Export Results');
        } else {
            this.$el
                .find('div.actionsBar .btn[data-button-requires="selection"]')
                .prop('disabled', false)
                .removeClass('disabled');

            this.$el
                .find('div.actionsBar .btn[data-url="property/export"]')
                .text('Export Selected');
        }
    }

    sort($e) {
        $e.preventDefault();

        this.query.sort_key = $e.currentTarget.dataset.sortColumn;
        this.query.sort_order =
            $e.currentTarget.dataset.sortDirection === 'asc' ? 'desc' : 'asc';

        this.fetch();
    }

    changePage($e) {
        $e.preventDefault();

        this.query.offset = Number($e.currentTarget.dataset.offset);

        this.fetch();

        $(window).scrollTop(
            this.$el.offset().top ? this.$el.offset().top - 125 : 120
        );
    }

    printSelected($e) {
        $e.preventDefault();

        window.open(
            Formatter.zumen_url('unit', propertyRestoreSelectedService()),
            _.uniqueId('print_zumen')
        );
    }

    bulkedit($e) {
        $e.preventDefault();

        this.format = this.format === 'bulkedit' ? 'long' : 'bulkedit';

        this.render();
    }

    populateBulkedit(propertyList) {
        for (const property of propertyList) {
            const trElement = this.el.querySelector(
                `tr[data-id="${property.id}"]`
            );
            populateForm(trElement, property);
        }
    }

    handleBulkFieldChange($e) {
        const field = $($e.currentTarget);

        // Get ID of row to update
        const id = field.closest('tr').data('id');
        if (!id) {
            throw new Error(
                'Cannot update property as no ID attribute was found'
            );
        }

        let value =
            'checkbox' === field.prop('type')
                ? field.prop('checked')
                    ? 1
                    : 0
                : field.val();

        // If data-numeral exists and value is string
        const numeralFormat = field.data('numeral');
        if (numeralFormat && typeof value === 'string') {
            value = value.replace(/,/gi, '');
            field.val(numeral(value).format(numeralFormat));
        }

        /* if the field is a partial data field or is NOT an update field, return */
        if (field.data('partial') === true || field.data('update') === false) {
            return;
        }

        // Send PATCH request
        OAuth2Client.fetchJSON(config.api.url + 'units/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                [field.prop('name')]: value,
            }),
        }).then((result) => {
            if ('undefined' !== typeof result.error) {
                alert(result.error);
            } else {
                field
                    .closest('tr')
                    .find('.timestamp')
                    .html(Formatter.timestamp(false, true));

                // Update other fields based on current field
                switch (field.prop('name')) {
                    case 'availability_status':
                        // If "unavailable"; unpublish
                        if (value === 'unavailable') {
                            field
                                .closest('tr')
                                .find('input[name="published"]')
                                .prop('checked', false)
                                .trigger('change');
                            field
                                .closest('tr')
                                .find('input[name="published_ja"]')
                                .prop('checked', false)
                                .trigger('change');
                        }
                        break;
                }
            }
        });
    }
}
