import { Collection } from 'backbone';
import config from '@/js/app/config';
import DealModel from '@/js/app/deal/models/deal';

const ReportTenantsResultCollection = Collection.extend({

    url: config.api.url + 'reports/tenants',

    model: DealModel,

});

export default ReportTenantsResultCollection;