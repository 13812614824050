module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row no-gutters align-items-center">\n    <div class="col">\n        <input class="typeahead" type="text" placeholder="&#xf002 ' +
((__t = ( placeholder )) == null ? '' : __t) +
'" autocomplete="off">\n    </div>\n\n    <div class="col-auto">\n        <a href="#" data-action="delete" style="display:none" class="ml-2" title="Clear"><i class="fa fa-times fa-lg" style="color:#000000" aria-hidden="true"></i></a>\n    </div>\n</div>\n';

}
return __p
};
