import { View } from 'backbone';
import Spinner from '@/js/app/ui/views/spinner';
import templateSpinner from '@/js/app/ui/templates/spinner.html';
import templateError from '@/js/app/ui/templates/error.html';
import * as Formatter from '@/js/app/formatter';
import Session from '@/js/app/session';
import template from '../templates/search_results.html';

export default class StaffResultsView extends View {

    preinitialize(options) {
        this.loading = false;
        this.error = false;

        this.events = {
            'click ul.pagination a': this.changePage,
        };

        this.criteria = options.criteria;

        this.spinner = new Spinner({
            top: true,
        });
    }

    initialize() {
        // When criteria changes; search
        this.listenTo(this.criteria, 'change', this.search);

        // When collection makes request, show spinner
        this.listenTo(this.collection, 'request', this.handleCollectionRequest);

        // When collection request finishes, stop spinner
        this.listenTo(this.collection, 'sync', this.handleCollectionSync);

        // If collection errors, show error message
        this.listenTo(this.collection, 'error', this.handleCollectionError);
    }

    search() {
        console.debug('StaffResultsView#search');

        // Fetch collection
        this.collection.fetch({
            data: _.pick(this.criteria.toJSON(), (value) => { return value !== '' })
        });
    }

    changePage($e) {
        console.debug('StaffResultsView#changePage');

        $e.preventDefault();

        // Set criteria offset from clicked UI element
        this.criteria.set('offset', Number($e.target.dataset.offset));
    }

    render() {
        console.debug('StaffResultsView#render');

        if (this.loading) {
            this.el.innerHTML = templateSpinner();
        } else if (this.error) {
            this.el.innerHTML = templateError();
        } else {
            this.el.innerHTML = template({
                staff: this.collection.toJSON(),
                criteria: this.criteria.toJSON(),
                total_rows: this.collection.total_rows,
                limit: this.criteria.get('limit'),
                offset: this.criteria.get('offset'),
                Formatter,
                Session,
            });
        }

        return this;
    }

    handleCollectionRequest() {
        this.loading = true;

        this.render();
    }

    handleCollectionSync() {
        this.loading = false;

        this.render();
    }

    handleCollectionError() {
        this.loading = false;
        this.error = true;

        this.render();
    }

}