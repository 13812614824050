module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<canvas class="chart" style="width: 100%; height: 400px"></canvas>\n\n<table class="table table-sm table-bordered">\n    <thead>\n        <tr>\n            <th scope="col" class="text-center">Types</th>\n            ';
 months.forEach((month)=> { ;
__p += '\n                <th scope="col" class="text-center">\n                    ' +
((__t = ( moment(month).format('MMM') )) == null ? '' : __t) +
'\n                </th>\n                ';
 }); ;
__p += '\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 types.forEach((type)=> { ;
__p += '\n            <tr class="inquiry-type">\n                <th scope="row" class="text-center">\n                    ' +
((__t = ( type.name )) == null ? '' : __t) +
'\n                </th>\n                ';
 type.count.forEach((value)=> { ;
__p += '\n                    <td class="text-center">\n                        ' +
((__t = ( value )) == null ? '' : __t) +
'\n                    </td>\n                    ';
 }); ;
__p += '\n            </tr>\n            ';
 }); ;
__p += '\n    </tbody>\n\n    <tfoot>\n        <tr class="total">\n            <th scope="row" class="text-center total">Total</th>\n            ';
 totals.forEach((value)=> { ;
__p += '\n                <td class="text-center total">\n                    ' +
((__t = ( value )) == null ? '' : __t) +
'\n                </td>\n                ';
 }); ;
__p += '\n        </tr>\n\n        <tr>\n            <th scope="row" class="text-center">Target</th>\n            ';
 targets.forEach((value)=> { ;
__p += '\n                <td class="text-center">\n                    ' +
((__t = ( value )) == null ? '' : __t) +
'\n                </td>\n                ';
 }); ;
__p += '\n        </tr>\n\n        <tr>\n            <th scope="row" class="text-center">Previous Total</th>\n            ';
 prev_totals.forEach((value)=> { ;
__p += '\n                <td class="text-center">\n                    ' +
((__t = ( value )) == null ? '' : __t) +
'\n                </td>\n                ';
 }); ;
__p += '\n        </tr>\n\n        <tr>\n            <th scope="row" class="text-center">YOY</th>\n            ';
 yoy.forEach((value)=> { ;
__p += '\n                <td class="text-center">\n                    ' +
((__t = ( numeral(value).format('0') )) == null ? '' : __t) +
'%\n                </td>\n                ';
 }); ;
__p += '\n        </tr>\n    </tfoot>\n</table>';

}
return __p
};
