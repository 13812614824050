module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 model.kpis.forEach(kpi => { ;
__p += '\n<tr>\n    <th scope="row">\n        ' +
((__t = ( moment().year(kpi.year).month(kpi.month - 1).format('MMM YYYY') )) == null ? '' : __t) +
'\n    </th>\n    <td>' +
((__t = ( Formatter.division(kpi.division_id) )) == null ? '' : __t) +
'</td>\n    <td class="text-right">' +
((__t = ( Formatter.currency() + numeral(kpi.revenue).format('0,0') )) == null ? '' : __t) +
'</td>\n</tr>\n';
 }) ;


}
return __p
};
