import config from '@/js/app/config';
import { getName } from '@/js/app/formatter';
import ModalView from '@/js/app/generic/modal';
import OAuth2Client from '@/js/app/oauth2-client';
import template from '../templates/modal.html';

export default class ChangelogsView extends ModalView {
    preinitialize() {
        super.preinitialize();
    }

    initialize() {
        // When collection syncs; render
        this.listenTo(this.collection, 'sync', this.render);
    }

    async render() {
        console.debug('ChangelogsView#render');

        /**
         * Consider "including" staff details in changelogs fetch, rather
         * than fetching separately and mapping on the UI.
         *
         * Need to consider efficiencies in this operation, as well as
         * efficiencies in all operations if we use "include" or keep
         * a list of staff available globally.
         */
        // Fetch all staff
        const fetchAllStaff = await OAuth2Client.fetchJSON(config.api.url + 'staff', {});
        const allStaff = fetchAllStaff.results;

        // Map changes in changelog
        const changelogs = this.collection.toJSON().map((change) => {
            // Convert timestamp to local time
            change.timestamp = moment.utc(change.timestamp).local();

            // Find staff based on user_id
            const staff = _.findWhere(allStaff, { id: change.user_id });

            // Add author to change
            change.author = (staff !== undefined ? getName(staff) : 'System');

            return change;
        });

        // Render parent view, then set modal content to template
        super.render({
            size: 'xl',
            centered: true,
        }).modalContent.innerHTML = template({
            changelogs,
        });
    }
}