module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>Fees / Costs</th>\n            <th>Deal ID</th>\n            <th>Deal Date</th>\n            <th>Seller / Owner</th>\n            <th>Buyer / Leasee</th>\n            <th>Agent Name</th>\n            <th>Referral</th>\n            <th>Property Name</th>\n            <th>Buyers Fee</th>\n            <th>Sellers Fee</th>\n            <th>Other Fees</th>\n            <th>Revenue Total</th>\n            <th>Memo</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 results.forEach((r) => { ;
__p += '\n        <tr>\n            <td>\n                <span class="fa-stack">\n                    ';
 if (r.fees_paid) { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #333333;" aria-hidden="true"></i><i\n                        class="fa fa-check fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                    ';
 } else { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #a94442;" aria-hidden="true"></i><i\n                        class="fa fa-yen fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                    ';
 } ;
__p += '\n                </span>\n                <span class="fa-stack">\n                    ';
 if (r.costs_paid) { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #333333;" aria-hidden="true"></i><i\n                        class="fa fa-check fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                    ';
 } else { ;
__p += '\n                    <i class="fa fa-circle fa-stack-2x" style="color: #a94442;" aria-hidden="true"></i><i\n                        class="fa fa-yen fa-stack-1x fa-inverse" aria-hidden="true"></i>\n                    ';
 } ;
__p += '\n                </span>\n            </td>\n            <td><a href="#deals/edit/' +
((__t = ( r.id )) == null ? '' : __t) +
'">' +
((__t = ( r.custom_id ? r.custom_id : r.id )) == null ? '' : __t) +
'</a></td>\n            <td>' +
((__t = ( r.取引の概要_契約成立年月日 )) == null ? '' : __t) +
'</td>\n            <td>\n                ';
 if (r.貸主_本人_type === 'individual') { ;
__p += '\n                <a href="#people/edit/' +
((__t = ( r.貸主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( r.貸主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } else if (r.貸主_本人_type === 'company') { ;
__p += '\n                <a href="#company/edit/' +
((__t = ( r.貸主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( r.貸主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n            </td>\n            <td>\n                ';
 if (r.借主_本人_type === 'individual') { ;
__p += '\n                <a href="#people/edit/' +
((__t = ( r.借主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( r.借主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } else if (r.借主_本人_type === 'company') { ;
__p += '\n                <a href="#company/edit/' +
((__t = ( r.借主_本人_id )) == null ? '' : __t) +
'">' +
((__t = ( r.借主_本人 )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n            </td>\n            <td>' +
((__t = ( r.assigned_to_name )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( r.initial_contact )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( r.物件名 )) == null ? '' : __t) +
' #' +
((__t = ( r.物件名_部屋番号 )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( numeral(r.split_bf).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( numeral(r.split_sf).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( numeral(r.split_fees).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td>' +
((__t = ( numeral(r.split_bf + r.split_sf + r.split_fees).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td>';
 if (!_.isEmpty(r.internal_memo)) { ;
__p += '\n                <a href="#" rel="popover" title="Memo" data-content="' +
((__t = ( r.internal_memo )) == null ? '' : __t) +
'"><i\n                        class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i></a>\n                ';
 } ;
__p += '\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
