import config from '@/js/app/config';
import { Collection } from 'backbone';
import GroupCompanyModel from './model';

export default Collection.extend({

    url: config.api.url + 'group_companies',

    model: GroupCompanyModel,

});