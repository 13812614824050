import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import Session from '@/js/app/session';
import template from '../templates/edit_people.html';

export default class CompanyPeopleView extends View {
    initialize() {
        // When collection syncs; render
        this.listenTo(this.collection, 'sync', this.render);
    }

    render() {
        console.debug('CompanyPeopleView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            people: this.collection.toJSON(),
            Formatter,
            Session,
        });

        return this;
    }
}
