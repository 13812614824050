import { View } from 'backbone';
import numeral from 'numeral';
import DivisionsCollection from '@/js/app/division/collections/divisions';
import * as Formatter from '@/js/app/formatter';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import mainTemplate from '../templates/kpi.html';
import editTemplate from '../templates/kpi_edit.html';
import readTemplate from '../templates/kpi_read.html';

export default class StaffEditKpiView extends View {
    preinitialize() {
        this.className = 'card';

        this.events = {
            'click [data-action="edit"]': this.handleEditClick,
            'click [data-action="cancel"]': this.handleCancelClick,
            'click [data-action="save"]': this.handleSaveClick,
        };
    }

    initialize() {
        this.listenTo(this.model, 'request', function () {
            this.el.querySelector('[data-part="read"]').classList.add('d-none');
            this.$el.find('[data-part="edit"]').addClass('d-none');
            this.el
                .querySelector('[data-part="loading"]')
                .classList.remove('d-none');
        });

        this.listenTo(this.model, 'sync error', function () {
            this.el
                .querySelector('[data-part="loading"]')
                .classList.add('d-none');
        });

        this.listenTo(this.model, 'sync', function () {
            this.el
                .querySelector('[data-part="read"]')
                .classList.remove('d-none');
        });

        this.listenTo(this.model, 'error', function () {
            this.$el.find('[data-part="edit"]').removeClass('d-none');
        });

        this.listenTo(this.model, 'change', this.renderReadTemplate);
    }

    render() {
        console.debug('StaffEditKpiView#render');

        this.el.innerHTML = mainTemplate({
            model: this.model.toJSON(),
            financialYear: Formatter.getFinancialYear(),
        });

        this.$el.find('[data-toggle="tooltip"]').tooltip();

        this.renderReadTemplate();

        return this;
    }

    renderReadTemplate() {
        console.debug('StaffEditKpiView#render');

        this.el.querySelector('#kpiTableBody').innerHTML = readTemplate({
            model: this.model.toJSON(),
            Formatter: Formatter,
        });
    }

    handleEditClick(e) {
        console.debug('StaffEditKpiView#handleEditClick');

        // Hide edit button
        e.currentTarget.classList.add('d-none');

        // Show save and cancel buttons
        this.$el.find('[data-part="edit"]').removeClass('d-none');

        // Render KPI table in edit mode
        this.el.querySelector('#kpiTableBody').innerHTML = editTemplate({
            model: this.model.toJSON(),
        });

        const divisions = new DivisionsCollection();

        // Loop through KPIs
        this.model.toJSON().kpis.forEach((kpi) => {
            // Create dynamic select view for current KPI
            const DivisionSelect = new UiDynamicSelectView({
                collection: divisions,
                mapper(model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                selected: kpi.division_id,
                prependOptions: [{ label: '', value: '' }],
            });

            // Render dynamic select and attach to appropriate row
            this.$el
                .find(
                    `tr[data-year="${kpi.year}"][data-month="${kpi.month}"] [data-slot="division"]`
                )
                .html(DivisionSelect.render().el);
        });

        // Fetch divisions
        divisions.fetch({
            data: {
                order: 'order',
            },
            reset: true,
        });
    }

    handleCancelClick() {
        console.debug('StaffEditKpiView#handleCancelClick');

        this.$el.find('[data-part="edit"]').addClass('d-none');
        this.el.querySelector('[data-part="read"]').classList.remove('d-none');

        this.renderReadTemplate();
    }

    handleSaveClick() {
        console.debug('StaffEditKpiView#handleSaveClick');

        const kpis = [];
        const staff_id = Number(this.model.staffId);

        this.el.querySelectorAll('tr[data-month]').forEach((tr) => {
            const selectEl = tr.querySelector('select');
            const division_id = Number(
                selectEl.options[selectEl.selectedIndex].value
            );

            const revenue = numeral(tr.querySelector('input').value).value();

            kpis.push({
                staff_id,
                year: tr.dataset.year,
                month: tr.dataset.month, // Month must be a string to keep leading 0
                division_id,
                revenue,
            });
        });

        this.model.save(
            { kpis },
            {
                wait: true,
            }
        );
    }
}
