module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="mt-3">\n    <div class="d-flex justify-content-between align-items-baseline">\n        <h1 class="h2 my-0">Dashboard</h1>\n\n        <h2 class="h4 my-0 text-right">\n            ' +
((__t = ( Formatter.getName(Session.data) )) == null ? '' : __t) +
' — ' +
((__t = ( moment().format('MMMM YYYY') )) == null ? '' : __t) +
'\n        </h2>\n    </div>\n</div>\n\n<hr>\n\n<div id="ytdReport"></div>\n';

}
return __p
};
