import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import template from '../templates/search_results.html';

export default class PropertyListResultsView extends View {
    preinitialize(options) {
        this.events = {
            'click ul.pagination a': this.changePage,
        };

        this.type = options.type || null;
        this.criteria = options.criteria || null;
    }

    initialize() {
        // When criteria changes; search
        this.listenTo(this.criteria, 'change', this.search);

        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    search() {
        console.debug('PropertyListResultsView#search');

        // Fetch collection
        this.collection.fetch({
            data: _.pick(this.criteria.toJSON(), (value) => {
                return value !== '';
            }),
            reset: true,
        });
    }

    changePage($e) {
        console.debug('PropertyListResultsView#changePage');

        $e.preventDefault();

        // Set criteria offset from clicked UI element
        this.criteria.set('offset', Number($e.target.dataset.offset));
    }

    render() {
        console.debug('PropertyListResultsView#render');

        this.el.innerHTML = template({
            lists: this.collection.toJSON(),
            criteria: this.criteria.toJSON(),
            total_rows: this.collection.total_rows,
            limit: this.criteria.get('limit'),
            offset: this.criteria.get('offset'),
            Formatter,
        });

        return this;
    }
}
