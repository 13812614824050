module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="mt-3">\n    <h1 class="h2 my-0">Staff Deal Report</h1>\n</div>\n\n<hr>\n\n<div class="row">\n    <div class="col-6" id="summary"></div>\n    <div class="col-6">\n        <form id="frm-report-criteria" name="report_criteria">\n            <div class="generate_form">\n                <fieldset>\n                    <legend>Report Setup</legend>\n\n                    <div class="form-group row">\n                        <label class="col-3 col-form-label">Date Range</label>\n                        <div class="col-9">\n                            <div class="input-group">\n                                <input class="form-control" name="date_from" type="date"\n                                    value="' +
((__t = ( criteria.date_from )) == null ? '' : __t) +
'">\n                                <div class="input-group-append input-group-prepend">\n                                    <span class="input-group-text">~</span>\n                                </div>\n                                <input class="form-control" name="date_to" type="date" value="' +
((__t = ( criteria.date_to )) == null ? '' : __t) +
'">\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class="form-group row">\n                        <label class="col-3 col-form-label">Agent</label>\n                        <div class="col-9" id="cntr-staff_id"></div>\n                    </div>\n                </fieldset>\n            </div>\n\n            <div class="form-group text-center">\n                <button type="submit" class="btn btn-primary px-5">Generate Report</button>\n            </div>\n        </form>\n    </div>\n</div>\n\n<div id="results"></div>';

}
return __p
};
