import { View } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import BloodhoundBmCompanyDataset from '@/js/app/bloodhound/datasets/bm_company';
import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import config from '@/js/app/config';
import { getCompanyName } from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Preferences from '@/js/app/preferences';
import propertyRestoreSelectedService from '@/js/app/property/services/restore-selected';
import PropertyLocationPickerView from '@/js/app/property/views/location-picker';
import Session from '@/js/app/session';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import { TypeaheadCompanyDefaults } from '@/js/app/typeahead/defaults';
import UiTypeaheadView from '@/js/app/ui/views/typeahead';
import template from '../templates/search.html';
import SearchResultsView from './search-results';

export default class BuildingsSearchView extends View {
    preinitialize(options) {
        this.options = options || {};
        this.options.bulkedit = true;

        this.events = {
            'submit #search form': this.submitSearch,
            'click [data-action="reset"]': this.handleReset
        };

        this.subviews = {
            locationPickerView: new PropertyLocationPickerView(),
            results: new SearchResultsView({
                format: ('undefined' !== typeof this.options.mode ? this.options.mode : 'long'),
                buttons: {
                    type: 'building',
                    add_to_list: true,
                    send: true,
                    print: true,
                    add_to_viewing: true,
                    bulkedit: false,
                },
            }),
            agentCompanyTypeahead: new UiTypeaheadView({
                id: 'field-agent_company',
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundCompanyDataset)
                ]
            }),
            bmCompanyTypeahead: new UiTypeaheadView({
                id: 'field-bm_company',
                options: TypeaheadCompanyDefaults,
                datasets: [
                    typeaheadCompanyDatasource(BloodhoundBmCompanyDataset)
                ]
            }),
        };
    }

    initialize() {
        this.render();

        /* reset the selected property list */
        propertyRestoreSelectedService([]);
    }

    render() {
        this.el.innerHTML = template({
            options: this.options,
            Session,
            Preferences,
        });

        // Expand results container to take full width of screen
        const resultsContainer = this.el.querySelector('.container-results');
        resultsContainer.style.width = `${$(document).width() - 16}px`;
        resultsContainer.style.marginLeft = `-${$('div.container').offset().left + 8}px`;

        this.subviews.agentCompanyTypeahead.setElement(this.el.querySelector('#cntr-agent_company_id')).render();
        this.subviews.bmCompanyTypeahead.setElement(this.el.querySelector('#cntr-bm_company_id')).render();

        // Add placeholder to typeahead fields
        this.$el.find('.tt-input').prop('placeholder', '\uf002');

        this.subviews.locationPickerView.setElement(this.el.querySelector('#location')).render();

        // Get search criteria from sessionStorage
        this.buildingSearchCriteria = JSON.parse(sessionStorage.getItem(
            'buildingSearchCriteria'
        ));

        const promises = [];

        // If search conditions exists
        if (this.buildingSearchCriteria) {
            const { agent_company, bm_company } = this.buildingSearchCriteria;

            populateForm(this.el.querySelector('form'), this.buildingSearchCriteria);

            if (agent_company || bm_company) {
                const query = {
                    id: []
                }

                // If ID exists, push to query.id array
                agent_company && query.id.push(agent_company);
                bm_company && query.id.push(bm_company);

                const url = new URL(config.api.url + 'companies');
                url.search = jQuery.param(query);

                // Fetch information for given staff ID
                const fetchCompanies = OAuth2Client.fetchJSON(url, {})
                    .then((response) => {
                        response.results.forEach((result) => {
                            if (result.id === agent_company) {
                                this.subviews.agentCompanyTypeahead.selectDatum(result);
                                this.subviews.agentCompanyTypeahead.setValue(getCompanyName(result));
                            }

                            if (result.id === bm_company) {
                                this.subviews.bmCompanyTypeahead.selectDatum(result);
                                this.subviews.bmCompanyTypeahead.setValue(getCompanyName(result));
                            }
                        })

                        // Trigger click on submit
                        this.$el.find('#search form').trigger('submit');
                    });

                promises.push(fetchCompanies);
            }
        }

        Promise.all(promises).then(() => {
            // Trigger submit
            this.$el.find('#search form').trigger('submit');
        })

        return this;
    }

    submitSearch($e) {
        $e.preventDefault();

        // Build query
        let query = {
            sort_key: Preferences.sort_key,
            sort_order: Preferences.sort_order,
            limit: Preferences.limit,
        };

        const form = this.el.querySelector('form');

        // Get criteria from search form
        const formData = formToObject(form, true);

        query = {
            ...query,
            ...formData
        }

        // If search form has selections
        if (!_.isEmpty(this.subviews.locationPickerView.selectedLocations)) {
            switch (this.subviews.locationPickerView.locationType) {
                case 'wards_and_neighborhoods': {
                    // Get cities and neighborhoods, and add as conditions
                    const locations = this.subviews.locationPickerView.getWardsNeighborhoods();
                    query.city = locations.cities;
                    query.neighborhood = locations.neighborhoods;
                    break;
                }

                case 'train_lines_and_stations':
                    // Get stations, and add as "landmark" condition
                    query.landmark = this.subviews.locationPickerView.getStations();
                    query.group_by = 'id';
                    break;

                case 'custom_areas':
                    // Get custom areas, and add as "distance from" condition
                    query.distance_from = this.subviews.locationPickerView.getCustomAreas();
                    break;
            }
        }

        // If company selected, add datum id
        if (this.subviews.agentCompanyTypeahead.datum) {
            query.agent_company = this.subviews.agentCompanyTypeahead.datum.id;
        }

        // If company selected, add datum id
        if (this.subviews.bmCompanyTypeahead.datum) {
            query.bm_company = this.subviews.bmCompanyTypeahead.datum.id;
        }

        // $(window).scrollTop($('.mini-layout h2').offset().top - 50);

        // Reset the selected property list
        propertyRestoreSelectedService([]);

        // Set query details on subview
        this.subviews.results.query = query;

        sessionStorage.setItem('buildingSearchCriteria', JSON.stringify(query));

        // Set element and render
        this.subviews.results.setElement(this.el.querySelector('#results')).render().fetch();
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('form').reset();

        this.el.querySelectorAll('input[type="checkbox"]').forEach((input) => {
            input.checked = false
        });

        this.subviews.agentCompanyTypeahead.clear();
        this.subviews.bmCompanyTypeahead.clear();

        sessionStorage.removeItem('buildingSearchCriteria');
    }
}