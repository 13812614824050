import { Collection } from 'backbone';
import config from '@/js/app/config';
import InquiryModel from '../models/inquiry';

export default Collection.extend({

    url: config.api.url + 'inquiries',

    model: InquiryModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});