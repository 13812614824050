import { Model } from 'backbone';
import config from '@/js/app/config';

export default Model.extend({

    urlRoot: config.api.url + 'applications',

    // Default attributes for the application item
    defaults: function() {
        return {
            id: null,
            type: null,
            status: null,
            addressbook_id: null,
            property_id: null,
            assigned_to: null,
            物件名: null,
            物件名_部屋番号: null,
            賃貸条件_賃料_月額: null,
            賃貸条件_Offer: null,
            代金_総額_円: null,
            代金_内訳_土地_円: null,
            代金_内訳_建物_円: null,
            代金_内訳_内消費税_円: null,
            代金_内訳_付属物_円: null,
            代金_内訳_付属物_内消費税_円: null,
            // created_at: null,
            // updated_at: null,
        };
    },

});