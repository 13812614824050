import { history, View } from 'backbone';
import template from '../templates/modal_new.html';

export default class CompanyNewView extends View {
    preinitialize() {
        this.id = 'modal-new_company';

        this.className = 'modal fade';

        this.events = {
            'shown.bs.modal': this.buildup,
            'hidden.bs.modal': this.teardown,
            'submit form': this.create,
        };
    }

    initialize() {
        this.listenTo(this.model, 'invalid', this.displayError);
    }

    render() {
        console.debug('CompanyNewView#render');

        // Render template
        this.el.innerHTML = template();

        // Trigger modal display
        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });
    }

    buildup() {
        console.debug('CompanyNewView#buildup');

        // Set focus
        this.el.querySelector('[name="company_name"]').focus();
    }

    create($e) {
        console.debug('CompanyNewView#create');

        $e.preventDefault();

        const data = {
            company_name: this.el.querySelector('#field-company_name').value,
        };

        // Save new company
        this.model.save(data).then((response) => {
            // Hide modal
            this.$el.modal('hide');

            // Redirect to edit company
            history.navigate('company/edit/' + response.id, { trigger: true });
        });
    }

    displayError(model, error) {
        console.debug('CompanyNewView#displayError');

        _.each(error, (v, k) => {
            this.$el
                .find('[name="' + k + '"]')
                .closest('.form-group')
                .addClass('is-invalid');
        });
    }

    teardown() {
        console.debug('CompanyNewView#teardown');

        this.$el.data('modal', null);

        // Remove view
        this.remove();
    }
}
