module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


const date_from = moment(criteria.from_year + '-' + criteria.from_month + '-01').format('YYYY-MM-DD');
const date_to = moment(criteria.to_year + '-' + criteria.to_month + '-01').endOf('month').format('YYYY-MM-DD');
;
__p += '\n\n<h2 class="h3">Staff Breakdown</h2>\n\n<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>Division</th>\n            <th>Agent</th>\n            <th class="text-right">Revenue</th>\n            <th class="text-right">Target</th>\n            <th class="text-right">%</th>\n            <th class="text-right">Total Revenue</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';
 results.forEach((division) => {
            let i = 1;
            _.each(division.staff, function(s, s_id) {
                const pct = s.kpis.revenue.target === 0 ? 0 : Math.floor(s.kpis.revenue.actual / s.kpis.revenue.target * 100);
        ;
__p += '\n        <tr>\n            ';
 if (i === 1) { ;
__p += '\n            <td rowspan="' +
((__t = ( _.size(division.staff) )) == null ? '' : __t) +
'" style="vertical-align: middle">' +
((__t = ( division.name )) == null ? '' : __t) +
'</td>\n            ';
 } ;
__p += '\n            <td>\n                <a href="#reports/commission/' +
((__t = ( s_id )) == null ? '' : __t) +
'/' +
((__t = ( date_from )) == null ? '' : __t) +
'/' +
((__t = ( date_to )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( Formatter.getName(s) )) == null ? '' : __t) +
'\n                </a>\n            </td>\n            <td class="text-right">' +
((__t = ( numeral(s.kpis.revenue.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td class="text-right">' +
((__t = ( numeral(s.kpis.revenue.target).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td class="text-right';
 if (s.kpis.revenue.target > 0) { ;
__p += ' color ' +
((__t = ( pct < 100 ? 'bad' : 'good' )) == null ? '' : __t);
 } ;
__p += '">\n                ' +
((__t = ( pct )) == null ? '' : __t) +
'%</td>\n            ';
 if (i === 1) { ;
__p += '\n            <td rowspan="' +
((__t = ( _.size(division.staff) )) == null ? '' : __t) +
'" class="text-right" style="vertical-align: middle">\n                ' +
((__t = ( numeral(division.kpis.revenue.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n            ';
 } ;
__p += '\n        </tr>\n        ';
 i++;
            });
        }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
