import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import ViewingModel from '@/js/app/viewing/models/viewing';
import ViewingNewView from '@/js/app/viewing/views/new';
import template from '../templates/edit_viewings.html';

export default class PeopleEditViewingsView extends View {
    preinitialize(options) {
        this.events = {
            'click [data-action="new"]': this.new,
        };

        this.person = options.person;
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'new');

        // When collection updates/resets/changes; render
        this.listenTo(this.collection, 'update reset change', this.render);
    }

    render() {
        console.debug('PeopleEditViewingsView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            viewings: this.collection.toJSON(),
            Formatter,
        });

        return this;
    }

    new() {
        console.debug('PeopleEditViewingsView#new');

        // Create new view, with new viewing model
        new ViewingNewView({
            model: new ViewingModel({
                addressbook_id: this.person.id,
            }),
            collection: this.collection,
        }).render();
    }
}
