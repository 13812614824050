import { Collection } from 'backbone';
import config from '@/js/app/config';
import DealModel from '../models/deal';

const DealCollection = Collection.extend({

    url: config.api.url + 'deals',

    model: DealModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});

export default DealCollection;
