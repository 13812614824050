module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3 mt-0">Company Summary</h2>\n\n<canvas class="chart" id="chart_company_summary" width="400" height="250"></canvas>\n\n<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>Division</th>\n            <th class="text-right">Revenue</th>\n            <th class="text-right">Target</th>\n            <th class="text-right">%</th>\n        </tr>\n    </thead>\n\n    <tbody>\n        ';

        results.forEach((division) => {
            // Skip divisions with no staff
            if (division.staff.length === 0) {
                return;
            }

            const pct = division.kpis.revenue.target === 0 ? 0 : Math.floor(division.kpis.revenue.actual / division.kpis.revenue.target * 100);
        ;
__p += '\n        <tr>\n            <td>' +
((__t = ( division.name )) == null ? '' : __t) +
'</td>\n            <td class="text-right">' +
((__t = ( numeral(division.kpis.revenue.actual).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td class="text-right">' +
((__t = ( numeral(division.kpis.revenue.target).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td class="text-right color ' +
((__t = ( pct < 100 ? 'bad' : 'good' )) == null ? '' : __t) +
'">' +
((__t = ( pct )) == null ? '' : __t) +
'%</td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n\n    <tfoot>\n        ';
 const pctTotal = targetTotal === 0 ? 0 : Math.floor(grandTotal / targetTotal * 100) ;
__p += '\n        <tr class="total">\n            <td><strong>Totals</strong></td>\n            <td class="text-right">' +
((__t = ( numeral(grandTotal).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td class="text-right">' +
((__t = ( numeral(targetTotal).format('0,0') )) == null ? '' : __t) +
'</td>\n            <td class="text-right color ' +
((__t = ( pctTotal < 100 ? 'bad' : 'good' )) == null ? '' : __t) +
'">' +
((__t = ( pctTotal )) == null ? '' : __t) +
'%</td>\n        </tr>\n    </tfoot>\n</table>';

}
return __p
};
