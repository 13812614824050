module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if ('undefined' !== typeof inquiries && inquiries.length > 0) { ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Timestamp</th>\n            <th>Type</th>\n            <th>Property</th>\n            <th>Page / Message</th>\n            <th>Country</th>\n            <th></th>\n        </tr>\n    </thead>\n\n    <tbody>\n    ';
 inquiries.forEach((i) => { ;
__p += '\n        <tr>\n            <td>\n                ' +
((__t = ( moment.utc(i.created_at).local().format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'\n            </td>\n            <td>\n                ' +
((__t = ( TextHelper._(i.inquiry_type, 'inquiry_type') )) == null ? '' : __t) +
'\n            </td>\n            <td>\n            ';
 if (i.property) { ;
__p += '\n                <a href="' +
((__t = ( Formatter.property_edit_url(i.property) )) == null ? '' : __t) +
'">' +
((__t = ( i.property.structure_name_en || '' )) == null ? '' : __t) +
'</a>\n            ';
 } else { ;
__p += '\n                -\n            ';
 } ;
__p += '\n            </td>\n            <td>\n            ';
 if (i.search) { ;
__p += '\n                <p>\n                    <a href="#property/search/' +
((__t = ( i.search.id )) == null ? '' : __t) +
'">' +
((__t = ( propertyGetTitleService(i.search) )) == null ? '' : __t) +
'</a>\n                </p>\n            ';
 } else if (i.meta.page_title && i.meta.page_url) { ;
__p += '\n                <p>\n                    <a href="' +
((__t = ( i.meta.page_url )) == null ? '' : __t) +
'" target="_new">' +
((__t = ( i.meta.page_title )) == null ? '' : __t) +
'</a>\n                </p>\n            ';
 } ;
__p += '\n\n                <p class="text-emphasis my-0">\n                    ' +
((__t = ( Formatter.nl2br(_.escape(i.message)) )) == null ? '' : __t) +
'\n                </p>\n            </td>\n            <td>\n                <abbr title="' +
((__t = ( Formatter.countryName(i.country_code) )) == null ? '' : __t) +
'">' +
((__t = ( i.country_code || '-' )) == null ? '' : __t) +
'</abbr>\n            </td>\n            <td>\n                <a href="#inquiries/' +
((__t = ( i.id )) == null ? '' : __t) +
'" class="btn btn-secondary">View</a>\n            </td>\n        </tr>\n    ';
 }); ;
__p += '\n    </tbody>\n</table>\n';
 } else { ;
__p += '\n<p>Client has not made any enquiries.</p>\n';
 } ;


}
return __p
};
