import { history, View } from 'backbone';
import template from '../templates/modal_new.html';

export default class PropertyListNewView extends View {
    preinitialize(options) {
        this.id = 'modal-new_list';
        this.className = 'modal fade';
        this.events = {
            'hidden.bs.modal': this.teardown,
            'submit form': this.create,
        };

        this.type = options.type || '';
        this.visibility = options.visibility || '';
    }

    initialize() {
        this.listenTo(this.model, 'invalid', this.displayError);
    }

    render() {
        console.debug('PropertyListNewView#render');

        // Render template
        this.el.innerHTML = template({
            type: this.type,
            visibility: this.visibility,
        });

        // Trigger modal display
        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });

        // Set focus
        this.el.querySelector('[name="name"]').focus();
    }

    create($e) {
        console.debug('PropertyListNewView#create');

        $e.preventDefault();

        // Get form fields
        const name = this.el.querySelector('#field-name');
        const data = {
            [name.name]: name.value,
        };

        // Save new property list
        this.model.save(data).then((response) => {
            if (this.collection) {
                // Add model to collection
                this.collection.add(this.model);
            } else {
                // Redirect to edit list
                history.navigate('property/lists/edit/' + response.id, {
                    trigger: true,
                });
            }

            // Hide modal
            this.$el.modal('hide');
        });
    }

    displayError(model, error) {
        console.debug('PropertyListNewView#displayError');

        _.each(error, (v, k) => {
            this.$el
                .find('[name="' + k + '"]')
                .closest('.form-group')
                .addClass('is-invalid');
        });
    }

    teardown() {
        console.debug('PropertyListNewView#teardown');

        this.$el.data('modal', null);

        // Remove view
        this.remove();
    }
}
