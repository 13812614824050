export const _source = {
    criteria_bedrooms: {
        'rent_bedroom_0': 'Studio',
        'rent_bedroom_1': '1',
        'rent_bedroom_2': '2',
        'rent_bedroom_3': '3',
        'rent_bedroom_4': '4+',
        'sales_bedroom_0': 'Studio',
        'sales_bedroom_1': '1',
        'sales_bedroom_2': '2',
        'sales_bedroom_3': '3',
        'sales_bedroom_4': '4+',
    },
    inquiry_type: {
        'general': 'General',
        'rent': 'Leasing',
        'commercial': 'Commercial',
        'sales': 'Sales',
        'investment': 'Investment',
        'short-term': 'Residential Hospitality',
        'property-management': 'Property Management',
        'resorts': 'Resorts',
        'resorts/hakuba': 'Resorts - Hakuba',
        'resorts/niseko': 'Resorts - Niseko',
        'resorts/other': 'Resorts - Other',
        'raintree_na': 'Raintree Nishiazabu',
    },
    structure_type: {
        'building': 'Multi-unit Building',
        'unit': 'Unit',
        'house': 'House',
        'land': 'Land',
    },
    pets: {
        'no': 'No Pets',
        'negotiable': 'Negotiable',
        'small': 'Small',
        'large': 'Large',
        'yes': 'OK',
    },
    availability_status: {
        'tba': 'TBA / 相談',
        'available': 'Available / 販売中 / 即日',
        'app_self': '自社買付',
        'app_other': '他社買付',
        'to_contract': '契約予定',
        'to_settlement': '決済予定',
        'unavailable': 'Unavailable / 売り止め',
        'app_1': 'Application 1',
        'app_2': 'Application 2',
        'app_3': 'Application 3',
    },
    ownership: {
        none: '無',
        management: '有(管理組合・管理会社と別途契約)',
        owner: '有(甲所有)',
    },
    viewing_type: {
        no: '不可',
        accompanied: '可（立会）',
        key_loan: '可（鍵貸）',
        free: '可（自由）',
        negotiable: '相談',
    },
    tax_type: {
        exempt: '非課税',
        included: '税込',
    },
    vacancy_status: {
        'second': 'セカンド',
        'occupied': '居住中',
        'rented': '賃貸中',
        'vacant': '空室',
    },
    country: {
        'AU': 'Australia',
        'AT': 'Austria',
        'BD': 'Bangladesh',
        'BE': 'Belgium',
        'BR': 'Brazil',
        'KH': 'Cambodia',
        'CA': 'Canada',
        'CN': 'China',
        'DK': 'Denmark',
        'FI': 'Finland',
        'FR': 'France',
        'DE': 'Germany',
        'GR': 'Greece',
        'HK': 'Hong Kong',
        'IN': 'India',
        'ID': 'Indonesia',
        'IE': 'Ireland',
        'IT': 'Italy',
        'JP': 'Japan',
        'KR': 'Korea',
        'MO': 'Macau',
        'MY': 'Malaysia',
        'MX': 'Mexico',
        'NP': 'Nepal',
        'NL': 'Netherlands',
        'NZ': 'New Zealand',
        'NO': 'Norway',
        'PK': 'Pakistan',
        'PH': 'Philippines',
        'PL': 'Poland',
        'PT': 'Portugal',
        'RO': 'Romania',
        'RU': 'Russia',
        'SG': 'Singapore',
        'ZA': 'South Africa',
        'ES': 'Spain',
        'LK': 'Sri Lanka',
        'SE': 'Sweden',
        'CH': 'Switzerland',
        'TW': 'Taiwan',
        'TH': 'Thailand',
        'UK': 'UK',
        'US': 'United States',
        'VN': 'Vietnam',
    },
}

export function _(identifier, context) {
    if (_source[context] === undefined || _source[context][identifier] === undefined) {
        return identifier;
    }

    return _source[context][identifier];
}

export function listMonths(nameFormat) {
    nameFormat = nameFormat || 'MMMM';

    // Generate valid months
    const months = [];
    const m = moment();
    for (let i = 0; i < 12; i++) {
        m.month(i);
        months.push({
            number: m.format('MM'),
            name: m.format(nameFormat)
        });
    }

    return months;
}

export function fyPeriod(year) {
    const dates = [
        moment((year - 1) + '-04-01'),
    ];

    for (let i = 1; i <= 11; i++) {
        // Clone first date and add X months
        dates.push(dates[0].clone().add(i, 'month'));
    }

    return dates;
}

export function saleStatus(availabilityStatus) {
    switch (availabilityStatus) {
        case 'tba':
            return '相談';
        case 'available':
            return '販売中';
        case 'app_self':
            return '自社買付';
        case 'app_other':
            return '他社買付';
        case 'to_contract':
            return '契約予定';
        case 'to_settlement':
            return '決済予定';
        case 'unavailable':
            return '売り止め';
        default:
            return '-';
    }
}