import { Collection } from 'backbone';
import config from '@/js/app/config';
import CompanyModel from '../models/company';

export default Collection.extend({

    url: config.api.url + 'companies',

    model: CompanyModel,

    parse: function(response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});