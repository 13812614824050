import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import template from '../templates/results.html';

export default class ReportRevenueResultsView extends View {
    preinitialize(options) {
        this.criteria = options.criteria;
    }

    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ReportRevenueResultsView#render');

        this.el.innerHTML = template({
            criteria: this.criteria,
            results: this.model.toJSON(),
            Formatter,
        });

        return this;
    }
}
