import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import * as TextHelper from '@/js/app/text-helper';
import template from '../templates/search_result.html';

export default class InquiryResultView extends View {
    preinitialize() {
        this.tagName = 'tr';
    }

    render() {
        console.debug('InquiryResultView#render');

        this.el.innerHTML = template({
            inquiry: this.model.attributes,
            TextHelper,
            Formatter,
        });

        return this;
    }
}
