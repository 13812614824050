import config from '@/js/app/config';
import { Collection } from 'backbone';
import RevenueModel from '../models/revenue';

const RevenueCollection = Collection.extend({

    url: config.api.url + 'revenue',

    model: RevenueModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },

});

export default RevenueCollection;
