import { View } from 'backbone';
import BuildingSearchResultsView from '@/js/app/building/views/search-results';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Preferences from '@/js/app/preferences';
import propertyRestoreSelectedService from '../services/restore-selected';
import template from '../templates/send.html';
import PropertySearchResultsView from './search-results';

export default class PropertySendView extends View {
    preinitialize(options) {
        this.events = {
            'change [name="secret_url_no_expiration"]': this.handleChangeNoExpiration,
            'click #generate_secret_url': this.generateLink,
        };

        this.type = options.type || '';

        this.selected = propertyRestoreSelectedService();

        this.query = {
            sort_key: Preferences.sort_key,
            sort_order: Preferences.sort_order,
            limit: 1000,
            id: this.selected,
        };

        let searchResultsView;
        if (this.type === 'building') {
            searchResultsView = new BuildingSearchResultsView({
                query: this.query,
                format: 'short-send',
            });
        } else {
            searchResultsView = new PropertySearchResultsView({
                query: this.query,
                format: 'short-send',
            });
        }

        this.subviews = {
            searchResultsView
        };
    }

    initialize() {
        this.render();

        if (this.selected.length > 0) {
            this.subviews.searchResultsView.fetch();
        }
    }

    render() {
        console.debug('PropertySendView#render');

        this.el.innerHTML = template();

        // Attach and render subview
        this.subviews.searchResultsView.setElement(this.el.querySelector('#results')).render();

        // Zumen URL (en)
        const urlEn = Formatter.zumen_url(this.type, this.query.id, 'en');
        this.$el.find('a.zumen_url_en').prop('href', urlEn);
        this.$el.find('a.zumen_url_en').text(urlEn);

        // Zumen URL (ja)
        const urlJa = Formatter.zumen_url(this.type, this.query.id, 'ja');
        this.$el.find('a.zumen_url_ja').prop('href', urlJa);
        this.$el.find('a.zumen_url_ja').text(urlJa);

        // Show secret URL panel
        this.$el.find('#expiration').removeClass('d-none');

        return this;
    }

    handleChangeNoExpiration(e) {
        console.debug('PropertySendView#handleChangeNoExpiration');

        this.toggleNoExpiration(e.currentTarget.checked)
    }

    toggleNoExpiration(disabled) {
        console.debug('PropertySendView#toggleNoExpiration');

        // Toggle expiration date
        this.el.querySelector('[name="secret_url_expiration"]').disabled = disabled;
    }

    handleClickGenerateLink() {
        console.debug('PropertySendView#handleClickGenerateLink');

        this.generateLink();
    }

    generateLink() {
        console.debug('PropertySendView#generateLink');

        // Get expiration date
        let expiration = null;
        if (this.el.querySelector('[name="secret_url_no_expiration"]').checked === false) {
            expiration = this.el.querySelector('input[name="secret_url_expiration"]').value;
        }

        // Build criteria
        const criteria = {};
        if (this.type === 'building') {
            criteria.building_ids = this.query.id;
        } else {
            criteria.unit_ids = this.query.id;
        }

        // Send POST request to create link
        OAuth2Client.fetchJSON(config.api.url + 'search_links', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                criteria,
                expires_at: expiration,
            }),
        })
            .then((response) => {
                // Generate URL
                const propertyType = response.criteria.property_type;
                const investment = Number(response.criteria.investment);
                const url_en = Formatter.public_search_url('en', propertyType, investment, response.id);
                const url_ja = Formatter.public_search_url('ja', propertyType, investment, response.id);
                const secretUrl = this.el.querySelector('#secret_urls');

                // Set URL for secret links
                const secretUrlEn = secretUrl.querySelector('a.secret_url_en');
                secretUrlEn.href = url_en;
                secretUrlEn.innerText = url_en;

                const secretUrlJa = secretUrl.querySelector('a.secret_url_ja');
                secretUrlJa.href = url_ja;
                secretUrlJa.innerText = url_ja;

                // Show expiration date
                this.el.querySelector('#expiration').innerText = 'Expires: ' + (expiration || '-');

                // Show secret URLs
                secretUrl.classList.remove('d-none');
            })
            .catch((err) => {
                alert(err.message);
            });
    }
}