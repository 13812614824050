module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card">\n    <div class="card-header">\n        <h2 class="card-title h4 mb-0"><i class="fa fa-address-card-o" aria-hidden="true"></i> My Client\'s Activity</h2>\n    </div>\n\n    ';
 if (activities.length === 0) { ;
__p += '\n    <div class="card-body">\n        <p style="margin: 1em">No recent activity from your assigned clients.</p>\n    </div>\n    ';
 } else { ;
__p += '\n    <table class="table table-sm">\n        <tbody>\n            ';
 activities.forEach((activity) => { ;
__p += '\n            <tr>\n                <td>' +
((__t = ( activity.icon )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( activity.message )) == null ? '' : __t) +
'</td>\n                <td title="' +
((__t = ( activity.log.timestamp.format('YYYY-MM-DD HH:mm:ss') )) == null ? '' : __t) +
'">' +
((__t = ( activity.log.timestamp.format('YY/MM/DD HH:mm') )) == null ? '' : __t) +
'</td>\n            </tr>\n            ';
 }); ;
__p += '\n        </tbody>\n    </table>\n    ';
 } ;
__p += '\n\n    ';
 if ('undefined' !== typeof addresses && 0 < addresses.length) { ;
__p += '\n    <div class="card-footer">\n        <a href="#clients/mine">Full List</a>\n    </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};
