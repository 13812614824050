module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Results</h2>\n';
 if ('undefined' !== typeof people && people.length > 0) { ;
__p += '\n';
 if (Session.isAllowed('phnx:people:exp') || Number(criteria.assigned_staff_id) == Session.data.user_id) { ;
__p += '\n<button type="button" data-action="export" class="btn btn-secondary mb-3">Export CSV</button>\n';
 } ;
__p += '\n<table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>ID</th>\n            <th>Name</th>\n            <th>Type</th>\n            <th>Company</th>\n            <th>Nationality</th>\n            <th>Living in</th>\n            <th>Property<br>type</th>\n            <th>Max<br>Budget</th>\n            <th>Bdrm</th>\n            <th>Assigned to</th>\n            <th>Last replied</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 people.forEach((p) => { ;
__p += '\n            <tr data-id="' +
((__t = ( p.id )) == null ? '' : __t) +
'">\n                <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( p.id )) == null ? '' : __t) +
'</a></td>\n                <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(p) )) == null ? '' : __t) +
'</a></td>\n                <td>' +
((__t = ( Formatter.determinePersonType(p) )) == null ? '' : __t) +
'</td>\n                <td>\n                    ';
 if (p.company) { ;
__p += '\n                        <a href="#company/edit/' +
((__t = ( p.company.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getCompanyName(p.company) )) == null ? '' : __t) +
'</a>\n                    ';
 } ;
__p += '\n                </td>\n                <td>' +
((__t = ( Formatter.countryName(p.nationality) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.countryName(p.country) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaStructureType(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaBudget(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.criteriaBedrooms(p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( Formatter.getAssignedStaff(criteria, p) )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( p.last_replied_date )) == null ? '' : __t) +
'</td>\n            </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n';
 } else { ;
__p += '\n<p>No people match your search query.</p>\n';
 } ;


}
return __p
};
