import { View } from 'backbone';
import template from '../templates/modal_new.html';

export default class ViewingNewView extends View {
    preinitialize() {
        this.id = 'modal-new_viewing';

        this.className = 'modal fade';

        this.events = {
            'hidden.bs.modal': this.teardown,
            'submit form': this.create,
        };
    }

    initialize() {
        this.listenTo(this.model, 'invalid', this.dispalyError);
    }

    render() {
        console.debug('ViewingNewView#render');

        // Render template
        this.el.innerHTML = template();

        // Trigger modal display
        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });

        // Set focus
        this.el.querySelector('[name="viewing_date"]').focus();
    }

    create($e) {
        console.debug('ViewingNewView#save');

        $e.preventDefault();

        // Get viewing date and notes
        let viewing_date = this.el.querySelector('#field-viewing_date').value;
        const notes = this.el.querySelector('#field-notes').value;

        if (viewing_date === '') {
            viewing_date = null;
        }

        // Get field values and set in model
        this.model
            .save({
                viewing_date: viewing_date,
                notes: notes,
            })
            .then(() => {
                // Add model to collection
                if (this.collection) {
                    this.collection.add(this.model);
                }

                // Hide modal
                this.$el.modal('hide');
            });
    }

    displayError(model, error) {
        console.debug('ViewingNewView#displayError');

        _.each(error, (v, k) => {
            this.$el
                .find('[name="' + k + '"]')
                .closest('.form-group')
                .addClass('is-invalid');
        });
    }

    teardown() {
        console.debug('ViewingNewView#teardown');

        this.$el.data('modal', null);

        // Remove view
        this.remove();
    }
}
