module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form id="frm-report-criteria">\n    <fieldset>\n        <div class="row align-items-center mb-3">\n            <div class="col-6">\n                <div class="form-group row mb-0">\n                    <label class="col-4 col-form-label">Division</label>\n                    <div id="cntr-division" class="col-auto">\n                    </div>\n                </div>\n            </div>\n\n            <div class="col-4">\n                <div class="form-group row mb-0">\n                    <label class="col-6 col-form-label">Financial Year</label>\n                    <div class="col-auto">\n                        <select class="form-control" name="fy">\n                            ';
 for (const y of years) { ;
__p += '\n                            <option value="' +
((__t = ( y )) == null ? '' : __t) +
'">' +
((__t = ( y )) == null ? '' : __t) +
'</option>\n                            ';
 } ;
__p += '\n                        </select>\n                    </div>\n                </div>\n            </div>\n\n            <div class="col-2">\n                <div id="spinner" class="spinner-border text-primary d-none" role="status">\n                    <span class="sr-only">Loading...</span>\n                </div>\n            </div>\n        </div>\n    </fieldset>\n</form>\n\n<div id="results"></div>';

}
return __p
};
