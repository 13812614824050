import { history, View } from 'backbone';
import populateForm from '@/js/libs/populate-form';
import BuildingSearchResultsView from '@/js/app/building/views/search-results';
import config from '@/js/app/config';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import propertyRestoreSelectedService from '@/js/app/property/services/restore-selected';
import PropertySearchResultsView from '@/js/app/property/views/search-results';
import PropertyListModel from '../models/property-list';
import template from '../templates/edit.html';

export default class PropertyListEditView extends View {
    preinitialize(options) {
        this.events = {
            'change form input,form select,form textarea': this.updateModel,
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="remove-property"]': this.removeProperty,
            'click button[data-action="download-comparison"]': this.downloadComparison,
        };

        this.model = new PropertyListModel({
            id: options.propertyListId,
        });
    }

    initialize() {
        // Functions that will trigger from DOM events need to bind to "this"
        _.bindAll(this, 'render', 'updateModel', 'delete', 'removeProperty', 'downloadComparison');

        this.render();

        this.listenTo(this.model, 'change:name', this.updateName);

        // When slug changes, update link
        this.listenTo(this.model, 'change:slug', this.updateLink);

        // Trigger fetch, and call "render" on success
        this.model.fetch({
            data: {
                include: ['created_by'],
            },
            silent: true,	// Mark: I know this is not recommended, but we need to get data into this model without triggering any "change" events
            success: this.render,
        });
    }

    render() {
        console.debug('PropertyListEditView#render');

        const list = this.model.toJSON();

        this.el.innerHTML = template({
            list,
        });

        populateForm(this.el, list);
        this.el.querySelector('#listName').innerText = list.name;
        if (list.created_by) {
            this.el.querySelector('#field-created_by').value = Formatter.getName(list.created_by);
        }
        this.el.querySelector('#field-created_at').value = moment.utc(list.created_at).local().format('YYYY-MM-DD HH:mm:ss');
        this.el.querySelector('#field-url').value = Formatter.list_url(list);

        propertyRestoreSelectedService([]);

        // If list has properties
        if (list.properties && list.properties.length > 0) {
            // Show properties
            new PropertySearchResultsView({
                query: {
                    order: 'updated_at DESC',
                    limit: 1000,
                    include: ['agent_company'],
                    id: list.properties,
                },
                format: 'long',
                el: this.el.querySelector('#unit_results'),
                buttons: {
                    type: 'unit',
                    add_to_list: true,
                    remove_from_list: true,
                    send: true,
                    print: true,
                    add_to_viewing: true,
                    download_comparison: true,
                },
            }).render().fetch();
        }

        // If list has buildings
        if (list.buildings && list.buildings.length > 0) {
            // Show buildings
            new BuildingSearchResultsView({
                query: {
                    order: 'updated_at DESC',
                    limit: 1000,
                    include: ['agent_company'],
                    id: list.buildings,
                },
                format: 'long',
                el: this.el.querySelector('#building_results'),
                buttons: {
                    type: 'building',
                    add_to_list: true,
                    remove_from_list: true,
                    send: true,
                    print: true,
                    add_to_viewing: true,
                    download_comparison: true,
                },
            }).render();
        }

        return this;
    }

    updateName(model, value) {
        console.debug('PropertyListEditView#updateName');

        // Build new list URL and set as value of URL field
        this.el.querySelector('#listName').innerText = value;
    }

    updateLink(model) {
        console.debug('PropertyListEditView#updateLink');

        // Build new list URL and set as value of URL field
        this.el.querySelector('#field-url').value = Formatter.list_url(model.toJSON());
    }

    updateModel($e) {
        console.debug('PropertyListEditView#updateModel');

        // Determine new value
        const t = $e.currentTarget;
        let value;

        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else; use value or null
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.save(t.name, value, { patch: true, wait: true });
    }

    delete() {
        console.debug('PropertyListEditView#delete');

        if (confirm('Are you sure you want to delete this list? This cannot be undone!')) {
            this.model.destroy({
                success: function () {
                    history.navigate('property/lists/mine', { trigger: true });
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    removeProperty($e) {
        console.debug('PropertyListEditView#removeProperty');

        $e.preventDefault();

        // Get selected properties
        const properties = propertyRestoreSelectedService();
        if (_.isEmpty(properties)) {
            return;
        }

        // Remove properties from list and reload
        this.model.save('properties', _.difference(this.model.get('properties'), properties), { patch: true, wait: true })
            .then(() => window.location.reload());
        /** @todo Intelligently remove from view */
    }

    downloadComparison() {
        console.debug('PropertyListEditView#downloadComparison');

        const selected = propertyRestoreSelectedService();

        OAuth2Client.xlsxDownload(config.api.url + 'property_comparison/' + selected.join('+'), {
            headers: {
                'Accept-Language': 'en',
            },
        }, {
            filename: 'comparison-' + moment().format('YYYYMMDD-HHmmss') + '.xlsx',
        });
    }
}