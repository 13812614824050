import { View } from 'backbone';
import Spinner from '@/js/app/ui/views/spinner';
import CitiesCollection from '../collections/cities';

export default class CitiesView extends View {
    preinitialize() {
        this.tagName = 'select';
        this.className = 'form-control';
        this.collection = new CitiesCollection;
        this.value = '';
    }

    initialize() {
        const s = new Spinner();
        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            s.spin(this.el);
        });

        // When collection updates/resets; render options
        this.listenTo(this.collection, 'update reset', this.renderOptions);

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            s.stop();
        });
    }

    render(parentEl) {
        console.debug('Cities#render');

        // Append el to parentEl
        parentEl.appendChild(this.el);

        // Initialize select2
        this.$el.select2({
            theme: 'bootstrap4'
        });

        return this;
    }

    renderOptions() {
        console.debug('Cities#renderOptions');

        // Remove all options
        this.el.innerHTML = '';

        // If collection contains models
        if (this.collection.length > 0) {
            // Initialize options as document fragment
            const options = document.createDocumentFragment();

            // Append option element to options
            options.appendChild(document.createElement('option'));

            // For each model in collection
            this.collection.each(model => {
                // Initialize o as option element
                const o = document.createElement('option');

                // Set value and innerHTML of o
                o.value = model.get('name');
                o.dataset.id = model.get('id');
                o.innerHTML = model.get('name');

                // Append o to options
                options.appendChild(o);
            });

            // Attach options to el
            this.el.appendChild(options);
        }

        // If value exists, set value
        if (this.value) {
            this.el.value = this.value;
        }

        return this;
    }

    setValue(value) {
        console.debug('Cities#setValue');

        if (value !== undefined) {
            this.value = value;
        }

        this.el.value = this.value;

        // Must trigger "change.select2" event
        this.$el.trigger('change.select2');

        // Trigger "set" event
        this.$el.trigger('set', { value: this.value });

        return this;
    }
}