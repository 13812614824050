module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal-dialog modal-dialog-centered" role="document">\n    <div class="modal-content">\n        <form autocomplete="off">\n            <div class="modal-header">\n                ';
 if (visibility === 'shared') { ;
__p += '\n                    ';
 if (type === 'building') { ;
__p += '\n                        <h3 class="modal-title">New Shared Building List</h3>\n                    ';
 } else { ;
__p += '\n                        <h3 class="modal-title">New Shared List</h3>\n                    ';
 } ;
__p += '\n                ';
 } else if (visibility === 'client') { ;
__p += '\n                    ';
 if (type === 'building') { ;
__p += '\n                        <h3 class="modal-title">New Client Building List</h3>\n                    ';
 } else { ;
__p += '\n                        <h3 class="modal-title">New Client List</h3>\n                    ';
 } ;
__p += '\n                ';
 } else if (visibility === 'public') { ;
__p += '\n                    ';
 if (type === 'building') { ;
__p += '\n                        <h3 class="modal-title">New Public Building List</h3>\n                    ';
 } else { ;
__p += '\n                        <h3 class="modal-title">New Public List</h3>\n                    ';
 } ;
__p += '\n                ';
 } else if (visibility === 'private') { ;
__p += '\n                    ';
 if (type === 'building') { ;
__p += '\n                        <h3 class="modal-title">New Private Building List</h3>\n                    ';
 } else { ;
__p += '\n                        <h3 class="modal-title">New Private List</h3>\n                    ';
 } ;
__p += '\n                ';
 } else { ;
__p += '\n                    ';
 if (type === 'building') { ;
__p += '\n                        <h3 class="modal-title">New Building List</h3>\n                    ';
 } else { ;
__p += '\n                        <h3 class="modal-title">New List</h3>\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n                <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n                    <i class="fa fa-times" aria-hidden="true"></i>\n                </button>\n            </div>\n            <div class="modal-body">\n                <fieldset>\n                    <div class="form-group row">\n                        <label class="col-4 col-form-label" for="field-name">List Name</label>\n                        <div class="col-6">\n                            <input id="field-name" type="text" name="name" class="form-control" required>\n                        </div>\n                    </div>\n                </fieldset>\n            </div>\n            <div class="modal-footer">\n                <button type="submit" class="btn btn-primary">Create</button>\n                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>\n            </div>\n        </form>\n    </div>\n</div>';

}
return __p
};
