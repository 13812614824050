import { Model } from 'backbone';
import config from '@/js/app/config';

export default Model.extend({

    urlRoot: config.api.url + 'property_alert',

    defaults: function () {
        return {
            id: null,
        };
    },

});