module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="mt-3">\n    <div class="row align-items-end">\n        <div class="col">\n            <h1 class="h2 my-0">Listings Report</h1>\n        </div>\n        <div class="col-auto">\n            <p class="h4 my-0">' +
((__t = ( moment().format('YYYY-MM-DD') )) == null ? '' : __t) +
'</p>\n        </div>\n    </div>\n</div>\n\n<hr>\n\n<div id="results"></div>';

}
return __p
};
