import ModalView from '@/js/app/generic/modal';
import template from '../templates/modal_automatch_clients.html';
import PropertyAutomatchClientsView from './automatch-clients';

export default class ModalAutomatchClientsView extends ModalView {
    preinitialize() {
        super.preinitialize();
    }

    render() {
        console.debug('ModalAutomatchClientsView#render');

        super.render({
            size: 'xl'
        }).modalContent.innerHTML = template();

        const view = new PropertyAutomatchClientsView({
            collection: this.collection,
        });

        // Render view and attach to modal body
        this.$el.find('.modal-body').html(view.render().el);

        // Fetch collection
        view.collection.fetch()

        return this;
    }
}