import numeral from 'numeral';

export default function populateForm(viewEl, data) {
    console.debug('PopulateForm');

    // For each key and value of data, process pair
    for (let [key, value] of Object.entries(data)) {
        processPair(viewEl, key, value);
    }
}

function processPair(viewEl, key, value) {
    // If value is an array
    if (Array.isArray(value)) {
        // Get all fields with name = key
        const fields = viewEl.querySelectorAll(`[name="${key}"]`);

        // Clear checked state for all fields
        fields.forEach(field => field.checked = false);

        // For each value in array
        value.forEach((subValue, index) => {
            // If val is an array or an object, process pair
            if (Array.isArray(subValue) || subValue instanceof Object) {
                processPair(viewEl, `${key}.${index}`, subValue);
            } else {
                // Initialize field as element with name = key
                const field = viewEl.querySelector(`[name="${key}"][value="${subValue}"]`);

                // If field exists, set as checked
                if (field) {
                    field.checked = true;
                }
            }
        });
    }
    // Else if value is an object
    else if (value instanceof Object) {
        // For each subKey and subValue of value, process pair
        for (let [subKey, subValue] of Object.entries(value)) {
            processPair(viewEl, `${key}.${subKey}`, subValue);
        }
    }
    else {
        // Get all fields with name = key
        const fields = viewEl.querySelectorAll(`[name="${key}"]`);

        // Loop over fields
        fields.forEach((field) => {
            // If field type is checkbox, set checked based on value
            if (field.type === 'checkbox') {
                field.checked = value > 0;  // Only positive values are considered checked
            }
            // Else if field type is radio
            else if (field.type === 'radio') {
                // Find radio button with the same value
                const radioButton = viewEl.querySelector(`[name="${key}"][value="${value}"]`);

                // If radio button exists, set checked to true
                if (radioButton) {
                    radioButton.checked = true;
                }
            }
            // Else if numeral data attribute exists, format appropriately
            else if (field.dataset.numeral) {
                field.value = value == null ? '' : numeral(value).format(field.dataset.numeral);
            }
            // Else if number data attribute exists, format appropriately
            else if (field.dataset.number) {
                field.value = value == null ? '' : numeral(value).format(field.dataset.number);
            }
            // Else, set value
            else {
                field.value = value == null ? '' : value;
            }
        });
    }
}