import { Collection, View } from 'backbone';
import template from '../templates/edit_kpis.html';
import EditStaffKpiView from './edit_kpi';

export default class StaffEditKpisView extends View {
    initialize() {
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                this.el.querySelector('#spinner').classList.remove('d-none');
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                this.el.querySelector('#spinner').classList.add('d-none');
            }
        });

        this.listenTo(this.collection, 'add', this.handleCollectionAdd);
    }

    render() {
        console.debug('StaffEditKpisView#render');

        this.el.innerHTML = template();

        return this;
    }

    handleCollectionAdd(model) {
        console.debug('StaffEditKpisView#handleCollectionAdd');

        // Create kpi item view with model
        const kpiItem = new EditStaffKpiView({
            model: model,
        });

        // Render view and append to list
        this.$el.find('#kpiList').prepend(kpiItem.render().el);
    }
}
