import { View } from 'backbone';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import KpisCollection from '../collections/kpis';
import template from '../templates/edit.html';
import StaffEditKpiView from './edit-kpis';
import StaffEditProfileView from './edit-profile';
import StaffModel from '../models/staff';

export default class StaffEditView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'staff_edit';

        this.events = {
            'click button[data-action="changelog"]': this.handleChangelogClick,
            'click [href="#tab-kpis"]': this.handleKpiTabClick,
        };

        this.model = new StaffModel({
            id: options.staffId,
        });

        this.kpisCollection = new KpisCollection([], {
            staffId: options.staffId,
        });

        this.changelogCollection = new ChangelogsCollection();

        this.subviews = {
            profileTab: new StaffEditProfileView({
                model: this.model,
            }),
            kpiTab: new StaffEditKpiView({
                collection: this.kpisCollection,
            }),
        };
    }

    initialize() {
        this.render();

        this.listenTo(this.model, 'change:last_name change:first_name', this.handleNameChange);

        // Trigger fetch
        this.model.fetch();
    }

    render() {
        console.debug('StaffEditView#render');

        this.el.innerHTML = template();

        // Attach and render subviews
        this.subviews.profileTab.setElement(this.el.querySelector('#tab-profile')).render();
        this.subviews.kpiTab.setElement(this.el.querySelector('#tab-kpis')).render();

        return this;
    }

    handleNameChange(model) {
        console.debug('StaffEditView#handleNameChange');

        this.changeName(model.get('last_name'), model.get('first_name'));
    }

    changeName(lastName, firstName) {
        console.debug('StaffEditView#changeName');

        this.el.querySelector('[data-outlet="last_name"]').innerText = lastName;
        this.el.querySelector('[data-outlet="first_name"]').innerText = firstName;
    }

    handleChangelogClick() {
        console.debug('StaffEditView#handleChangelogClick');

        // Create new changelogs view with changelog collection
        new ChangelogsView({
            collection: this.changelogCollection,
        });

        // Fetch collection
        this.changelogCollection.fetch({
            data: {
                resource_type: 'staff',
                resource_id: this.model.get('id'),
            },
        });
    }

    handleKpiTabClick() {
        console.debug('StaffEditView#handleKpiTabClick');

        this.kpisCollection.fetch();
    }
}
