import { history, View } from 'backbone';
import ApplicationModel from '@/js/app/application/models/application';
import ApplicationEditView from '@/js/app/application/views/edit';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Preferences from '@/js/app/preferences';
import template from '../templates/edit_applications.html';

export default class PeopleEditApplicationsView extends View {
    preinitialize(options) {
        this.events = {
            'click [data-action="new"]': this.new,
            // 'click [data-action="refresh"]': this.refresh,    /** @todo */
            'click [data-action="edit"]': this.edit,
            'click [data-action="approve"]': this.approve,
            'click [data-action="deny"]': this.deny,
        };

        this.person = options.person;
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'new', 'edit', 'approve', 'deny');

        // When collection updates/resets/changes; render
        this.listenTo(this.collection, 'update reset change', this.render);
    }

    render() {
        console.debug('PeopleEditApplicationsView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            applications: this.collection.toJSON(),
            propertyType: Preferences.property_type,
            Formatter,
        });

        return this;
    }

    new($e) {
        console.debug('PeopleEditApplicationsView#new');

        $e.preventDefault();

        // Create edit view, with new application model
        const editView = new ApplicationEditView({
            model: new ApplicationModel({
                addressbook_id: this.person.id,
                type: Preferences.property_type,
            }),
            collection: this.collection,
        });

        this.$el.find('#edit_application').html(editView.el);
        /**
         * Need to call render() after using .html() as listeners bound to individual elements are cleared
         * @todo Might be able to use render().el in .html() after we move to typeahead system (maybe....)
         */
        editView.render();
    }

    edit($e) {
        console.debug('PeopleEditApplicationsView#edit');

        // Create edit view, with application model from collection
        const editView = new ApplicationEditView({
            model: this.collection.get($e.currentTarget.dataset.id),
        });

        this.$el.find('#edit_application').html(editView.el);
        /**
         * Need to call render() after using .html() as listeners bound to individual elements are cleared
         * @todo Might be able to use render().el in .html() after we move to typeahead system (maybe....)
         */
        editView.render();
    }

    approve($e) {
        console.debug('PeopleEditApplicationsView#approve');

        // Get application model from collection
        const applicationModel = this.collection.get($e.currentTarget.dataset.id);

        if (confirm('Are you sure you want to approve this application?')) {
            // Approve application
            OAuth2Client.fetchJSON(applicationModel.url(), {
                method: 'APPROVE',
            })
                .then((response) => {
                    history.navigate('deals/edit/' + response.deal_id, { trigger: true });
                });
        }
    }

    deny($e) {
        console.debug('PeopleEditApplicationsView#deny');

        // Get application model from collection
        const applicationModel = this.collection.get($e.currentTarget.dataset.id);

        if (confirm('Are you sure you want to deny this application?')) {
            // Deny application

            OAuth2Client.fetchJSON(applicationModel.url(), {
                method: 'DENY',
            })
                .then((response) => {
                    applicationModel.set(response);
                });
        }
    }
}