import { Model } from 'backbone';

export default Model.extend({

    idAttribute: 'filename',

    defaults: function () {
        return {
            filename: null,
            type: null,
        };
    },

});