import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import Preferences from '@/js/app/preferences';
import template from '../templates/edit_deals.html';

export default class PeopleEditDealsView extends View {
    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.collection, 'update reset', this.render);
    }

    render() {
        console.debug('PeopleEditDealsView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            deals: this.collection.toJSON(),
            Formatter,
            Preferences,
        });

        return this;
    }
}
