import BloodhoundCompanyDataset from '@/js/app/bloodhound/datasets/company';
import BloodhoundIndividualDataset from '@/js/app/bloodhound/datasets/individual';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';
import BuildingModel from '@/js/app/property/models/building';
import UnitModel from '@/js/app/property/models/unit';
import propertyParseIdService from '@/js/app/property/services/parse-id';
import Session from '@/js/app/session';
import typeaheadCompanyDatasource from '@/js/app/typeahead/datasources/company';
import typeaheadIndividualDatasource from '@/js/app/typeahead/datasources/individual';
import { TypeaheadCompanyDefaults } from '@/js/app/typeahead/defaults';
import populateForm from '@/js/libs/populate-form';
import { history, View } from 'backbone';
import numeral from 'numeral';
import DealFileCollection from '../collections/files';
import RevenueCollection from '../collections/revenue';
import template from '../templates/edit.html';
import DealFilesView from './files';
import DealRevenueView from './revenue';

export default class DealEditView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'deal_edit';

        this.events = {
            'change input[name],select[name],textarea[name]': this.updateModel,
            'change #field-property_id': this.populatePropertyData,
            'change [data-lock-row-on-paid]': this.toggleRowLock,
            'click button[data-action="copy"]': this.copy,
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="generate-pdf"]': this.generatePdf,
            'click button[data-action="changelog"]': this.changelog,
            'typeahead:select #cntr-貸主_本人 [data-typeahead]': this.selectLessor,
            'click #cntr-貸主_本人 [data-action="clear-name"]': this.clearLessor,
            'typeahead:select #cntr-貸主_本人2 [data-typeahead]': this.selectLessor2,
            'click #cntr-貸主_本人2 [data-action="clear-name"]': this.clearLessor2,
            'typeahead:select #cntr-貸主_代理人 [data-typeahead]': this.selectLessorProxy,
            'click #cntr-貸主_代理人 [data-action="clear-name"]': this.clearLessorProxy,
            'typeahead:select #cntr-借主_本人 [data-typeahead]': this.selectLessee,
            'click #cntr-借主_本人 [data-action="clear-name"]': this.clearLessee,
            'typeahead:select #cntr-借主_本人2 [data-typeahead]': this.selectLessee2,
            'click #cntr-借主_本人2 [data-action="clear-name"]': this.clearLessee2,
            'typeahead:select #cntr-借主_代理人 [data-typeahead]': this.selectLesseeProxy,
            'click #cntr-借主_代理人 [data-action="clear-name"]': this.clearLesseeProxy,
            'typeahead:select #cntr-借主_入居者 [data-typeahead]': this.selectTenant,
            'click #cntr-借主_入居者 [data-action="clear-name"]': this.clearTenant,
            'typeahead:select #cntr-takkengyosha_shogo [data-typeahead]': this.selectRealEstateCompany,
            'click #cntr-takkengyosha_shogo [data-action="clear-name"]': this.clearRealEstateCompany,
            'typeahead:select #cntr-kanriitakusaki_senyubu [data-typeahead]': this.selectManagementCompanyProprietaryArea,
            'click #cntr-kanriitakusaki_senyubu [data-action="clear-name"]': this.clearManagementCompanyProprietaryArea,
            'typeahead:select #cntr-kanriitakusaki_kyoyobu [data-typeahead]': this.selectManagementCompanyCommonArea,
            'click #cntr-kanriitakusaki_kyoyobu [data-action="clear-name"]': this.clearManagementCompanyCommonArea,
        };

        // Create subview containers
        this.subviews = {
            revenue: new DealRevenueView({
                collection: new RevenueCollection,
            }),
            fileList: new DealFilesView({
                collection: new DealFileCollection(null, {
                    deal_id: options.model.id,
                }),
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'render', 'toggleRowLock', 'changelog');

        this.render();

        // When deal ID changes: update URL with new ID
        this.listenTo(this.model, 'change:id', this.updateURL);

        // When division_approved changes: toggle mutability
        this.listenTo(this.model, 'change:division_approved', this.toggleMutability);

        // When accounting_approved changes: toggle division approval mutability
        this.listenTo(this.model, 'change:accounting_approved', this.toggleDivisionApprovalMutability);

        // When division_approved changes: toggle accounting approval mutability
        this.listenTo(this.model, 'change:division_approved', this.toggleAccountingApprovalMutability);

        // When v changes: toggle deduction system
        this.listenTo(this.model, 'change:v', this.toggleDeductionSystem);

        // When 建物の種類 changes: toggle mansion section
        this.listenTo(this.model, 'change:建物の種類', this.toggleMansionSection);

        // When building ID or unit ID changes: update link
        this.listenTo(this.model, 'change:building_id change:unit_id', this.updatePropertyURL);

        // Update outlets
        this.listenTo(this.model, 'change', this.updateOutlets);

        if (this.model.id) {
            // Trigger fetch, and call "render" on success
            this.model.fetch({
                data: {
                    // include: ['assigned_to'],
                },
                silent: true,	// Mark: I know this is not recommended, but we need to get data into this model without triggering any "change" events
                success: this.render,
            });

            // Trigger fetch on revenue list
            this.subviews.revenue.collection.fetch({
                data: {
                    deal_id: this.model.id,
                    include: ['division', 'staff'],
                },
            });

            // Trigger fetch on file list
            this.subviews.fileList.collection.fetch({
                data: {
                    order: 'last_modified_at',
                    include: ['last_modified_by']
                },
            });
        }
    }

    render() {
        console.debug('DealEditView#render');

        this.el.innerHTML = template({
            id: this.model.get('custom_id') ? this.model.get('custom_id') : this.model.id,
            version: this.model.get('v'),
            dealType: this.model.get('type'),
            isDeleteAllowed: Session.isAllowed('phnx:deals:d'),
            isAccountingAllowed: Session.isAllowed('phnx:accounting'),
        });


        this.$el.find('#container-revenue').append(this.subviews.revenue.el);

        // If model ID exists
        if (this.model.id) {
            // Render fileList subview
            this.subviews.fileList.setElement(this.el.querySelector('#files')).render();
        } else {
            // Hide files title
            this.el.querySelector('#filesTitle').classList.add('d-none');
        }

        populateForm(this.el, this.model.toJSON());
        this.updatePropertyURL(this.model);
        this.initializeLinkedTypeaheadField('貸主_本人');
        this.initializeLinkedTypeaheadField('貸主_本人2');
        this.initializeLinkedTypeaheadField('貸主_代理人');
        this.initializeLinkedTypeaheadField('借主_本人');
        this.initializeLinkedTypeaheadField('借主_本人2');
        this.initializeLinkedTypeaheadField('借主_代理人');
        this.initializeLinkedTypeaheadField('借主_入居者');
        this.initializeLinkedTypeaheadField('takkengyosha_shogo');
        this.initializeLinkedTypeaheadField('kanriitakusaki_senyubu');
        this.initializeLinkedTypeaheadField('kanriitakusaki_kyoyobu');

        // Populate property ID (this is needed because field does not have a "name" attribute)
        this.$el.find(`#field-property_id`).val(this.model.get('property_id'));

        /* when a field changes and is no longer empty, set these other fields as required, otherwise clear requirement */
        const that = this;
        const bindRequireFields = function (field) {
            field = 'undefined' !== typeof field && !field.bubbles ? field : this;

            const this_value = field.value;
            const this_required_fields = field.dataset.requireFields.split(',');

            if (this_required_fields.length) {
                for (let i = this_required_fields.length - 1; i >= 0; i--) {
                    that.el.querySelector('[name="' + this_required_fields[i] + '"]').required = (this_value.length > 0);
                }
            }
        };
        this.$el.find('[data-require-fields]').on('change', bindRequireFields);
        this.$el.find('[data-require-fields]').each(function (i, field) {
            bindRequireFields(field);
        });

        // Toggle mutability
        this.toggleMutability(this.model);

        // Toggle division approval mutability
        this.toggleDivisionApprovalMutability(this.model);

        // Toggle accounting approval mutability
        this.toggleAccountingApprovalMutability(this.model);

        // Toggle deductions
        this.toggleDeductionSystem(this.model);

        // Toggle mansion section
        this.toggleMansionSection(this.model);

        // Setup typeahead
        this.$el.find('#cntr-貸主_本人 [data-typeahead], #cntr-貸主_本人2 [data-typeahead], #cntr-貸主_代理人 [data-typeahead], #cntr-借主_本人 [data-typeahead], #cntr-借主_本人2 [data-typeahead], #cntr-借主_代理人 [data-typeahead]').typeahead(
            TypeaheadCompanyDefaults,
            typeaheadCompanyDatasource(BloodhoundCompanyDataset),
            typeaheadIndividualDatasource(BloodhoundIndividualDataset)
        );
        this.$el.find('#cntr-借主_入居者 [data-typeahead]').typeahead(
            TypeaheadCompanyDefaults,
            typeaheadIndividualDatasource(BloodhoundIndividualDataset)
        );
        this.$el.find('#cntr-takkengyosha_shogo [data-typeahead], #cntr-kanriitakusaki_senyubu [data-typeahead], #cntr-kanriitakusaki_kyoyobu [data-typeahead]').typeahead(
            TypeaheadCompanyDefaults,
            typeaheadCompanyDatasource(BloodhoundCompanyDataset)
        );

        // Add placeholder to typeahead fields
        this.$el.find('.tt-input').prop('placeholder', '\uf002');

        return this;
    }

    initializeLinkedTypeaheadField(name) {
        if (this.model.get(name)) {
            this.hideTypeaheadField(name);
            this.showDisplayField(name);

            if (['借主_入居者'].includes(name)) {
                this.updateLink(name, '#people/edit/' + this.model.get(`${name}_id`));
            } else if (['takkengyosha_shogo', 'kanriitakusaki_senyubu', 'kanriitakusaki_kyoyobu'].includes(name)) {
                this.updateLink(name, '#company/edit/' + this.model.get(`${name}_id`));
            } else {
                const type = this.model.get(`${name}_type`);
                if (type === 'individual') {
                    this.updateLink(name, '#people/edit/' + this.model.get(`${name}_id`));
                } else if (type === 'company') {
                    this.updateLink(name, '#company/edit/' + this.model.get(`${name}_id`));
                }
            }
        }
    }

    showTypeaheadField(name) {
        const typeaheadField = this.$el.find(`#cntr-${name} [data-typeahead]`);
        typeaheadField.closest('.input-group').removeClass('d-none');
    }

    hideTypeaheadField(name) {
        const typeaheadField = this.$el.find(`#cntr-${name} [data-typeahead]`);
        typeaheadField.closest('.input-group').addClass('d-none');
    }

    showDisplayField(name) {
        // Set value of field, and disable
        const displayField = this.$el.find(`[name="${name}"]`);
        displayField.closest('.input-group').removeClass('d-none');
    }

    hideDisplayField(name) {
        this.$el.find(`[name="${name}"]`).closest('.input-group').addClass('d-none');
    }

    updateLink(name, url) {
        const link = this.el.querySelector(`[data-outlet="${name}_link"]`);
        if (link) {
            link.classList.remove('d-none');
            link.href = url;
        }
    }

    updateURL(model, value) {
        history.navigate('deals/edit/' + value);
    }

    updatePropertyURL(model) {
        console.debug('DealEditView#updatePropertyURL');

        const propertyLink = this.el.querySelector('[data-outlet="property_link"]');
        const buildingId = model.get('building_id');

        let newUrl = '';
        if (buildingId) {
            // Build new URL
            newUrl = '#property/edit/' + model.get('building_id') + '.0';

            const unitId = model.get('unit_id');
            if (unitId) {
                newUrl += '/' + unitId;
            }

            // Show property link
            propertyLink.classList.remove('d-none');
        } else {
            // Hide property link
            propertyLink.classList.add('d-none');
        }

        // Set new URL on property link
        propertyLink.href = newUrl;
    }

    updateModel($e) {
        console.debug('DealEditView#updateModel');

        const t = $e.currentTarget;

        // If no name, return
        if (t.name === '') {
            return;
        }

        let value;

        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else if number type, or data attribute "numeral" exists; unformat number
        else if (t.type === 'number' || typeof t.dataset.numeral !== 'undefined') {
            value = numeral(t.value).value() || null;
        }
        // Else; use value or null
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.set(t.name, value);

        // Save changed attributes
        /**
         * We have to set then save because the above "set" will also trigger other fields to change too.
         * Once all the related fields have changed, then we save the "changed attributes"
         */
        this.model.save(this.model.changedAttributes(), { patch: true, wait: true });
    }

    updateOutlets(model) {
        console.debug('DealEditView#updateOutlets');

        // Loop through all changed attributes, and set value of input fields
        /** @todo What if <select> or <textarea>? */
        _.each(model.changedAttributes(), (v, k) => {
            // Get outlet for key
            const o = this.el.querySelector('[data-outlet="' + k + '"]');
            if (o) {
                o.innerHTML = v;
            }

            // Get input for key
            const t = this.el.querySelector('[name="' + k + '"]');
            if (t) {
                // If radio or checkbox, change checked property
                if (t.type === 'radio' && v != null) {
                    this.el.querySelector('[name="' + k + '"][value="' + v + '"]').checked = true;
                }
                else if (t.type === 'checkbox') {
                    this.el.querySelector('[name="' + k + '"]').checked = !!v; // Force convert value to boolean
                }
                // Else, change value
                else {
                    // If numeral data attribute exists, format appropriately
                    if (t.dataset.numeral) {
                        t.value = v ? numeral(v).format(t.dataset.numeral) : '';
                    }
                    else {
                        t.value = v;
                    }
                }
            }
        });
    }

    selectLessor($e, datum) {
        console.debug('DealEditView#selectLessor');

        this.model.set({
            貸主_本人_id: datum.id,
            貸主_本人_type: datum._type,		// This is set during transform() in Bloodhound
            貸主_本人: $e.currentTarget.value,
            貸主_本人_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            貸主_本人_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('貸主_本人');
        this.showDisplayField('貸主_本人');

        if (datum._type === 'individual') {
            this.updateLink('貸主_本人', '#people/edit/' + datum.id);
        } else if (datum._type === 'company') {
            this.updateLink('貸主_本人', '#company/edit/' + datum.id);
        }
    }

    clearLessor($e) {
        console.debug('DealEditView#clearLessor');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                貸主_本人_id: null,
                貸主_本人_type: null,
                貸主_本人: null,
                貸主_本人_住所: null,
                貸主_本人_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('貸主_本人');
            this.showTypeaheadField('貸主_本人');

            // Clear and enable typeahead
            this.$el.find('#cntr-貸主_本人 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectLessor2($e, datum) {
        console.debug('DealEditView#selectLessor2');

        this.model.set({
            貸主_本人2_id: datum.id,
            貸主_本人2_type: datum._type,		// This is set during transform() in Bloodhound
            貸主_本人2: $e.currentTarget.value,
            貸主_本人2_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            貸主_本人2_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('貸主_本人2');
        this.showDisplayField('貸主_本人2');

        if (datum._type === 'individual') {
            this.updateLink('貸主_本人2', '#people/edit/' + datum.id);
        } else if (datum._type === 'company') {
            this.updateLink('貸主_本人2', '#company/edit/' + datum.id);
        }
    }

    clearLessor2($e) {
        console.debug('DealEditView#clearLessor2');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                貸主_本人2_id: null,
                貸主_本人2_type: null,
                貸主_本人2: null,
                貸主_本人2_住所: null,
                貸主_本人2_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('貸主_本人2');
            this.showTypeaheadField('貸主_本人2');

            // Clear and enable typeahead
            this.$el.find('#cntr-貸主_本人2 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectLessorProxy($e, datum) {
        console.debug('DealEditView#selectLessorProxy');

        this.model.set({
            貸主_代理人_id: datum.id,
            貸主_代理人_type: datum._type,		// This is set during transform() in Bloodhound
            貸主_代理人: $e.currentTarget.value,
            貸主_代理人_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            貸主_代理人_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('貸主_代理人');
        this.showDisplayField('貸主_代理人');

        if (datum._type === 'individual') {
            this.updateLink('貸主_代理人', '#people/edit/' + datum.id);
        } else if (datum._type === 'company') {
            this.updateLink('貸主_代理人', '#company/edit/' + datum.id);
        }
    }

    clearLessorProxy($e) {
        console.debug('DealEditView#clearLessorProxy');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                貸主_代理人_id: null,
                貸主_代理人_type: null,
                貸主_代理人: null,
                貸主_代理人_住所: null,
                貸主_代理人_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('貸主_代理人');
            this.showTypeaheadField('貸主_代理人');

            // Clear and enable typeahead
            this.$el.find('#cntr-貸主_代理人 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectLessee($e, datum) {
        console.debug('DealEditView#selectLessee');

        this.model.set({
            借主_本人_id: datum.id,
            借主_本人_type: datum._type,		// This is set during transform() in Bloodhound
            借主_本人: $e.currentTarget.value,
            借主_本人_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_本人_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('借主_本人');
        this.showDisplayField('借主_本人');

        if (datum._type === 'individual') {
            this.updateLink('借主_本人', '#people/edit/' + datum.id);
        } else if (datum._type === 'company') {
            this.updateLink('借主_本人', '#company/edit/' + datum.id);
        }
    }

    clearLessee($e) {
        console.debug('DealEditView#clearLessee');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                借主_本人_id: null,
                借主_本人_type: null,
                借主_本人: null,
                借主_本人_住所: null,
                借主_本人_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('借主_本人');
            this.showTypeaheadField('借主_本人');

            // Clear and enable typeahead
            this.$el.find('#cntr-借主_本人 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectLessee2($e, datum) {
        console.debug('DealEditView#selectLessee2');

        this.model.set({
            借主_本人2_id: datum.id,
            借主_本人2_type: datum._type,		// This is set during transform() in Bloodhound
            借主_本人2: $e.currentTarget.value,
            借主_本人2_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_本人2_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('借主_本人2');
        this.showDisplayField('借主_本人2');

        if (datum._type === 'individual') {
            this.updateLink('借主_本人2', '#people/edit/' + datum.id);
        } else if (datum._type === 'company') {
            this.updateLink('借主_本人2', '#company/edit/' + datum.id);
        }
    }

    clearLessee2($e) {
        console.debug('DealEditView#clearLessee2');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                借主_本人2_id: null,
                借主_本人2_type: null,
                借主_本人2: null,
                借主_本人2_住所: null,
                借主_本人2_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('借主_本人2');
            this.showTypeaheadField('借主_本人2');

            // Clear and enable typeahead
            this.$el.find('#cntr-借主_本人2 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectLesseeProxy($e, datum) {
        console.debug('DealEditView#selectLesseeProxy');

        this.model.set({
            借主_代理人_id: datum.id,
            借主_代理人_type: datum._type,		// This is set during transform() in Bloodhound
            借主_代理人: $e.currentTarget.value,
            借主_代理人_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_代理人_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('借主_代理人');
        this.showDisplayField('借主_代理人');

        if (datum._type === 'individual') {
            this.updateLink('借主_代理人', '#people/edit/' + datum.id);
        } else if (datum._type === 'company') {
            this.updateLink('借主_代理人', '#company/edit/' + datum.id);
        }
    }

    clearLesseeProxy($e) {
        console.debug('DealEditView#clearLesseeProxy');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                借主_代理人_id: null,
                借主_代理人_type: null,
                借主_代理人: null,
                借主_代理人_住所: null,
                借主_代理人_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('借主_代理人');
            this.showTypeaheadField('借主_代理人');

            // Clear and enable typeahead
            this.$el.find('#cntr-借主_代理人 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectTenant($e, datum) {
        console.debug('DealEditView#selectTenant');

        this.model.set({
            借主_入居者_id: datum.id,
            借主_入居者: $e.currentTarget.value,
            借主_入居者_住所: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            借主_入居者_TEL: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('借主_入居者');
        this.showDisplayField('借主_入居者');

        this.updateLink('借主_入居者', '#people/edit/' + datum.id);
    }

    clearTenant($e) {
        console.debug('DealEditView#clearTenant');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                借主_入居者_id: null,
                借主_入居者: null,
                借主_入居者_住所: null,
                借主_入居者_TEL: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('借主_入居者');
            this.showTypeaheadField('借主_入居者');

            // Clear and enable typeahead
            this.$el.find('#cntr-借主_入居者 [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectRealEstateCompany($e, datum) {
        console.debug('DealEditView#selectRealEstateCompany');

        this.model.set({
            takkengyosha_shogo_id: datum.id,
            takkengyosha_shogo: $e.currentTarget.value,
            takkengyosha_shozaichi: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            takkengyosha_tel: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('takkengyosha_shogo');
        this.showDisplayField('takkengyosha_shogo');

        this.updateLink('takkengyosha_shogo', '#company/edit/' + datum.id);
    }

    clearRealEstateCompany($e) {
        console.debug('DealEditView#clearRealEstateCompany');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                takkengyosha_shogo_id: null,
                takkengyosha_shogo: null,
                takkengyosha_shozaichi: null,
                takkengyosha_tel: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('takkengyosha_shogo');
            this.showTypeaheadField('takkengyosha_shogo');

            // Clear and enable typeahead
            this.$el.find('#cntr-takkengyosha_shogo [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectManagementCompanyProprietaryArea($e, datum) {
        console.debug('DealEditView#selectManagementCompanyProprietaryArea');

        this.model.set({
            kanriitakusaki_senyubu_id: datum.id,
            kanriitakusaki_senyubu: $e.currentTarget.value,
            kanriitakusaki_senyubu_jusho: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            kanriitakusaki_senyubu_tel: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('kanriitakusaki_senyubu');
        this.showDisplayField('kanriitakusaki_senyubu');

        this.updateLink('kanriitakusaki_senyubu', '#company/edit/' + datum.id);
    }

    clearManagementCompanyProprietaryArea($e) {
        console.debug('DealEditView#clearManagementCompanyProprietaryArea');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                kanriitakusaki_senyubu_id: null,
                kanriitakusaki_senyubu: null,
                kanriitakusaki_senyubu_jusho: null,
                kanriitakusaki_senyubu_tel: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('kanriitakusaki_senyubu');
            this.showTypeaheadField('kanriitakusaki_senyubu');

            // Clear and enable typeahead
            this.$el.find('#cntr-kanriitakusaki_senyubu [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    selectManagementCompanyCommonArea($e, datum) {
        console.debug('DealEditView#selectManagementCompanyCommonArea');

        this.model.set({
            kanriitakusaki_kyoyobu_id: datum.id,
            kanriitakusaki_kyoyobu: $e.currentTarget.value,
            kanriitakusaki_kyoyobu_jusho: (datum.postcode || '') + ' ' + (datum.address_string || ''),
            kanriitakusaki_kyoyobu_tel: (datum.telephone || ''),
        });
        this.model.save(this.model.changedAttributes(), {
            patch: true,
            wait: true,
        });

        this.hideTypeaheadField('kanriitakusaki_kyoyobu');
        this.showDisplayField('kanriitakusaki_kyoyobu');

        this.updateLink('kanriitakusaki_kyoyobu', '#company/edit/' + datum.id);
    }

    clearManagementCompanyCommonArea($e) {
        console.debug('DealEditView#clearManagementCompanyCommonArea');

        $e.preventDefault();

        // If model is division approved, return
        if (this.model.get('division_approved')) {
            return;
        }

        if (confirm('Are you sure you want to clear this field?')) {
            this.model.set({
                kanriitakusaki_kyoyobu_id: null,
                kanriitakusaki_kyoyobu: null,
                kanriitakusaki_kyoyobu_jusho: null,
                kanriitakusaki_kyoyobu_tel: null,
            });
            this.model.save(this.model.changedAttributes(), {
                patch: true,
                wait: true,
            });

            this.hideDisplayField('kanriitakusaki_kyoyobu');
            this.showTypeaheadField('kanriitakusaki_kyoyobu');

            // Clear and enable typeahead
            this.$el.find('#cntr-kanriitakusaki_kyoyobu [data-typeahead]')
                .typeahead('val', '')
                .focus();
        }
    }

    populatePropertyData($e) {
        console.debug('DealEditView#populatePropertyData');

        $e.preventDefault();

        const propertyId = propertyParseIdService($e.currentTarget.value);

        // If no property ID, return
        if (!propertyId) {
            return;
        }

        // Create appropriate model, based on structure_type
        let model;
        const includes = ['location'];
        if (propertyId.structure_type === 'B' || propertyId.structure_type === 'L') {
            model = new BuildingModel({
                id: propertyId.id,
            });
        } else if (propertyId.structure_type === 'A' || propertyId.structure_type === 'H') {
            model = new UnitModel({
                id: propertyId.id,
            });

            // Include structure
            includes.push('structure');
        }

        const all_fields = this.$el.find('input[data-populate-by="property_id"]');
        const structure_name_field = this.$el.find('input[name="物件名"]');
        const structure_type_field = this.$el.find('input[name="建物の種類"]');
        const address_field = this.$el.find('input[name="物件所在地_部屋番号"]');
        const room_no_field = this.$el.find('input[name="物件名_部屋番号"]');
        const rent_field = this.$el.find('input[name="賃貸条件_賃料_月額"]');
        const deposit_field = this.$el.find('input[name="賃貸条件_敷金"]');
        const completion_year_field = this.$el.find('input[name="建物_建築年月日"]');
        const size_field = this.$el.find('input[name="建物_床面積_合計"]');
        const total_floors_field = this.$el.find('input[name="建物_階数_地上"]');
        const total_floors_basement_field = this.$el.find('input[name="建物_階数_地下"]');

        const madori_field = this.$el.find('input[name="建物_間取り"]');

        structure_name_field.removeClass('error').empty();
        all_fields.not('input[name="賃貸条件_賃料_月額"], input[name="賃貸条件_敷金"]').val('');

        model.fetch({
            data: {
                include: includes,
            },
        })
            .then((p) => {
                const data = {
                    property_id: $e.currentTarget.value
                };

                // Add building and unit ID based on structure type
                if (p.structure_type === 'building' || p.structure_type === 'land') {
                    data.building_id = p.id;
                } else if (p.structure_type === 'unit' || p.structure_type === 'house') {
                    data.building_id = p.building_id;
                    data.unit_id = p.id;
                }

                // Structure type
                structure_type_field.filter('[data-structure-type="' + p.structure_type + '"]').prop('checked', true);
                data[structure_type_field.prop('name')] = structure_type_field.filter(':checked').val();

                if (p.structure_type === 'building' || p.structure_type === 'land') {
                    // Structure name
                    structure_name_field.val(p.structure_name_en || '');
                    data[structure_name_field.prop('name')] = p.structure_name_en || '';

                    // Address
                    address_field.val(p.location.prefecture + p.location.city + p.location.neighborhood + p.location.address);
                    data[address_field.prop('name')] = p.location.prefecture + p.location.city + p.location.neighborhood + p.location.address;

                    // Total floors
                    if (total_floors_field.length > 0) {
                        total_floors_field.val(p.total_floors);
                        data[total_floors_field.prop('name')] = p.total_floors;
                    }
                    if (total_floors_basement_field.length > 0) {
                        total_floors_basement_field.val(p.total_floors_basement);
                        data[total_floors_basement_field.prop('name')] = p.total_floors_basement;
                    }
                } else if (p.structure_type === 'unit' || p.structure_type === 'house') {
                    // Structure name
                    structure_name_field.val(p.structure.structure_name_en || '');
                    data[structure_name_field.prop('name')] = p.structure.structure_name_en || '';

                    // Address
                    address_field.val(p.structure.location.prefecture + p.structure.location.city + p.structure.location.neighborhood + p.structure.location.address);
                    data[address_field.prop('name')] = p.structure.location.prefecture + p.structure.location.city + p.structure.location.neighborhood + p.structure.location.address;

                    // Total floors
                    if (total_floors_field.length > 0) {
                        total_floors_field.val(p.structure.total_floors);
                        data[total_floors_field.prop('name')] = p.structure.total_floors;
                    }
                    if (total_floors_basement_field.length > 0) {
                        total_floors_basement_field.val(p.structure.total_floors_basement);
                        data[total_floors_basement_field.prop('name')] = p.structure.total_floors_basement;
                    }

                    // Room number
                    room_no_field.val(p.room_no);
                    data[room_no_field.prop('name')] = p.room_no;

                    // Madori
                    madori_field.val(`${p.bedroom_no} ${p.floorplan_type || 'LDK'}`);
                    data[madori_field.prop('name')] = `${p.bedroom_no} ${p.floorplan_type || 'LDK'}`;
                }

                // Rent
                if (rent_field.length && 0 == rent_field.val().length) {
                    rent_field.val(p.rent);
                    data[rent_field.prop('name')] = p.rent;
                }
                // Deposit
                if (deposit_field.val() && 0 == deposit_field.val().length) {
                    deposit_field.val(p.deposit_by_months * p.rent);
                    data[deposit_field.prop('name')] = p.deposit_by_months * p.rent;
                }
                // Year built
                if (completion_year_field.val() && 0 == completion_year_field.val().length) {
                    completion_year_field.val(p.completion_year);
                    data[completion_year_field.prop('name')] = p.completion_year;
                }
                // Structure unit size
                if (size_field.val() && 0 == size_field.val().length) {
                    size_field.val(p.size);
                    data[size_field.prop('name')] = p.size;
                }

                // Bulk update model
                /** Must use set() first, as there are other attributes that are triggered to update. These won't save if using save() directly */
                this.model.set(data);
                if (this.model.changedAttributes()) {
                    this.model.save(this.model.changedAttributes(), {
                        patch: true,
                        wait: true,
                    });
                }
            });
    }

    toggleRowLock(e) {
        console.debug('DealEditView#toggleRowLock');

        const checkbox = e.target ? $(e.target) : $(e);
        const rowScope = checkbox.closest('div.row[data-lock-row]');

        if (this.model.get('division_approved') || checkbox.prop('checked')) {
            // Disable all fields in row
            const inputs = rowScope.find('select, input:not([data-lock-row-on-paid]):not([readonly])');
            inputs.prop('disabled', true);

            // Hide typeahead clear
            //
        }
        else {
            // Enable regular fields in row
            const inputs = rowScope.find('select, input:not([data-lock-row-on-paid]):not([readonly]):not([data-typeahead])');
            inputs.prop('disabled', false);

            // Unlock typeahead fields in row (where appropriate)
            const typeaheadInputs = rowScope.find('input[data-typeahead]');
            typeaheadInputs.each((index, element) => {
                // If no value, enable typeahead
                if (element.value === '') {
                    element.disabled = false;
                }
            });

            // Show typeahead clear
            //
        }
    }

    toggleMutability(model) {
        console.debug('DealEditView#toggleMutability');

        // If division_approved; lock daicho
        if (model.get('division_approved')) {
            // Disable fields
            const inputs = this.$el.find('select, textarea:not([data-backoffice-use]), input:not([data-lock-row-on-paid]):not([readonly]):not([data-backoffice-use])');
            inputs.prop('disabled', true);
        }
        // Else; unlock daicho
        else {
            // Enable regular fields
            this.$el.find('select, textarea:not([data-backoffice-use]), input:not([data-lock-row-on-paid]):not([readonly]):not([data-backoffice-use]):not([data-typeahead])').prop('disabled', false);

            // Enable typeahead fields (where appropriate)
            const typeaheadInputs = this.el.querySelectorAll('input[data-typeahead]');
            typeaheadInputs.forEach(element => {
                // If no value, enable typeahead
                if (element.value === '') {
                    element.disabled = false;
                }
            });

            // Re-lock paid rows as necessary
            _.each(this.$el.find('[data-lock-row-on-paid]'), this.toggleRowLock);
        }
    }

    toggleDivisionApprovalMutability(model) {
        console.debug('DealEditView#toggleDivisionApprovalMutability');

        // Disable division_approved if not allowed OR accounting_approved (enable division_approved if allowed AND not accounting_approved)
        this.el.querySelector('[name="division_approved"]').disabled = !Session.isAllowed('phnx:deals.div_approve:u') || !!model.get('accounting_approved');
    }

    toggleAccountingApprovalMutability(model) {
        console.debug('DealEditView#toggleAccountingApprovalMutability');

        // Disable accounting_approved, unless user has "phnx:deals.acct_approve:u" permission, and division approved
        this.el.querySelector('[name="accounting_approved"]').disabled = !(Session.isAllowed('phnx:deals.acct_approve:u') && !!model.get('division_approved'));
    }

    toggleDeductionSystem(model) {
        console.debug('DealEditView#toggleDeductionSystem');

        if (model.get('v') === 1) {
            // Show web deduction UI
            this.$el.find('[data-deduction-web]').removeClass('d-none');

            // Hide agent deduction UI
            this.$el.find('[data-deduction-agent]').addClass('d-none');
        } else if (model.get('v') === 2) {
            // Show agent deduction UI
            this.$el.find('[data-deduction-agent]').removeClass('d-none');

            // Hide web deduction UI
            this.$el.find('[data-deduction-web]').addClass('d-none');
        } else {
            // Hide agent deduction UI
            this.$el.find('[data-deduction-agent]').addClass('d-none');

            // Hide web deduction UI
            this.$el.find('[data-deduction-web]').addClass('d-none');
        }
    }

    toggleMansionSection(model) {
        console.debug('DealEditView#toggleMansionSection');

        // If building type is mansion
        if (model.get('建物の種類') === 'マンション') {
            // Show section
            this.$el.find('#section-mansion').show();
        } else {
            // Hide section
            this.$el.find('#section-mansion').hide();
        }
    }

    copy() {
        console.debug('DealEditView#copy');

        // Display confirmation box to user
        if (confirm('Are you sure you want to copy this deal?')) {
            OAuth2Client.fetchJSON(`${config.api.url}deals/${this.model.id}`, {
                method: 'COPY',
            })
            .then((responseBody) => {
                // Redirect to edit deal
                history.navigate('deals/edit/' + responseBody.id, { trigger: true });
            });
        }
    }

    delete() {
        console.debug('DealEditView#delete');

        // Display confirmation box to user. If accepted; destroy model and navigate to deal search
        if (confirm('Are you sure you want to delete this deal? This cannot be undone!')) {
            this.model.destroy({
                success: function () {
                    history.navigate('deals', { trigger: true });
                },
                error: function (model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    generatePdf() {
        /**
         * @todo Consider replacing with a simple call to OAuth2Client.download(), to avoid creating blob URLs that cannot be cleaned up
         */
        OAuth2Client.fetch(`${config.api.url}generate/deal/${this.model.id}`, {})
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {
                    return response.blob();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((blob) => {
                // Create blob URL
                const url = window.URL.createObjectURL(blob);
                // Create temporary <a> element
                const tmpAnchor = document.createElement('a');
                document.body.appendChild(tmpAnchor);
                // Set URL
                tmpAnchor.href = url;
                // Set target
                tmpAnchor.target = '_blank';
                // Trigger click
                tmpAnchor.click();
                // Remove temporary <a> element
                document.body.removeChild(tmpAnchor);
            });
    }

    changelog() {
        console.debug('DealEditView#changelog');

        // Create modal view, with collection
        this.subviews.changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        this.subviews.changelogView.collection.fetch({
            data: {
                resource_type: 'deal',
                resource_id: this.model.get('id'),
            },
        });
    }
}