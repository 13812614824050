import { View } from 'backbone';
import * as Formatter from '@/js/app/formatter';
import OAuth2Client from '@/js/app/oauth2-client';
import Session from '@/js/app/session';
import * as TextHelper from '@/js/app/text-helper';
import templateError from '@/js/app/ui/templates/error.html';
import InquiryModel from '../models/inquiry';
import templateLoading from '@/js/app/ui/templates/spinner.html';
import template from '../templates/detail.html';

export default class InquiryDetailView extends View {
    preinitialize(options) {
        this.events = {
            'click [data-action="mark-spam"]': this.handleMarkAsSpamClick,
            'click [data-action="unmark-spam"]': this.handleUnmarkSpamClick,
            'click [data-action="create-client"]': this.handleCreateClientClick,
        };

        this.model = new InquiryModel({
            id: options.inquiryId,
        });
    }

    initialize() {
        // When model starts request; handleModelRequest
        this.listenTo(this.model, 'request', this.handleModelRequest);

        // When model request fails; handleModelError
        this.listenTo(this.model, 'error', this.handleModelError);

        // When model request syncs; handleModelSync
        this.listenTo(this.model, 'sync', this.handleModelSync);
    }

    renderLoading() {
        this.el.innerHTML = templateLoading();
    }

    renderError() {
        this.el.innerHTML = templateError();
    }

    render() {
        console.debug('InquiryDetailView#render');

        this.el.innerHTML = template({
            inquiry: this.model.toJSON(),
            isInquiryUpdateAllowed: Session.isAllowed('phnx:inquiries:u'),
            TextHelper,
            Formatter,
        });

        return this;
    }

    handleModelRequest() {
        console.debug('InquiryDetailView#handleModelRequest');

        this.renderLoading();
    }

    handleModelError() {
        console.debug('InquiryDetailView#handleModelError');

        this.renderError();
    }

    handleModelSync() {
        console.debug('InquiryDetailView#handleModelSync');

        this.render();
    }

    handleMarkAsSpamClick(e) {
        console.debug('InquiryDetailView#handleMarkAsSpamClick');

        e.currentTarget.querySelector('.spinner-border').classList.remove('d-none');
        this.model.save({ spam_level: 1 }, { patch: true, wait: true });
    }

    handleUnmarkSpamClick(e) {
        console.debug('InquiryDetailView#handleUnmarkSpamClick');

        e.currentTarget.querySelector('.spinner-border').classList.remove('d-none');
        this.model.save({ spam_level: 0 }, { patch: true, wait: true });
    }

    async handleCreateClientClick(e) {
        console.debug('InquiryDetailView#handleCreateClientClick');

        e.currentTarget.querySelector('.spinner-border').classList.remove('d-none');

        try {
            await OAuth2Client.fetchJSON(this.model.url() + '/create_client', {
                method: 'POST'
            });

            this.fetchInquiry();
        } catch (error) {
            e.currentTarget.querySelector('.spinner-border').classList.add('d-none');
        }
    }

    fetchInquiry() {
        this.model.fetch({
            data: {
                include: ['property'],
            }
        });
    }
}
