import DivisionsCollection from '@/js/app/division/collections/divisions';
import Session from '@/js/app/session';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import { history, View } from 'backbone';
import template from '../templates/modal_new.html';

export default class StaffNewView extends View {
    preinitialize() {
        this.id = 'modal-new_staff';
        this.className = 'modal fade';
        this.events = {
            'shown.bs.modal': this.buildup,
            'hidden.bs.modal': this.teardown,
            'submit form': this.create,
        };

        this.subviews = {
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: new DivisionsCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                prependOptions: [
                    { label: '', value: '' }
                ]
            }),
        }
    }

    initialize() {
        this.render();

        this.listenTo(this.model, 'invalid', this.displayError);

        // Fetch divisions
        this.subviews.divisionSelect.collection.fetch({
            data: {
                company_id: 1,
                active: 1,
                order: 'order',
            },
        });
    }

    render() {
        console.debug('StaffNewView#render');

        // Render template
        this.el.innerHTML = template({
            Session
        });

        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        // Trigger modal display
        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });
    }

    buildup() {
        console.debug('StaffNewView#buildup');

        // Set focus
        this.el.querySelector('[name="first_name"]').focus();
    }

    create($e) {
        console.debug('StaffNewView#create');

        $e.preventDefault();

        const data = {
            first_name: this.el.querySelector('#field-first_name').value,
            last_name: this.el.querySelector('#field-last_name').value,
            email: this.el.querySelector('#field-email').value,
            division_id: Number(this.el.querySelector('[name="division_id"]').value),
        };

        this.model.save(data)
            .then((response) => {
                // Hide modal
                this.$el.modal('hide');

                // Redirect to edit staff
                history.navigate('staff/edit/' + response.id, { trigger: true });
            });
    }

    displayError(model, error) {
        console.debug('StaffNewView#displayError');

        _.each(error, (v, k) => {
            this.$el.find('[name="' + k + '"]').closest('.form-group').addClass('is-invalid');
        });
    }

    teardown() {
        console.debug('StaffNewView#teardown');

        this.$el.data('modal', null);

        // Remove view
        this.remove();
    }
}