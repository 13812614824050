import DivisionsCollection from '@/js/app/division/collections/divisions';
import GroupCompaniesCollection from '@/js/app/group-company/collection';
import Session from '@/js/app/session';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import StaffCollection from '../collections/staff';
import StaffCriteria from '../criterias/staff';
import StaffModel from '../models/staff';
import template from '../templates/search.html';
import StaffNewView from './new';
import StaffResultsView from './results';

export default class StaffSearchView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'staff';

        this.events = {
            'change #field-company': this.handleChangeCompany,
            'submit form': this.handleSubmit,
            'click [data-action="reset"]': this.handleReset,
            'click [data-action="new"]': this.handleNewStaffClick,
        };

        // Create criteria model
        this.criteria = new StaffCriteria();

        this.divisionsCollection = new DivisionsCollection();
        this.groupCompaniesCollection = new GroupCompaniesCollection();

        // Create subviews
        this.subviews = {
            groupCompanySelect: new UiDynamicSelectView({
                id: 'field-company',
                collection: this.groupCompaniesCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'company_id',
                },
                selected: Session.data.company_id,
                prependOptions: [
                    { label: 'All', value: '' }
                ]
            }),
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: this.divisionsCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                selected: Session.data.division_id,
                prependOptions: [
                    { label: 'All', value: '' }
                ]
            }),
            peopleResults: new StaffResultsView({
                collection: new StaffCollection(),
                criteria: this.criteria,
            }),
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'handleSubmit');

        this.render();

        // Fetch group companies
        this.groupCompaniesCollection.fetch({
            data: {
                order: 'order',
            },
        });
    }

    render() {
        console.debug('StaffSearchView#render');

        // Render template
        this.el.innerHTML = template({
            Session
        });

        // Append company select subview inside container in DOM
        this.$el.find('#cntr-company').html(this.subviews.groupCompanySelect.el);

        // Append division select subview inside container in DOM
        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        // Attach peopleResults subview to DOM
        this.subviews.peopleResults.setElement(this.el.querySelector('#results'));

        // Get search criteria from sessionStorage
        this.staffSearchCriteria = JSON.parse(sessionStorage.getItem(
            'staffSearchCriteria'
        ));

        // If search conditions exists
        if (this.staffSearchCriteria) {
            populateForm(this.el.querySelector('#frm-staff-search'), this.staffSearchCriteria);
        }

        return this;
    }

    fetchDivisions(companyId) {
        // Fetch divisions
        this.divisionsCollection.fetch({
            data: {
                company_id: companyId,
                order: 'order',
            },
        });
    }

    handleChangeCompany(e) {
        console.debug('StaffSearchView#handleChangeCompany');

        const companyId = e.currentTarget.value;

        this.fetchDivisions(companyId);
    }

    handleSubmit(e) {
        console.debug('StaffSearchView#handleSubmit');

        e.preventDefault();

        // Get defaults, and merge with criteria from form
        const criteria = this.criteria.defaults();

        const form = this.el.querySelector('#frm-staff-search');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        sessionStorage.setItem('staffSearchCriteria', JSON.stringify(criteria));

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('#frm-staff-search').reset();

        sessionStorage.removeItem('staffSearchCriteria');
    }

    handleNewStaffClick() {
        console.debug('StaffSearchView#handleNewStaffClick');

        new StaffNewView({
            model: new StaffModel()
        });
    }
}