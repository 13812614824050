const now = moment();

const month = now.format('MM');
const year = now.year();

const lastMonth = now.clone().subtract(1, 'months').format('MM');
const lastYear = now.clone().subtract(1, 'years').year();

const currentQuarter = now.quarter();
let lastQuarter = now.quarter() - 1;

if (lastQuarter < 1) {
    lastQuarter = 4;
}

const getQuarterDates = (quarter) => {
    let startMonth;
    let endMonth;

    switch (quarter) {
        case 1:
            startMonth = '01';
            endMonth = '03';
            break;
        case 2:
            startMonth = '04';
            endMonth = '06';
            break;
        case 3:
            startMonth = '07';
            endMonth = '09';
            break;
        case 4:
            startMonth = '10';
            endMonth = '12';
            break;
    }

    return { startMonth, endMonth };
};

const currentQuarterMonths = getQuarterDates(currentQuarter);
const lastQuarterMonths = getQuarterDates(lastQuarter);

/**
 * @param {string} rangeType Preset date range category
 * @param inputs Month and year select inputs for From/To fields
 * @param {HTMLSelectElement} inputs.fromMonthSelect Select input for month in From field
 * @param {HTMLSelectElement} inputs.fromYearSelect Select input for year in From field
 * @param {HTMLSelectElement} inputs.toMonthSelect Select input for month in To field
 * @param {HTMLSelectElement} inputs.toYearSelect Select input for year in To field
 */

const setDateInputFields = (rangeType, inputs) => {
    const { fromMonthSelect, fromYearSelect, toMonthSelect, toYearSelect } =
        inputs;

    // Reset year inputs
    fromMonthSelect.value = toMonthSelect.value = month;
    fromYearSelect.value = toYearSelect.value = year;

    switch (rangeType) {
        case 'month-current':
            fromMonthSelect.value = month;
            fromYearSelect.value = year;
            toMonthSelect.value = month;
            toYearSelect.value = year;
            break;

        case 'month-last':
            fromMonthSelect.value = lastMonth;
            toMonthSelect.value = lastMonth;

            if (lastMonth === '12') {
                fromYearSelect.value = lastYear;
                toYearSelect.value = lastYear;
            }
            break;

        case 'quarter-current':
            fromMonthSelect.value = currentQuarterMonths.startMonth;
            toMonthSelect.value = currentQuarterMonths.endMonth;
            break;

        case 'quarter-last':
            fromMonthSelect.value = lastQuarterMonths.startMonth;
            toMonthSelect.value = lastQuarterMonths.endMonth;

            if (lastQuarter === 4) {
                fromYearSelect.value = lastYear;
                toYearSelect.value = lastYear;
            }
            break;

        case 'fy-current':
            fromMonthSelect.value = '04';
            toMonthSelect.value = '03';

            if (now.month() >= 3) {
                fromYearSelect.value = year;
                toYearSelect.value = year + 1;
            } else {
                fromYearSelect.value = year - 1;
                toYearSelect.value = year;
            }
            break;

        case 'fy-last':
            fromMonthSelect.value = '04';
            toMonthSelect.value = '03';

            if (now.month() >= 3) {
                fromYearSelect.value = year - 1;
                toYearSelect.value = year;
            } else {
                fromYearSelect.value = year - 2;
                toYearSelect.value = year - 1;
            }
            break;
    }
};

/**
 * @param {string} rangeType Preset date range category
 * @param inputs Date range inputs for From/To fields
 * @param {HTMLSelectElement} inputs.fromSelect Select input for date range in From field
 * @param {HTMLSelectElement} inputs.toSelect Select input for date range in To field
 */

const setRangeInputFields = (rangeType, inputs) => {
    const { fromSelect, toSelect } = inputs;

    switch (rangeType) {
        case 'month-current':
            fromSelect.value = now
                .clone()
                .startOf('month')
                .format('YYYY-MM-DD');
            toSelect.value = now.clone().endOf('month').format('YYYY-MM-DD');
            break;

        case 'month-last':
            fromSelect.value = now
                .clone()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD');
            toSelect.value = now
                .clone()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYY-MM-DD');
            break;

        case 'quarter-current':
            fromSelect.value = now
                .clone()
                .set('month', parseInt(currentQuarterMonths.startMonth) - 1)
                .startOf('month')
                .format('YYYY-MM-DD');
            toSelect.value = now
                .clone()
                .set('month', parseInt(currentQuarterMonths.endMonth) - 1)
                .endOf('month')
                .format('YYYY-MM-DD');
            break;

        case 'quarter-last':
            if (lastQuarter === 4) {
                fromSelect.value = now
                    .clone()
                    .set('month', 0)
                    .subtract(3, 'months')
                    .startOf('month')
                    .format('YYYY-MM-DD');
                toSelect.value = now
                    .clone()
                    .set('month', 0)
                    .subtract(1, 'months')
                    .endOf('month')
                    .format('YYYY-MM-DD');
            } else {
                fromSelect.value = now
                    .clone()
                    .set('month', parseInt(lastQuarterMonths.startMonth) - 1)
                    .startOf('month')
                    .format('YYYY-MM-DD');
                toSelect.value = now
                    .clone()
                    .set('month', parseInt(lastQuarterMonths.endMonth) - 1)
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }

            break;

        case 'fy-current':
            if (now.month() >= 3) {
                fromSelect.value = now
                    .clone()
                    .set('month', 3)
                    .startOf('month')
                    .format('YYYY-MM-DD');
                toSelect.value = now
                    .clone()
                    .set({ year: year + 1, month: 2 })
                    .endOf('month')
                    .format('YYYY-MM-DD');
            } else {
                fromSelect.value = now
                    .clone()
                    .set({ year: year - 1, month: 3 })
                    .startOf('month')
                    .format('YYYY-MM-DD');
                toSelect.value = now
                    .clone()
                    .set({ year: year, month: 2 })
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            break;

        case 'fy-last':
            if (now.month() >= 3) {
                fromSelect.value = now
                    .clone()
                    .set({ year: year - 1, month: 3 })
                    .startOf('month')
                    .format('YYYY-MM-DD');
                toSelect.value = now
                    .clone()
                    .set({ year: year, month: 2 })
                    .endOf('month')
                    .format('YYYY-MM-DD');
            } else {
                fromSelect.value = now
                    .clone()
                    .set({ year: year - 2, month: 3 })
                    .startOf('month')
                    .format('YYYY-MM-DD');
                toSelect.value = now
                    .clone()
                    .set({ year: year - 1, month: 2 })
                    .endOf('month')
                    .format('YYYY-MM-DD');
            }
            break;
    }
};

/**
 * @param context Scope from which to destructure the select inputs (this)
 */

const getSelectInputs = (context) => {
    const { fromSelect, toSelect } = context;
    return { fromSelect, toSelect };
}

/**
 * @param context Scope from which to destructure the select inputs (this)
 */

const getMonthYearSelectInputs = (context) => {
    const { fromMonthSelect, fromYearSelect, toMonthSelect, toYearSelect } = context;
    return { fromMonthSelect, fromYearSelect, toMonthSelect, toYearSelect }
}

export { setDateInputFields, setRangeInputFields, getSelectInputs, getMonthYearSelectInputs };
