import { View } from 'backbone';
import formToObject from '@/js/libs/form-utils';
import populateForm from '@/js/libs/populate-form';
import InquiryCollection from '../collections/inquiries';
import template from '../templates/search.html';
import InquiryResultsView from './results';

export default class InquirySearchView extends View {
    preinitialize() {
        this.tagName = 'section';
        this.id = 'inquiries';

        this.events = {
            'submit #frm-inquiries-search': this.search,
            'click [data-action="reset"]': this.handleReset,
        };
    }

    initialize() {
        // Functions that will trigger from events need to bind to "this"
        _.bindAll(this, 'render', 'search');

        this.render();
    }

    render() {
        console.debug('InquirySearchView#render');

        // Render template
        this.el.innerHTML = template();

        this.inquirySearchCriteria = JSON.parse(sessionStorage.getItem(
            'inquirySearchCriteria'
        ));

        // If search conditions exists
        if (this.inquirySearchCriteria) {
            populateForm(this.el.querySelector('#frm-inquiries-search'), this.inquirySearchCriteria);

            return this;
        }
    }

    search($e) {
        console.debug('InquirySearchView#search');

        $e.preventDefault();
        $e.stopPropagation();

        // Default search parameters
        const criteria = {
            order: 'created_at DESC',
            limit: 20,
            offset: 0,
        };

        const form = this.el.querySelector('#frm-inquiries-search');

        // Get criteria from search form
        const formData = formToObject(form, true);

        Object.assign(criteria, formData);

        // Store search conditions to sessionStorage
        sessionStorage.setItem('inquirySearchCriteria', JSON.stringify(criteria));

        // Create new inquiries results view, with new collection
        const inquiriesResultsView = new InquiryResultsView({
            collection: new InquiryCollection(),
        });

        // Attach inquiries results view to DOM
        this.$el.find('#results').html(inquiriesResultsView.el);

        // Set criteria in results view
        inquiriesResultsView.criteria = criteria;

        // Fetch collection
        inquiriesResultsView.collection.fetch({
            data: criteria,
            reset: true,
        });
    }

    handleReset(e) {
        e.preventDefault();

        this.el.querySelector('form').reset();

        sessionStorage.removeItem('inquirySearchCriteria');
    }
}