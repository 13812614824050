module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="mt-3">\n    <div class="d-flex justify-content-between align-items-baseline">\n        <h1 class="h2 my-0">Dashboard</h1>\n\n        <h2 class="h4 my-0 text-right">\n            ' +
((__t = ( Formatter.getName(Session.data) )) == null ? '' : __t) +
' — ' +
((__t = ( moment().format('MMMM YYYY') )) == null ? '' : __t) +
'\n        </h2>\n    </div>\n</div>\n\n<hr>\n\n<div id="ytdReport"></div>\n\n<div class="row">\n    <div class="col-6">\n        <div id="targets_widget" class="mb-3"></div>\n    </div>\n\n    <div class="col-6">\n        <div id="my_clients_widget"></div>\n    </div>\n</div>';

}
return __p
};
