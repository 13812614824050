import { Workbox, messageSW } from 'workbox-window';
import '../../css/app.css';
import ClientsNewView from './client/views/new';
import CompanyModel from './company/models/company';
import LocationModel from './property/models/location';
import CompanyNewView from './company/views/new';
import config from './config';
import googleMapsLoader from './google-maps-loader';
import OAuth2Client from './oauth2-client';
import PeopleModel from './people/models/people';
import PeopleNewView from './people/views/new';
import PropertyNewView from './property/views/new';
import Router from './router';
import Session from './session';
import ServiceWorkerAlertView from './sw/alert';

// Define required resources
const requiredResources = [
    googleMapsLoader
];

jQuery(function () {
    const top_nav = $('body > .navbar li a');
    top_nav.on('click', function () {
        top_nav.parent('li').removeClass('active');

        if ($(this).hasClass('menu')) {
            top_nav.parent('li').removeClass('open');
        }

        $(this).parent('ul[class=""]').addClass('active');
    });


    /*$('ul.tabs a, ul.pills a, .pagination a, .well .btn, .actions .btn, .alert-message .btn, a.close').click(function(e) {
        e.preventDefault();
    });*/


    // Menu: Add new company
    $('#menu-new_company').on('click', function ($e) {
        $e.preventDefault();

        new CompanyNewView({
            model: new CompanyModel()
        }).render();
    });

    // Menu: Add new person
    $('#menu-new_person').on('click', function ($e) {
        $e.preventDefault();

        new PeopleNewView({
            model: new PeopleModel()
        }).render();
    });

    // Menu: Add new client
    $('#menu-new_client').on('click', function ($e) {
        $e.preventDefault();

        new ClientsNewView({
            model: new PeopleModel()
        }).render();
    });

    // Menu: Address lookup and verification
    $('#menu-new_property').on('click', function ($e) {
        $e.preventDefault();

        new PropertyNewView({
            model: new LocationModel()
        }).render();
    });

    // Override AJAX in Backbone to OAuth2Client.ajax
    Backbone.ajax = OAuth2Client.ajax.bind(OAuth2Client);

    // If token exists, load session
    if (OAuth2Client.hasToken()) {
        requiredResources.push(
            Session.load()
                .catch((err) => {
                    console.error(err.message);
                })
        );
    }

    // Create router
    new Router();

    Promise.all(requiredResources)
        .then((resources) => {
            window.google = resources[0];   // Payload of googleMapsLoader promise

            // Start Backbone
            Backbone.history.start();
        });

});

/*
// No longer used, but could be useful?
String.prototype.linkify = function () {
    const text = this;

    if (text) {
        return text.replace(
            /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi,
            function (url) {
                let full_url = url;
                if (!full_url.match('^https?:\/\/')) {
                    full_url = 'http://' + full_url;
                }
                return '<a href="' + full_url + '" target="_blank">' + url + '</a>';
            }
        );
    } else {
        return text;
    }
};
*/

String.prototype.capitalize = function () {
    return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
        return p1 + p2.toUpperCase();
    });
};

// Check that service workers are supported
if ('serviceWorker' in navigator && config.enable_service_worker) {
    const wb = new Workbox('/sw.js');
    let registration;

    // Assuming the user accepted the update, set up a listener
    // that will reload the page as soon as the previously waiting
    // service worker has taken control.
    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
    });

    const showSkipWaitingPrompt = () => {
        const serviceWorkerAlert = new ServiceWorkerAlertView({
            el: document.getElementById('serviceWorkerUpdateCntr'),
            onUpdate: () => {
                if (registration && registration.waiting) {
                    // Send a message to the waiting service worker,
                    // instructing it to activate.
                    messageSW(registration.waiting, { type: 'SKIP_WAITING' });
                }
            }
        });

        serviceWorkerAlert.render();
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', showSkipWaitingPrompt);
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

    wb.register().then(reg => {
        // Check for update every 1 hour
        setInterval(() => {
            reg.update();
        }, 3600000);

        registration = reg;
    });
}
